<template>
  <div class="layer process">
    <div class="content pr_content">
      <h2 class="layer_title">服务流程</h2>
      <ul class="pce_items">
        <li>
          <i class="iconfont icon-yuyue1"></i>
          <h5>在线预约</h5>
          <p>填写您的联系方式</p>
          <span class="arrow arrow-r"></span>
        </li>
        <li>
          <i class="iconfont icon-jiance"></i>
          <h5>上门检测</h5>
          <p>
            对各种不同空间的灯光
            <br />进行专业检测
          </p>
          <span class="arrow arrow-r"></span>
        </li>
        <li>
          <i class="iconfont icon-fangan"></i>
          <h5>制定方案</h5>
          <p>
            制定专属你的照明方案
            <br />并签订合同
          </p>
          <span class="arrow arrow-r"></span>
        </li>
        <li>
          <i class="iconfont icon-bianzu"></i>
          <h5>初步改造</h5>
          <p>
            改造之前对家具进行保护处理
            <br />并在小范围内做好硬装的改造
          </p>
          <span class="arrow arrow-d"></span>
        </li>
        <li>
          <i class="iconfont icon-shouhou2"></i>
          <h5>售后无忧</h5>
          <p>
            自施工完成之日起
            <br />提供5年上门保修服务
          </p>
          <!-- <span class="arrow arrow-l"></span> -->
        </li>
        <li>
          <i class="iconfont icon-zhaoming"></i>
          <h5>照明体验</h5>
          <p>
            完成照明升级，体验全新
            <br />的照明环境
          </p>
          <span class="arrow arrow-l"></span>
        </li>
        <li>
          <i class="iconfont icon-bianzu1"></i>
          <h5>调试验收</h5>
          <p>
            完工后对其实际效果进行调整
            <br />设定好人性化场景模式及控制方法
          </p>
          <span class="arrow arrow-l"></span>
        </li>
        <li>
          <i class="iconfont icon-anzhuang1"></i>
          <h5>替换安装</h5>
          <p>根据照明方案，进行安装</p>
          <span class="arrow arrow-l"></span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "Process"
};
</script>
<style lang="scss">
@import "../../assets/scss/mixins.scss";

.process {
  background: #fff;
  .layer_title {
    padding-bottom: 48px;
  }
  .pce_items {
    margin-left: -79px;
    @include clearfix;
    li {
      float: left;
      height: 220px;
      width: 240px;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      padding: 10px;
      margin: 0 0 80px 79px;
      position: relative;
    }
    h5 {
      color: #4a4a4a;
      text-align: center;
    }
    p {
      font-size: 14px;
      color: #999;
      line-height: 24px;
      text-align: center;
      padding-top: 10px;
    }
    .iconfont {
      color: #3c9096;
      display: block;
      width: 72px;
      font-size: 64px;
      margin: 26px auto 20px;
      text-align: center;
    }
  }
  .arrow {
    display: block;
    position: absolute;
    width: 30px;
    height: 18px;
    background: url("../../assets/images/arrow.png") no-repeat;
  }
  .arrow-r {
    right: -55px;
    top: 50%;
    margin-top: -9px;
  }
  .arrow-d {
    transform: rotate(90deg);
    left: 50%;
    margin-left: -15px;
    bottom: -50px;
  }
  .arrow-l {
    transform: rotate(180deg);
    left: -55px;
    top: 50%;
    margin-top: -9px;
  }
}
</style>

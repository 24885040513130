<template>
  <div class="pop_layer" v-if="isShow">
    <!--:class="{ poplayer: isShow }"-->
    <div class="mask"></div>
    <div class="pop_content">
      <div class="close" @click="handlePopShow">
        <i class="iconfont icon-guanbi"></i>
      </div>
      <div class="pop_inner">
        <div class="pop_img_bg">
          <img src="../../assets/images/pop_img.png" alt />
        </div>
        <div class="pop_form" id="form">
          <h1>免费上门报价</h1>
          <div class="pop_form_item">
            <input
              type="text"
              placeholder="手机号"
              v-model="data.mobile"
              :class="{ error: errorTip.mobile }"
            />
            <span v-if="errorTip.mobile">请输入正确的手机号码</span>
          </div>
          <div class="pop_form_item">
            <input
              type="text"
              placeholder="验证码"
              v-model="data.code"
              :class="{ error: errorTip.code }"
            />
            <button
              class="btn btn-code"
              @click="getCode"
              :disabled="buttonDisabled"
            >
              {{ buttonText }}
            </button>
            <span v-if="errorTip.code">验证码不正确</span>
          </div>
          <div class="pop_form_item">
            <input
              type="text"
              placeholder="姓名"
              v-model="data.realname"
              maxlength="20"
              :class="{ error: errorTip.realname }"
            />
            <span v-if="errorTip.realname">{{ realNameTip }}</span>
          </div>
          <div class="pop_form_item">
            <input
              type="text"
              placeholder="所在区域"
              @click="handlebjAreaShow"
              v-model="addressArear"
              :class="{ error: errorTip.district }"
            />
            <span v-if="errorTip.district">请选择您所在的区域</span>
            <div class="icon-box" @click="handlebjAreaShow">
              <i class="iconfont icon-angle-down"></i>
            </div>
            <div class="cascader" v-if="bjShow">
              <span class="close-cascader" @click="handleHideBjarea">
                <i class="iconfont icon-close"></i>
              </span>
              <div class="city_selected">
                <span>北京市 {{ bjSelect }}</span>
                <span>{{ data.district }}</span>
              </div>
              <ul class="bjArea">
                <li
                  v-for="(area, index) in bjArea"
                  @key="index"
                  @click="handleBjareaSlect(area)"
                >
                  {{ area.label }}
                </li>
              </ul>
            </div>
            <div class="cascader" v-if="areaSelectIsShow">
              <!--  :class="{isActive:areaSelectIsShow}" v-if="areaSelectIsShow" -->
              <span class="close-cascader" @click="handleHideArea">
                <i class="iconfont icon-close"></i>
              </span>
              <div class="city_selected">
                <span @click="handleReSelectProvince">
                  {{ data.province ? data.province : "选择省份/自治区" }}
                </span>
                <span @click="handleReSelectCity">{{ data.city }}</span>
                <span>{{ data.district }}</span>
              </div>
              <ul class="province" v-if="!data.province">
                <li
                  v-for="(province, index) in provinceList"
                  @key="index"
                  @click="handleProvince(province)"
                >
                  {{ province.label }}
                </li>
              </ul>
              <ul class="city" v-if="!data.city">
                <li
                  v-for="(city, index) in cityList"
                  @key="index"
                  @click="handleCity(city)"
                >
                  {{ city.label }}
                </li>
              </ul>
              <ul class="area">
                <li
                  v-for="(area, index) in areaList"
                  @key="index"
                  @click="handleArea(area)"
                >
                  {{ area.label }}
                </li>
              </ul>
            </div>
          </div>
          <div class="pop_form_item">
            <input
              type="text"
              placeholder="详细地址"
              v-model="data.address"
              maxlength="100"
              :class="{ error: errorTip.address }"
            />
            <span v-if="errorTip.address">{{ addressTip }}</span>
          </div>
          <div class="pop_form_item">
            <input
              type="text"
              placeholder="备注"
              maxlength="1000"
              v-model="data.note"
              :class="{ error: errorTip.note }"
            />
            <span v-if="errorTip.note">{{ noteTip }}</span>
          </div>

          <div class="pop_form_item">
            <p class="pop_form_item-label">
              场景类型
              <span v-if="errorTip.scene">请至少选择一个场景</span>
            </p>
            <div class="checkbox">
              <span
                class="checkbox-item"
                v-for="(item, index) in scenceList"
                @key="item.id"
              >
                <input
                  type="radio"
                  class="checkbox-input"
                  :id="`checkbox-${index}`"
                  name="radio"
                  @change="handleCheckbox($event.target.checked, item)"
                  :checked="ifchecked(item)"
                />
                <label :for="`checkbox-${index}`" class="checkbox-label">
                  {{ item.name }}
                </label>
              </span>
            </div>
          </div>
          <div class="pop_form_item">
            <button
              class="submit"
              @click="handleSubmit"
              :disabled="submitDisabled"
            >
              {{ submitText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { deepCopy } from "../../untils/untils";

import cityJson from "./city";

import host from "../../../config";

export default {
  name: "Pop",
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    defaultNum: {
      type: String
    }
  },
  watch: {
    isShow(val) {
      if (val) {
        this.resetsubmitData();
        // this.teimer = 60;
        this.data.mobile = this.defaultNum ? this.defaultNum : "";
      }
    },
    defaultNum(val) {
      this.data.mobile = val;
    },
    maxName(val) {
      // eslint-disable-next-line no-console
      console.log(`姓名的长度为： ${this.getRealLen(val)}`);
      if (this.getRealLen(val) > 20) {
        this.errorTip.realname = true;
        this.realNameTip = "姓名长度不能超过20个字符或10个汉字";
      } else {
        this.errorTip.realname = false;
        this.realNameTip = "请输入您的姓名";
      }
    },
    maxAddress(val) {
      if (this.getRealLen(val) > 100) {
        this.errorTip.address = true;
        this.addressTip = "地址长度不能超过100个字符或50个汉字";
      } else {
        this.errorTip.realname = false;
        this.realNameTip = "请输入您的详细地址";
      }
    },
    maxNote(val) {
      if (this.getRealLen(val) > 1000) {
        this.errorTip.note = true;
      } else {
        this.errorTip.note = false;
      }
    }
  },
  computed: {
    maxName() {
      return this.data.realname;
    },
    maxAddress() {
      return this.data.address;
    },
    maxNote() {
      return this.data.note;
    }
  },
  data() {
    return {
      submitDisabled: false,
      buttonDisabled: false,
      teimer: 60,
      buttonText: "获取验证码",
      submitText: "提交",
      scenceList: [
        {
          name: "家庭",
          id: 1
        },
        {
          name: "办公室",
          id: 2
        },
        {
          name: "商业空间",
          id: 3
        },
        {
          name: "其他",
          id: 4
        }
      ],

      // 城市选择器
      provinceList: cityJson,
      addressArear: "",
      areaSelectIsShow: false,
      cityList: [],
      areaList: [],
      // 提交的数据

      data: {
        mobile: "",
        code: "",
        province: "",
        city: "",
        district: "",
        address: "",
        note: "",
        realname: "",
        scene: 1
      },
      errorTip: {
        mobile: false,
        code: false,
        realname: false,
        district: false,
        address: false,
        scene: false,
        note: false
      },
      addressTip: "请输入您的详细地址",
      noteTip: "备注信息不能超过1000个字符或500个汉字",
      realNameTip: "请输入您的姓名",
      countDownLabel: null,
      bjArea: [],
      bjSelect: "",
      bjShow: false
    };
  },

  methods: {
    handlePopShow() {
      this.$emit("close");
      if (!this.isShow) {
        this.errorTip.district = false;
        // this.resetsubmitData();
      }
    },
    handleProvince(province) {
      this.data.province = province.label;
      this.cityList = province.children;
      if (province.children && province.children.length === 1) {
        this.data.city = province.children[0].label;
        this.areaList = province.children[0].children;
      }
    },
    handleCity(city) {
      this.data.city = city.label;
      this.areaList = city.children;
    },
    handleArea(area) {
      this.data.district = area.label;
      this.areaSelectIsShow = false;
      this.addressArear = `${this.data.province} ${this.data.city} ${this.data.district}`;
    },
    handleAreaShow() {
      this.areaSelectIsShow = true;
    },
    handlebjAreaShow() {
      this.bjArea = cityJson[0].children[0].children;
      this.bjShow = true;
    },
    handleHideArea() {
      this.areaSelectIsShow = false;
    },
    handleHideBjarea() {
      this.bjShow = false;
    },
    handleBjareaSlect(area) {
      this.data.province = "北京市";
      this.data.city = "北京市";
      this.data.district = area.label;
      this.addressArear = `北京市  ${area.label}`;
      this.bjShow = false;
    },
    handleReSelectProvince() {
      this.restArearData();
    },
    handleReSelectCity() {
      this.data.city = "";
      this.data.district = "";
      this.areaList = [];
    },
    handleCheckbox(ischeck, item) {
      if (ischeck) {
        this.data.scene = item.id;
      }
    },
    resetsubmitData() {
      this.data = Object.assign({}, this.data, {
        // mobile: "",
        code: "",
        province: "",
        city: "",
        district: "",
        address: "",
        note: "",
        realname: "",
        scene: 1
      });
      this.errorTip = Object.assign({}, this.errorTip, {
        mobile: false,
        code: false,
        realname: false,
        district: false,
        address: false,
        scene: false
      });
      this.submitDisabled = false;
      clearTimeout(this.countDownLabel);
      this.buttonText = "获取验证码";
      this.buttonDisabled = false;
      this.teimer = 60;
      this.submitText = "提交";
      this.addressArear = "";
      this.areaSelectIsShow = false;
    },
    restArearData() {
      this.data.province = "";
      this.data.city = "";
      this.data.district = "";
      this.cityList = [];
      this.areaList = [];
    },
    required(val) {
      return val.length > 0;
    },
    mobile(val) {
      if (val == null || this.trim(val) === "") return false;
      // let rex = /^(((13[0-9]{1})|(15[0-9]{1})|(18[0-9]{1}))|(17|16[0-9]{1}))+\d{8})$/;
      let rex = /^((1(3|5|6|7|8|9)[0-9]{1})+\d{8})$/;
      return rex.test(val);
    },
    trim(value) {
      return value.replace(/(^\s*)|(\s*$)/g, "");
    },
    countdown() {
      let _this = this;
      // debugger;
      if (_this.teimer === 0) {
        this.buttonText = "获取验证码";
        this.buttonDisabled = false;
        _this.teimer = 60;
        return false;
      }
      this.buttonText = `${_this.teimer}秒`;
      _this.teimer--;
      _this.countDownLabel = setTimeout(() => {
        _this.countdown();
      }, 1000);
    },
    ifchecked(val) {
      if (val.id === 1) return true;
    },
    getCode() {
      console.log("获取验证码！");
      if (!this.mobile(this.data.mobile)) {
        this.errorTip.mobile = true;
      } else {
        this.errorTip.mobile = false;
        axios({
          method: "post",
          url: host + "/api/detect/sendsms",
          params: {
            mobile: this.data.mobile
          }
        })
          .then(response => {
            const { data } = response;
            if (data.state === 1) {
              this.buttonDisabled = true;
              this.countdown();
            } else {
              clearTimeout(this.countDownLabel);
              this.buttonText = "获取验证码";
              this.buttonDisabled = false;
              this.teimer = 60;
              this.$emit("codefail");
            }
          })
          .catch(error => {
            // 请求失败处理
            console.log(error);
            this.$emit("netError");
          });
      }
    },
    // 数据提交
    handleSubmit() {
      let _this = this;
      let _data = deepCopy(this.data);
      let isPass = [];
      Object.keys(_data).forEach(key => {
        if (key !== "note") {
          if (!_data[key]) {
            _this.errorTip[key] = true;
          } else if (key === "mobile" && !this.mobile(this.data.mobile)) {
            this.errorTip.mobile = true;
          } else {
            _this.errorTip[key] = false;
          }
        }
      });
      Object.keys(this.errorTip).forEach(item => {
        isPass.push(this.errorTip[item]);
      });

      if (!isPass.includes(true)) {
        this.submitText = "提交中...";
        this.submitDisabled = true;
        axios({
          method: "post",
          url: host + "/api/detect/reserve",
          params: {
            mobile: _data.mobile,
            code: _data.code,
            province: _data.province,
            city: _data.city,
            district: _data.district,
            address: _data.address,
            note: _data.note,
            realname: _data.realname,
            scene: _data.scene
          }
        })
          .then(response => {
            const { data } = response;
            if (data.state === 0) {
              // this.teimer = 0;
              this.errorTip.code = true;
              this.submitDisabled = false;
              this.submitText = "提交";
            } else if (data.state === 1) {
              this.$emit("success");
              this.handlePopShow();
            } else {
              this.$emit("submitFail");
            }
          })
          .catch(error => {
            // 请求失败处理
            this.$emit("netError");
            // eslint-disable-next-line no-console
            console.log(error);
          });
      }
    },
    getRealLen(str) {
      return str.replace(/[^\x00-\xff]/g, "__").length;
    }
  }
};
</script>
<style lang="scss">
.pop_layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 199;
  // display: none;

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: #282828, $alpha: 0.6);
  }
  .pop_content,
  .pop_tip {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  .pop_content {
    height: 636px;
    width: 766px;
  }
  .pop_tip {
    height: 180px;
    width: 260px;
    background: #fff;
    text-align: center;
    padding-top: 75px;
    font-size: 20px;
    border-radius: 5px;
    .close {
      top: 0;
      right: 0;
      color: #4a4a4a;
    }
    p {
      font-size: 16px;
    }
  }
  .pop_inner {
    // background: url("../../assets/images/pop_img.png") no-repeat center left;
    padding-left: 358px;
    height: 636px;
    width: 766px;
  }
  .pop_form {
    background: #fff;
    box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    padding-bottom: 32px;
    position: relative;
    z-index: 10;
  }
  .pop_img_bg {
    width: 368px;
    height: 551px;
    position: absolute;
    left: 0;
    top: 44px;
    z-index: 1;
  }
  h1 {
    color: #282828;
    font-size: 28px;
    text-align: center;
    padding: 32px 0 24px;
    font-weight: 600;
  }
  .close {
    position: absolute;
    right: -35px;
    top: -35px;
    color: #fff;
    height: 40px;
    width: 40px;
    cursor: pointer;

    color: #fff;
    text-align: center;
    line-height: 40px;
    .iconfont {
      font-size: 24px;
    }
    // span {
    //   display: block;
    //   width: 24px;
    //   height: 24px;
    //   border: 1px solid #fff;
    //   border-radius: 50%;
    //   text-align: center;
    //   line-height: 24px;
    //   margin: 8px 0 0 8px;
    // }
  }
  .pop_form_item {
    width: 344px;
    background: #fff;
    margin: 0 auto;
    border-radius: 3px;
    margin-bottom: 12px;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }
    input {
      padding: 0 16px;
      border: 1px solid #eee;
      height: 48px;
      line-height: 48px;
      width: 100%;
      outline: none;
      font-size: 16px;
      border-radius: 3px;
      &.error {
        border-color: #d8345d;
      }
    }
    & > span {
      font-size: 12px;
      color: #d8345d;
    }
    .label {
      font-size: #999;
      font-size: 16px;
    }
    .icon-box {
      position: absolute;
      right: 16px;
      top: 16px;
      color: #ccc;
      cursor: pointer;
    }
    .submit {
      display: block;
      margin: 0 auto;
      width: 344px;
      height: 48px;
      background: linear-gradient(
        315deg,
        rgba(110, 195, 199, 1) 0%,
        rgba(60, 144, 150, 1) 100%
      );
      border-radius: 3px;
      text-align: center;
      line-height: 48px;
      color: #fff;
      font-size: 20px;
      text-decoration: none;
      outline: none;
      border: none;
      &:disabled {
        opacity: 0.5;
      }
    }
    .btn {
      display: block;
      position: absolute;
      cursor: pointer;
      right: 0;
      top: 0;
      height: 48px;
      color: #3c9096;
      text-decoration: none;
      line-height: 48px;
      width: 100px;
      text-align: center;
      border: 0;
      background: none;
      outline: none;
      span {
        font-size: 12px;
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        height: 24px;
        background: #eeeeee;
        top: 50%;
        margin-top: -12px;
      }
      &:disabled {
        line-height: 18px;
        opacity: 0.5;
        cursor: default;
      }
    }
  }
  .pop_form_item-label {
    height: 24px;
    line-height: 24px;
    span {
      font-size: 12px;
      color: rgb(250, 57, 106);
      padding-left: 15px;
    }
  }
  .checkbox {
    padding-top: 8px;
    margin-left: -3px;
    .checkbox-input {
      display: none;
    }
    .checkbox-label {
      box-sizing: content-box;
      display: inline-block;
      position: relative;
      width: 78px;
      height: 46px;
      text-align: center;
      line-height: 46px;
      font-size: 14px;
      border-radius: 3px;
      border: 1px solid #eee;
      font-size: 16px;
      color: #999;
      margin-left: 6px;
    }
    .checkbox-input + .checkbox-label:active,
    .checkbox-input:checked + .checkbox-label {
      width: 76px;
      height: 44px;
      line-height: 44px;
      border: 2px solid #3c9096;
      color: #3c9096;
    }
  }
  .cascader {
    // display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    background: #fff;
    width: 344px;
    border: 1px solid #eee;
    box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.16);
    padding: 0 16px 16px;
    .close-cascader {
      position: absolute;
      width: 48px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      right: 0;
      top: 0;
      cursor: pointer;
      &:hover {
        background: rgba(219, 161, 161, 0.06);
      }
    }
    ul {
      padding-top: 12px;
    }
    li {
      float: left;
      font-size: 14px;
      margin-bottom: 12px;
      margin-right: 16px;
      cursor: pointer;
    }
    .city_selected {
      height: 48px;
      line-height: 48px;
      width: 100%;
      color: #3c9096;
      font-size: 16px;
      border-bottom: 1px solid #eee;
      cursor: pointer;
      span {
        padding-right: 5px;
      }
    }
  }
  .isActive {
    display: block;
  }
}
</style>

<template>
  <div class="layer plan">
    <div class="content p_content">
      <h2 class="layer_title">焕新方案</h2>
      <div class="plan_wrap">
        <div class="plan_item">
          <div class="plan_img">
            <img src="../../assets/images/plan_1.png" alt="替换型焕新" />
          </div>
          <h5 class="plan_title">替换型焕新</h5>
          <p class="plan_des">采用更好灯光品质的灯具进行原灯位替换合理优化灯光分布</p>
          <ul>
            <li>
              <i class="iconfont icon-yuandengweitihuan"></i>
              <span>原灯位替换</span>
            </li>
            <li>
              <i class="iconfont icon-genggaoguangzhiliang"></i>
              <span>更佳光品质</span>
            </li>
            <li>
              <i class="iconfont icon-shijian"></i>
              <span>3小时完成</span>
            </li>
          </ul>
        </div>
        <div class="plan_item">
          <div class="plan_img">
            <img src="../../assets/images/plan_2.png" alt="智能型焕新" />
          </div>
          <h5 class="plan_title">智能型焕新</h5>
          <p class="plan_des">采用更好灯光品质的灯具进行原灯位替换根据场景需求完成智能升级</p>
          <ul>
            <li>
              <i class="iconfont icon-yuandengweitihuan"></i>
              <span>原灯位替换</span>
            </li>
            <li>
              <i class="iconfont icon-genggaoguangzhiliang"></i>
              <span>更佳光品质</span>
            </li>
            <li>
              <i class="iconfont icon-wuxianzhineng"></i>
              <span>无线智能</span>
            </li>
            <li>
              <i class="iconfont icon-changjinghua"></i>
              <span>灯光场景化</span>
            </li>
            <li>
              <i class="iconfont icon-shijian"></i>
              <span>8小时完成</span>
            </li>
          </ul>
        </div>
        <div class="plan_item">
          <div class="plan_img">
            <img src="../../assets/images/plan_3.png" alt="设计型焕新" />
          </div>
          <h5 class="plan_title">设计型焕新</h5>
          <p class="plan_des">在项目现有条件基础上，对空间灯光进行重新设计，用灯光打造场所的高级感</p>
          <ul>
            <li>
              <i class="iconfont icon-dengguangsheji"></i>
              <span>灯光设计</span>
            </li>
            <li>
              <i class="iconfont icon-shigong"></i>
              <span>现场施工</span>
            </li>
            <li>
              <i class="iconfont icon-genggaoguangzhiliang"></i>
              <span>更佳光品质</span>
            </li>
            <li>
              <i class="iconfont icon-gaojidengguang"></i>
              <span>高级灯光效果</span>
            </li>
            <li>
              <i class="iconfont icon-changjinghua"></i>
              <span>灯光场景化</span>
            </li>
            <li>
              <i class="iconfont icon-zhinengkexuan"></i>
              <span>智能可选</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Plan"
};
</script>
<style lang="scss">
@import "../../assets/scss/mixins.scss";

.plan {
  background: #f8f8f8;
  padding-bottom: 80px;
  .layer_title {
    padding: 64px 0 48px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .plan_wrap {
    @include clearfix;
    height: auto;
  }
  .plan_item {
    float: left;
    width: 380px;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    height: 667px;
  }
  .plan_item + .plan_item {
    margin-left: 30px;
  }
  .plan_img {
    width: 380px;
    height: 254px;
  }
  .plan_title {
    line-height: 34px;
    color: #4a4a4a;
    font-size: 24px;
    padding-top: 32px;
    text-align: center;
    position: relative;
    padding-bottom: 14px;
    &::after {
      display: block;
      content: "";
      position: absolute;
      width: 32px;
      height: 2px;
      background: linear-gradient(
        315deg,
        rgba(110, 195, 199, 1) 0%,
        rgba(60, 144, 150, 1) 100%
      );
      left: 50%;
      margin-left: -16px;
      bottom: 0;
    }
  }
  .plan_des {
    color: #999;
    font-size: 16px;
    line-height: 26px;
    margin: 0 46px;
    text-align: center;
    padding-top: 14px;
    &::after {
      content: "";
      display: block;
      height: 1px;
      width: 280px;
      margin: 24px auto;
      background: #999;
      opacity: 0.24;
    }
  }
  ul {
    height: 212px;
    text-align: center;
    padding: 10px 0 0 20px;
  }
  li {
    // display:inline-block;
    float: left;
    color: #4a4a4a;
    text-align: center;
    margin: 0 14px 26px;
    width: 84px;
    i {
      font-size: 40px;
    }
    span {
      padding-top: 5px;
      font-size: 14px;
      line-height: 28px;
      display: block;
      text-align: center;
    }
  }
}
</style>

module.exports = [
  {
    value: "110000@@北京",
    label: "北京",
    children: [
      {
        value: "110100@@北京市",
        label: "北京市",
        children: [
          { value: "110101@@东城区", label: "东城区" },
          { value: "110102@@西城区", label: "西城区" },
          { value: "110103@@崇文区", label: "崇文区" },
          { value: "110104@@宣武区", label: "宣武区" },
          { value: "110105@@朝阳区", label: "朝阳区" },
          { value: "110106@@丰台区", label: "丰台区" },
          { value: "110107@@石景山区", label: "石景山区" },
          { value: "110108@@海淀区", label: "海淀区" },
          { value: "110109@@门头沟区", label: "门头沟区" },
          { value: "110111@@房山区", label: "房山区" },
          { value: "110112@@通州区", label: "通州区" },
          { value: "110113@@顺义区", label: "顺义区" },
          { value: "110114@@昌平区", label: "昌平区" },
          { value: "110115@@大兴区", label: "大兴区" },
          { value: "110116@@怀柔区", label: "怀柔区" },
          { value: "110117@@平谷区", label: "平谷区" },
          { value: "110228@@密云县", label: "密云县" },
          { value: "110229@@延庆县", label: "延庆县" },
          { value: "110230@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "120000@@天津",
    label: "天津",
    children: [
      {
        value: "120100@@天津市",
        label: "天津市",
        children: [
          { value: "120101@@和平区", label: "和平区" },
          { value: "120102@@河东区", label: "河东区" },
          { value: "120103@@河西区", label: "河西区" },
          { value: "120104@@南开区", label: "南开区" },
          { value: "120105@@河北区", label: "河北区" },
          { value: "120106@@红桥区", label: "红桥区" },
          { value: "120107@@塘沽区", label: "塘沽区" },
          { value: "120108@@汉沽区", label: "汉沽区" },
          { value: "120109@@大港区", label: "大港区" },
          { value: "120110@@东丽区", label: "东丽区" },
          { value: "120111@@西青区", label: "西青区" },
          { value: "120112@@津南区", label: "津南区" },
          { value: "120113@@北辰区", label: "北辰区" },
          { value: "120114@@武清区", label: "武清区" },
          { value: "120115@@宝坻区", label: "宝坻区" },
          { value: "120116@@滨海新区", label: "滨海新区" },
          { value: "120221@@宁河县", label: "宁河县" },
          { value: "120223@@静海县", label: "静海县" },
          { value: "120225@@蓟州", label: "蓟州" },
          { value: "120226@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "130000@@河北",
    label: "河北",
    children: [
      {
        value: "130100@@石家庄市",
        label: "石家庄市",
        children: [
          { value: "130102@@长安区", label: "长安区" },
          { value: "130103@@桥东区", label: "桥东区" },
          { value: "130104@@桥西区", label: "桥西区" },
          { value: "130105@@新华区", label: "新华区" },
          { value: "130107@@井陉矿区", label: "井陉矿区" },
          { value: "130108@@裕华区", label: "裕华区" },
          { value: "130121@@井陉县", label: "井陉县" },
          { value: "130123@@正定县", label: "正定县" },
          { value: "130124@@栾城县", label: "栾城县" },
          { value: "130125@@行唐县", label: "行唐县" },
          { value: "130126@@灵寿县", label: "灵寿县" },
          { value: "130127@@高邑县", label: "高邑县" },
          { value: "130128@@深泽县", label: "深泽县" },
          { value: "130129@@赞皇县", label: "赞皇县" },
          { value: "130130@@无极县", label: "无极县" },
          { value: "130131@@平山县", label: "平山县" },
          { value: "130132@@元氏县", label: "元氏县" },
          { value: "130133@@赵县", label: "赵县" },
          { value: "130181@@辛集市", label: "辛集市" },
          { value: "130182@@藁城市", label: "藁城市" },
          { value: "130183@@晋州市", label: "晋州市" },
          { value: "130184@@新乐市", label: "新乐市" },
          { value: "130185@@鹿泉市", label: "鹿泉市" },
          { value: "130186@@其它区", label: "其它区" },
        ],
      },
      {
        value: "130200@@唐山市",
        label: "唐山市",
        children: [
          { value: "130202@@路南区", label: "路南区" },
          { value: "130203@@路北区", label: "路北区" },
          { value: "130204@@古冶区", label: "古冶区" },
          { value: "130205@@开平区", label: "开平区" },
          { value: "130207@@丰南区", label: "丰南区" },
          { value: "130208@@丰润区", label: "丰润区" },
          { value: "130223@@滦县", label: "滦县" },
          { value: "130224@@滦南县", label: "滦南县" },
          { value: "130225@@乐亭县", label: "乐亭县" },
          { value: "130227@@迁西县", label: "迁西县" },
          { value: "130229@@玉田县", label: "玉田县" },
          { value: "130230@@曹妃甸", label: "曹妃甸" },
          { value: "130281@@遵化市", label: "遵化市" },
          { value: "130283@@迁安市", label: "迁安市" },
          { value: "130284@@其它区", label: "其它区" },
        ],
      },
      {
        value: "130300@@秦皇岛市",
        label: "秦皇岛市",
        children: [
          { value: "130302@@海港区", label: "海港区" },
          { value: "130303@@山海关区", label: "山海关区" },
          { value: "130304@@北戴河区", label: "北戴河区" },
          { value: "130321@@青龙满族自治县", label: "青龙满族自治县" },
          { value: "130322@@昌黎县", label: "昌黎县" },
          { value: "130323@@抚宁县", label: "抚宁县" },
          { value: "130324@@卢龙县", label: "卢龙县" },
          { value: "130398@@其它区", label: "其它区" },
          { value: "130399@@经济技术开发区", label: "经济技术开发区" },
        ],
      },
      {
        value: "130400@@邯郸市",
        label: "邯郸市",
        children: [
          { value: "130402@@邯山区", label: "邯山区" },
          { value: "130403@@丛台区", label: "丛台区" },
          { value: "130404@@复兴区", label: "复兴区" },
          { value: "130406@@峰峰矿区", label: "峰峰矿区" },
          { value: "130421@@邯郸县", label: "邯郸县" },
          { value: "130423@@临漳县", label: "临漳县" },
          { value: "130424@@成安县", label: "成安县" },
          { value: "130425@@大名县", label: "大名县" },
          { value: "130426@@涉县", label: "涉县" },
          { value: "130427@@磁县", label: "磁县" },
          { value: "130428@@肥乡县", label: "肥乡县" },
          { value: "130429@@永年县", label: "永年县" },
          { value: "130430@@邱县", label: "邱县" },
          { value: "130431@@鸡泽县", label: "鸡泽县" },
          { value: "130432@@广平县", label: "广平县" },
          { value: "130433@@馆陶县", label: "馆陶县" },
          { value: "130434@@魏县", label: "魏县" },
          { value: "130435@@曲周县", label: "曲周县" },
          { value: "130481@@武安市", label: "武安市" },
          { value: "130482@@其它区", label: "其它区" },
        ],
      },
      {
        value: "130500@@邢台市",
        label: "邢台市",
        children: [
          { value: "130502@@桥东区", label: "桥东区" },
          { value: "130503@@桥西区", label: "桥西区" },
          { value: "130521@@邢台县", label: "邢台县" },
          { value: "130522@@临城县", label: "临城县" },
          { value: "130523@@内丘县", label: "内丘县" },
          { value: "130524@@柏乡县", label: "柏乡县" },
          { value: "130525@@隆尧县", label: "隆尧县" },
          { value: "130526@@任县", label: "任县" },
          { value: "130527@@南和县", label: "南和县" },
          { value: "130528@@宁晋县", label: "宁晋县" },
          { value: "130529@@巨鹿县", label: "巨鹿县" },
          { value: "130530@@新河县", label: "新河县" },
          { value: "130531@@广宗县", label: "广宗县" },
          { value: "130532@@平乡县", label: "平乡县" },
          { value: "130533@@威县", label: "威县" },
          { value: "130534@@清河县", label: "清河县" },
          { value: "130535@@临西县", label: "临西县" },
          { value: "130581@@南宫市", label: "南宫市" },
          { value: "130582@@沙河市", label: "沙河市" },
          { value: "130583@@其它区", label: "其它区" },
        ],
      },
      {
        value: "130600@@保定市",
        label: "保定市",
        children: [
          { value: "130602@@竞秀", label: "竞秀" },
          { value: "130603@@莲池", label: "莲池" },
          { value: "130604@@南市区", label: "南市区" },
          { value: "130621@@满城县", label: "满城县" },
          { value: "130622@@清苑县", label: "清苑县" },
          { value: "130623@@涞水县", label: "涞水县" },
          { value: "130624@@阜平县", label: "阜平县" },
          { value: "130625@@徐水县", label: "徐水县" },
          { value: "130626@@定兴县", label: "定兴县" },
          { value: "130627@@唐县", label: "唐县" },
          { value: "130628@@高阳县", label: "高阳县" },
          { value: "130629@@容城县", label: "容城县" },
          { value: "130630@@涞源县", label: "涞源县" },
          { value: "130631@@望都县", label: "望都县" },
          { value: "130632@@安新县", label: "安新县" },
          { value: "130633@@易县", label: "易县" },
          { value: "130634@@曲阳县", label: "曲阳县" },
          { value: "130635@@蠡县", label: "蠡县" },
          { value: "130636@@顺平县", label: "顺平县" },
          { value: "130637@@博野县", label: "博野县" },
          { value: "130638@@雄县", label: "雄县" },
          { value: "130681@@涿州市", label: "涿州市" },
          { value: "130682@@定州市", label: "定州市" },
          { value: "130683@@安国市", label: "安国市" },
          { value: "130684@@高碑店市", label: "高碑店市" },
          { value: "130698@@高开区", label: "高开区" },
          { value: "130699@@其它区", label: "其它区" },
        ],
      },
      {
        value: "130700@@张家口市",
        label: "张家口市",
        children: [
          { value: "130702@@桥东区", label: "桥东区" },
          { value: "130703@@桥西区", label: "桥西区" },
          { value: "130705@@宣化区", label: "宣化区" },
          { value: "130706@@下花园区", label: "下花园区" },
          { value: "130721@@宣化县", label: "宣化县" },
          { value: "130722@@张北县", label: "张北县" },
          { value: "130723@@康保县", label: "康保县" },
          { value: "130724@@沽源县", label: "沽源县" },
          { value: "130725@@尚义县", label: "尚义县" },
          { value: "130726@@蔚县", label: "蔚县" },
          { value: "130727@@阳原县", label: "阳原县" },
          { value: "130728@@怀安县", label: "怀安县" },
          { value: "130729@@万全县", label: "万全县" },
          { value: "130730@@怀来县", label: "怀来县" },
          { value: "130731@@涿鹿县", label: "涿鹿县" },
          { value: "130732@@赤城县", label: "赤城县" },
          { value: "130733@@崇礼县", label: "崇礼县" },
          { value: "130734@@其它区", label: "其它区" },
        ],
      },
      {
        value: "130800@@承德市",
        label: "承德市",
        children: [
          { value: "130802@@双桥区", label: "双桥区" },
          { value: "130803@@双滦区", label: "双滦区" },
          { value: "130804@@鹰手营子矿区", label: "鹰手营子矿区" },
          { value: "130821@@承德县", label: "承德县" },
          { value: "130822@@兴隆县", label: "兴隆县" },
          { value: "130823@@平泉县", label: "平泉县" },
          { value: "130824@@滦平县", label: "滦平县" },
          { value: "130825@@隆化县", label: "隆化县" },
          { value: "130826@@丰宁满族自治县", label: "丰宁满族自治县" },
          { value: "130827@@宽城满族自治县", label: "宽城满族自治县" },
          {
            value: "130828@@围场满族蒙古族自治县",
            label: "围场满族蒙古族自治县",
          },
          { value: "130829@@其它区", label: "其它区" },
        ],
      },
      {
        value: "130900@@沧州市",
        label: "沧州市",
        children: [
          { value: "130902@@新华区", label: "新华区" },
          { value: "130903@@运河区", label: "运河区" },
          { value: "130921@@沧县", label: "沧县" },
          { value: "130922@@青县", label: "青县" },
          { value: "130923@@东光县", label: "东光县" },
          { value: "130924@@海兴县", label: "海兴县" },
          { value: "130925@@盐山县", label: "盐山县" },
          { value: "130926@@肃宁县", label: "肃宁县" },
          { value: "130927@@南皮县", label: "南皮县" },
          { value: "130928@@吴桥县", label: "吴桥县" },
          { value: "130929@@献县", label: "献县" },
          { value: "130930@@孟村回族自治县", label: "孟村回族自治县" },
          { value: "130981@@泊头市", label: "泊头市" },
          { value: "130982@@任丘市", label: "任丘市" },
          { value: "130983@@黄骅市", label: "黄骅市" },
          { value: "130984@@河间市", label: "河间市" },
          { value: "130985@@其它区", label: "其它区" },
        ],
      },
      {
        value: "131000@@廊坊市",
        label: "廊坊市",
        children: [
          { value: "131002@@安次区", label: "安次区" },
          { value: "131003@@广阳区", label: "广阳区" },
          { value: "131022@@固安县", label: "固安县" },
          { value: "131023@@永清县", label: "永清县" },
          { value: "131024@@香河县", label: "香河县" },
          { value: "131025@@大城县", label: "大城县" },
          { value: "131026@@文安县", label: "文安县" },
          { value: "131028@@大厂回族自治县", label: "大厂回族自治县" },
          { value: "131051@@开发区", label: "开发区" },
          {
            value: "131052@@燕郊经济技术开发区",
            label: "燕郊经济技术开发区",
          },
          { value: "131081@@霸州市", label: "霸州市" },
          { value: "131082@@三河市", label: "三河市" },
          { value: "131083@@其它区", label: "其它区" },
        ],
      },
      {
        value: "131100@@衡水市",
        label: "衡水市",
        children: [
          { value: "131102@@桃城区", label: "桃城区" },
          { value: "131121@@枣强县", label: "枣强县" },
          { value: "131122@@武邑县", label: "武邑县" },
          { value: "131123@@武强县", label: "武强县" },
          { value: "131124@@饶阳县", label: "饶阳县" },
          { value: "131125@@安平县", label: "安平县" },
          { value: "131126@@故城县", label: "故城县" },
          { value: "131127@@景县", label: "景县" },
          { value: "131128@@阜城县", label: "阜城县" },
          { value: "131181@@冀州市", label: "冀州市" },
          { value: "131182@@深州市", label: "深州市" },
          { value: "131183@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "140000@@山西",
    label: "山西",
    children: [
      {
        value: "140100@@太原市",
        label: "太原市",
        children: [
          { value: "140105@@小店区", label: "小店区" },
          { value: "140106@@迎泽区", label: "迎泽区" },
          { value: "140107@@杏花岭区", label: "杏花岭区" },
          { value: "140108@@尖草坪区", label: "尖草坪区" },
          { value: "140109@@万柏林区", label: "万柏林区" },
          { value: "140110@@晋源区", label: "晋源区" },
          { value: "140121@@清徐县", label: "清徐县" },
          { value: "140122@@阳曲县", label: "阳曲县" },
          { value: "140123@@娄烦县", label: "娄烦县" },
          { value: "140181@@古交市", label: "古交市" },
          { value: "140182@@其它区", label: "其它区" },
        ],
      },
      {
        value: "140200@@大同市",
        label: "大同市",
        children: [
          { value: "140202@@城区", label: "城区" },
          { value: "140203@@矿区", label: "矿区" },
          { value: "140211@@南郊区", label: "南郊区" },
          { value: "140212@@新荣区", label: "新荣区" },
          { value: "140221@@阳高县", label: "阳高县" },
          { value: "140222@@天镇县", label: "天镇县" },
          { value: "140223@@广灵县", label: "广灵县" },
          { value: "140224@@灵丘县", label: "灵丘县" },
          { value: "140225@@浑源县", label: "浑源县" },
          { value: "140226@@左云县", label: "左云县" },
          { value: "140227@@大同县", label: "大同县" },
          { value: "140228@@其它区", label: "其它区" },
        ],
      },
      {
        value: "140300@@阳泉市",
        label: "阳泉市",
        children: [
          { value: "140302@@城区", label: "城区" },
          { value: "140303@@矿区", label: "矿区" },
          { value: "140311@@郊区", label: "郊区" },
          { value: "140321@@平定县", label: "平定县" },
          { value: "140322@@盂县", label: "盂县" },
          { value: "140323@@其它区", label: "其它区" },
        ],
      },
      {
        value: "140400@@长治市",
        label: "长治市",
        children: [
          { value: "140421@@长治县", label: "长治县" },
          { value: "140423@@襄垣县", label: "襄垣县" },
          { value: "140424@@屯留县", label: "屯留县" },
          { value: "140425@@平顺县", label: "平顺县" },
          { value: "140426@@黎城县", label: "黎城县" },
          { value: "140427@@壶关县", label: "壶关县" },
          { value: "140428@@长子县", label: "长子县" },
          { value: "140429@@武乡县", label: "武乡县" },
          { value: "140430@@沁县", label: "沁县" },
          { value: "140431@@沁源县", label: "沁源县" },
          { value: "140481@@潞城市", label: "潞城市" },
          { value: "140482@@城区", label: "城区" },
          { value: "140483@@郊区", label: "郊区" },
          { value: "140484@@高新区", label: "高新区" },
          { value: "140485@@其它区", label: "其它区" },
        ],
      },
      {
        value: "140500@@晋城市",
        label: "晋城市",
        children: [
          { value: "140502@@城区", label: "城区" },
          { value: "140521@@沁水县", label: "沁水县" },
          { value: "140522@@阳城县", label: "阳城县" },
          { value: "140524@@陵川县", label: "陵川县" },
          { value: "140525@@泽州县", label: "泽州县" },
          { value: "140581@@高平市", label: "高平市" },
          { value: "140582@@其它区", label: "其它区" },
        ],
      },
      {
        value: "140600@@朔州市",
        label: "朔州市",
        children: [
          { value: "140602@@朔城区", label: "朔城区" },
          { value: "140603@@平鲁区", label: "平鲁区" },
          { value: "140621@@山阴县", label: "山阴县" },
          { value: "140622@@应县", label: "应县" },
          { value: "140623@@右玉县", label: "右玉县" },
          { value: "140624@@怀仁县", label: "怀仁县" },
          { value: "140625@@其它区", label: "其它区" },
        ],
      },
      {
        value: "140700@@晋中市",
        label: "晋中市",
        children: [
          { value: "140702@@榆次区", label: "榆次区" },
          { value: "140721@@榆社县", label: "榆社县" },
          { value: "140722@@左权县", label: "左权县" },
          { value: "140723@@和顺县", label: "和顺县" },
          { value: "140724@@昔阳县", label: "昔阳县" },
          { value: "140725@@寿阳县", label: "寿阳县" },
          { value: "140726@@太谷县", label: "太谷县" },
          { value: "140727@@祁县", label: "祁县" },
          { value: "140728@@平遥县", label: "平遥县" },
          { value: "140729@@灵石县", label: "灵石县" },
          { value: "140781@@介休市", label: "介休市" },
          { value: "140782@@其它区", label: "其它区" },
        ],
      },
      {
        value: "140800@@运城市",
        label: "运城市",
        children: [
          { value: "140802@@盐湖区", label: "盐湖区" },
          { value: "140821@@临猗县", label: "临猗县" },
          { value: "140822@@万荣县", label: "万荣县" },
          { value: "140823@@闻喜县", label: "闻喜县" },
          { value: "140824@@稷山县", label: "稷山县" },
          { value: "140825@@新绛县", label: "新绛县" },
          { value: "140826@@绛县", label: "绛县" },
          { value: "140827@@垣曲县", label: "垣曲县" },
          { value: "140828@@夏县", label: "夏县" },
          { value: "140829@@平陆县", label: "平陆县" },
          { value: "140830@@芮城县", label: "芮城县" },
          { value: "140881@@永济市", label: "永济市" },
          { value: "140882@@河津市", label: "河津市" },
          { value: "140883@@其它区", label: "其它区" },
        ],
      },
      {
        value: "140900@@忻州市",
        label: "忻州市",
        children: [
          { value: "140902@@忻府区", label: "忻府区" },
          { value: "140921@@定襄县", label: "定襄县" },
          { value: "140922@@五台县", label: "五台县" },
          { value: "140923@@代县", label: "代县" },
          { value: "140924@@繁峙县", label: "繁峙县" },
          { value: "140925@@宁武县", label: "宁武县" },
          { value: "140926@@静乐县", label: "静乐县" },
          { value: "140927@@神池县", label: "神池县" },
          { value: "140928@@五寨县", label: "五寨县" },
          { value: "140929@@岢岚县", label: "岢岚县" },
          { value: "140930@@河曲县", label: "河曲县" },
          { value: "140931@@保德县", label: "保德县" },
          { value: "140932@@偏关县", label: "偏关县" },
          { value: "140981@@原平市", label: "原平市" },
          { value: "140982@@其它区", label: "其它区" },
        ],
      },
      {
        value: "141000@@临汾市",
        label: "临汾市",
        children: [
          { value: "141002@@尧都区", label: "尧都区" },
          { value: "141021@@曲沃县", label: "曲沃县" },
          { value: "141022@@翼城县", label: "翼城县" },
          { value: "141023@@襄汾县", label: "襄汾县" },
          { value: "141024@@洪洞县", label: "洪洞县" },
          { value: "141025@@古县", label: "古县" },
          { value: "141026@@安泽县", label: "安泽县" },
          { value: "141027@@浮山县", label: "浮山县" },
          { value: "141028@@吉县", label: "吉县" },
          { value: "141029@@乡宁县", label: "乡宁县" },
          { value: "141030@@大宁县", label: "大宁县" },
          { value: "141031@@隰县", label: "隰县" },
          { value: "141032@@永和县", label: "永和县" },
          { value: "141033@@蒲县", label: "蒲县" },
          { value: "141034@@汾西县", label: "汾西县" },
          { value: "141081@@侯马市", label: "侯马市" },
          { value: "141082@@霍州市", label: "霍州市" },
          { value: "141083@@其它区", label: "其它区" },
        ],
      },
      {
        value: "141100@@吕梁市",
        label: "吕梁市",
        children: [
          { value: "141102@@离石区", label: "离石区" },
          { value: "141121@@文水县", label: "文水县" },
          { value: "141122@@交城县", label: "交城县" },
          { value: "141123@@兴县", label: "兴县" },
          { value: "141124@@临县", label: "临县" },
          { value: "141125@@柳林县", label: "柳林县" },
          { value: "141126@@石楼县", label: "石楼县" },
          { value: "141127@@岚县", label: "岚县" },
          { value: "141128@@方山县", label: "方山县" },
          { value: "141129@@中阳县", label: "中阳县" },
          { value: "141130@@交口县", label: "交口县" },
          { value: "141181@@孝义市", label: "孝义市" },
          { value: "141182@@汾阳市", label: "汾阳市" },
          { value: "141183@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "150000@@内蒙古",
    label: "内蒙古",
    children: [
      {
        value: "150100@@呼和浩特市",
        label: "呼和浩特市",
        children: [
          { value: "150102@@新城区", label: "新城区" },
          { value: "150103@@回民区", label: "回民区" },
          { value: "150104@@玉泉区", label: "玉泉区" },
          { value: "150105@@赛罕区", label: "赛罕区" },
          { value: "150121@@土默特左旗", label: "土默特左旗" },
          { value: "150122@@托克托县", label: "托克托县" },
          { value: "150123@@和林格尔县", label: "和林格尔县" },
          { value: "150124@@清水河县", label: "清水河县" },
          { value: "150125@@武川县", label: "武川县" },
          { value: "150126@@其它区", label: "其它区" },
        ],
      },
      {
        value: "150200@@包头市",
        label: "包头市",
        children: [
          { value: "150202@@东河区", label: "东河区" },
          { value: "150203@@昆都仑区", label: "昆都仑区" },
          { value: "150204@@青山区", label: "青山区" },
          { value: "150205@@石拐区", label: "石拐区" },
          { value: "150206@@白云矿区", label: "白云矿区" },
          { value: "150207@@九原区", label: "九原区" },
          { value: "150221@@土默特右旗", label: "土默特右旗" },
          { value: "150222@@固阳县", label: "固阳县" },
          {
            value: "150223@@达尔罕茂明安联合旗",
            label: "达尔罕茂明安联合旗",
          },
          { value: "150224@@其它区", label: "其它区" },
        ],
      },
      {
        value: "150300@@乌海市",
        label: "乌海市",
        children: [
          { value: "150302@@海勃湾区", label: "海勃湾区" },
          { value: "150303@@海南区", label: "海南区" },
          { value: "150304@@乌达区", label: "乌达区" },
          { value: "150305@@其它区", label: "其它区" },
        ],
      },
      {
        value: "150400@@赤峰市",
        label: "赤峰市",
        children: [
          { value: "150402@@红山区", label: "红山区" },
          { value: "150403@@元宝山区", label: "元宝山区" },
          { value: "150404@@松山区", label: "松山区" },
          { value: "150421@@阿鲁科尔沁旗", label: "阿鲁科尔沁旗" },
          { value: "150422@@巴林左旗", label: "巴林左旗" },
          { value: "150423@@巴林右旗", label: "巴林右旗" },
          { value: "150424@@林西县", label: "林西县" },
          { value: "150425@@克什克腾旗", label: "克什克腾旗" },
          { value: "150426@@翁牛特旗", label: "翁牛特旗" },
          { value: "150428@@喀喇沁旗", label: "喀喇沁旗" },
          { value: "150429@@宁城县", label: "宁城县" },
          { value: "150430@@敖汉旗", label: "敖汉旗" },
          { value: "150431@@其它区", label: "其它区" },
        ],
      },
      {
        value: "150500@@通辽市",
        label: "通辽市",
        children: [
          { value: "150502@@科尔沁区", label: "科尔沁区" },
          { value: "150521@@科尔沁左翼中旗", label: "科尔沁左翼中旗" },
          { value: "150522@@科尔沁左翼后旗", label: "科尔沁左翼后旗" },
          { value: "150523@@开鲁县", label: "开鲁县" },
          { value: "150524@@库伦旗", label: "库伦旗" },
          { value: "150525@@奈曼旗", label: "奈曼旗" },
          { value: "150526@@扎鲁特旗", label: "扎鲁特旗" },
          { value: "150581@@霍林郭勒市", label: "霍林郭勒市" },
          { value: "150582@@其它区", label: "其它区" },
        ],
      },
      {
        value: "150600@@鄂尔多斯市",
        label: "鄂尔多斯市",
        children: [
          { value: "150602@@东胜区", label: "东胜区" },
          { value: "150621@@达拉特旗", label: "达拉特旗" },
          { value: "150622@@准格尔旗", label: "准格尔旗" },
          { value: "150623@@鄂托克前旗", label: "鄂托克前旗" },
          { value: "150624@@鄂托克旗", label: "鄂托克旗" },
          { value: "150625@@杭锦旗", label: "杭锦旗" },
          { value: "150626@@乌审旗", label: "乌审旗" },
          { value: "150627@@伊金霍洛旗", label: "伊金霍洛旗" },
          { value: "150628@@其它区", label: "其它区" },
        ],
      },
      {
        value: "150700@@呼伦贝尔市",
        label: "呼伦贝尔市",
        children: [
          { value: "150702@@海拉尔区", label: "海拉尔区" },
          { value: "150721@@阿荣旗", label: "阿荣旗" },
          {
            value: "150722@@莫力达瓦达斡尔族自治旗",
            label: "莫力达瓦达斡尔族自治旗",
          },
          { value: "150723@@鄂伦春自治旗", label: "鄂伦春自治旗" },
          { value: "150724@@鄂温克族自治旗", label: "鄂温克族自治旗" },
          { value: "150725@@陈巴尔虎旗", label: "陈巴尔虎旗" },
          { value: "150726@@新巴尔虎左旗", label: "新巴尔虎左旗" },
          { value: "150727@@新巴尔虎右旗", label: "新巴尔虎右旗" },
          { value: "150781@@满洲里市", label: "满洲里市" },
          { value: "150782@@牙克石市", label: "牙克石市" },
          { value: "150783@@扎兰屯市", label: "扎兰屯市" },
          { value: "150784@@额尔古纳市", label: "额尔古纳市" },
          { value: "150785@@根河市", label: "根河市" },
          { value: "150786@@其它区", label: "其它区" },
        ],
      },
      {
        value: "150800@@巴彦淖尔市",
        label: "巴彦淖尔市",
        children: [
          { value: "150802@@临河区", label: "临河区" },
          { value: "150821@@五原县", label: "五原县" },
          { value: "150822@@磴口县", label: "磴口县" },
          { value: "150823@@乌拉特前旗", label: "乌拉特前旗" },
          { value: "150824@@乌拉特中旗", label: "乌拉特中旗" },
          { value: "150825@@乌拉特后旗", label: "乌拉特后旗" },
          { value: "150826@@杭锦后旗", label: "杭锦后旗" },
          { value: "150827@@其它区", label: "其它区" },
        ],
      },
      {
        value: "150900@@乌兰察布市",
        label: "乌兰察布市",
        children: [
          { value: "150902@@集宁区", label: "集宁区" },
          { value: "150921@@卓资县", label: "卓资县" },
          { value: "150922@@化德县", label: "化德县" },
          { value: "150923@@商都县", label: "商都县" },
          { value: "150924@@兴和县", label: "兴和县" },
          { value: "150925@@凉城县", label: "凉城县" },
          { value: "150926@@察哈尔右翼前旗", label: "察哈尔右翼前旗" },
          { value: "150927@@察哈尔右翼中旗", label: "察哈尔右翼中旗" },
          { value: "150928@@察哈尔右翼后旗", label: "察哈尔右翼后旗" },
          { value: "150929@@四子王旗", label: "四子王旗" },
          { value: "150981@@丰镇市", label: "丰镇市" },
          { value: "150982@@其它区", label: "其它区" },
        ],
      },
      {
        value: "152200@@兴安盟",
        label: "兴安盟",
        children: [
          { value: "152201@@乌兰浩特市", label: "乌兰浩特市" },
          { value: "152202@@阿尔山市", label: "阿尔山市" },
          { value: "152221@@科尔沁右翼前旗", label: "科尔沁右翼前旗" },
          { value: "152222@@科尔沁右翼中旗", label: "科尔沁右翼中旗" },
          { value: "152223@@扎赉特旗", label: "扎赉特旗" },
          { value: "152224@@突泉县", label: "突泉县" },
          { value: "152225@@其它区", label: "其它区" },
        ],
      },
      {
        value: "152500@@锡林郭勒盟",
        label: "锡林郭勒盟",
        children: [
          { value: "152501@@二连浩特市", label: "二连浩特市" },
          { value: "152502@@锡林浩特市", label: "锡林浩特市" },
          { value: "152522@@阿巴嘎旗", label: "阿巴嘎旗" },
          { value: "152523@@苏尼特左旗", label: "苏尼特左旗" },
          { value: "152524@@苏尼特右旗", label: "苏尼特右旗" },
          { value: "152525@@东乌珠穆沁旗", label: "东乌珠穆沁旗" },
          { value: "152526@@西乌珠穆沁旗", label: "西乌珠穆沁旗" },
          { value: "152527@@太仆寺旗", label: "太仆寺旗" },
          { value: "152528@@镶黄旗", label: "镶黄旗" },
          { value: "152529@@正镶白旗", label: "正镶白旗" },
          { value: "152530@@正蓝旗", label: "正蓝旗" },
          { value: "152531@@多伦县", label: "多伦县" },
          { value: "152532@@其它区", label: "其它区" },
        ],
      },
      {
        value: "152900@@阿拉善盟",
        label: "阿拉善盟",
        children: [
          { value: "152921@@阿拉善左旗", label: "阿拉善左旗" },
          { value: "152922@@阿拉善右旗", label: "阿拉善右旗" },
          { value: "152923@@额济纳旗", label: "额济纳旗" },
          { value: "152924@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "210000@@辽宁",
    label: "辽宁",
    children: [
      {
        value: "210100@@沈阳市",
        label: "沈阳市",
        children: [
          { value: "210102@@和平区", label: "和平区" },
          { value: "210103@@沈河区", label: "沈河区" },
          { value: "210104@@大东区", label: "大东区" },
          { value: "210105@@皇姑区", label: "皇姑区" },
          { value: "210106@@铁西区", label: "铁西区" },
          { value: "210111@@苏家屯区", label: "苏家屯区" },
          { value: "210112@@浑南", label: "浑南" },
          { value: "210113@@新城子区", label: "新城子区" },
          { value: "210114@@于洪区", label: "于洪区" },
          { value: "210122@@辽中县", label: "辽中县" },
          { value: "210123@@康平县", label: "康平县" },
          { value: "210124@@法库县", label: "法库县" },
          { value: "210181@@新民市", label: "新民市" },
          { value: "210182@@浑南新区", label: "浑南新区" },
          { value: "210183@@张士开发区", label: "张士开发区" },
          { value: "210184@@沈北新区", label: "沈北新区" },
          { value: "210185@@其它区", label: "其它区" },
        ],
      },
      {
        value: "210200@@大连市",
        label: "大连市",
        children: [
          { value: "210202@@中山区", label: "中山区" },
          { value: "210203@@西岗区", label: "西岗区" },
          { value: "210204@@沙河口区", label: "沙河口区" },
          { value: "210211@@甘井子区", label: "甘井子区" },
          { value: "210212@@旅顺口区", label: "旅顺口区" },
          { value: "210213@@金州区", label: "金州区" },
          { value: "210224@@长海县", label: "长海县" },
          { value: "210251@@开发区", label: "开发区" },
          { value: "210281@@瓦房店市", label: "瓦房店市" },
          { value: "210282@@普兰店市", label: "普兰店市" },
          { value: "210283@@庄河市", label: "庄河市" },
          { value: "210297@@岭前区", label: "岭前区" },
          { value: "210298@@其它区", label: "其它区" },
        ],
      },
      {
        value: "210300@@鞍山市",
        label: "鞍山市",
        children: [
          { value: "210302@@铁东区", label: "铁东区" },
          { value: "210303@@铁西区", label: "铁西区" },
          { value: "210304@@立山区", label: "立山区" },
          { value: "210311@@千山区", label: "千山区" },
          { value: "210321@@台安县", label: "台安县" },
          { value: "210323@@岫岩满族自治县", label: "岫岩满族自治县" },
          { value: "210351@@高新区", label: "高新区" },
          { value: "210381@@海城市", label: "海城市" },
          { value: "210382@@其它区", label: "其它区" },
        ],
      },
      {
        value: "210400@@抚顺市",
        label: "抚顺市",
        children: [
          { value: "210402@@新抚区", label: "新抚区" },
          { value: "210403@@东洲区", label: "东洲区" },
          { value: "210404@@望花区", label: "望花区" },
          { value: "210411@@顺城区", label: "顺城区" },
          { value: "210421@@抚顺县", label: "抚顺县" },
          { value: "210422@@新宾满族自治县", label: "新宾满族自治县" },
          { value: "210423@@清原满族自治县", label: "清原满族自治县" },
          { value: "210424@@其它区", label: "其它区" },
        ],
      },
      {
        value: "210500@@本溪市",
        label: "本溪市",
        children: [
          { value: "210502@@平山区", label: "平山区" },
          { value: "210503@@溪湖区", label: "溪湖区" },
          { value: "210504@@明山区", label: "明山区" },
          { value: "210505@@南芬区", label: "南芬区" },
          { value: "210521@@本溪满族自治县", label: "本溪满族自治县" },
          { value: "210522@@桓仁满族自治县", label: "桓仁满族自治县" },
          { value: "210523@@其它区", label: "其它区" },
        ],
      },
      {
        value: "210600@@丹东市",
        label: "丹东市",
        children: [
          { value: "210602@@元宝区", label: "元宝区" },
          { value: "210603@@振兴区", label: "振兴区" },
          { value: "210604@@振安区", label: "振安区" },
          { value: "210624@@宽甸满族自治县", label: "宽甸满族自治县" },
          { value: "210681@@东港市", label: "东港市" },
          { value: "210682@@凤城市", label: "凤城市" },
          { value: "210683@@其它区", label: "其它区" },
        ],
      },
      {
        value: "210700@@锦州市",
        label: "锦州市",
        children: [
          { value: "210702@@古塔区", label: "古塔区" },
          { value: "210703@@凌河区", label: "凌河区" },
          { value: "210711@@太和区", label: "太和区" },
          { value: "210726@@黑山县", label: "黑山县" },
          { value: "210727@@义县", label: "义县" },
          { value: "210781@@凌海市", label: "凌海市" },
          { value: "210782@@北镇市", label: "北镇市" },
          { value: "210783@@其它区", label: "其它区" },
        ],
      },
      {
        value: "210800@@营口市",
        label: "营口市",
        children: [
          { value: "210802@@站前区", label: "站前区" },
          { value: "210803@@西市区", label: "西市区" },
          { value: "210804@@鲅鱼圈区", label: "鲅鱼圈区" },
          { value: "210811@@老边区", label: "老边区" },
          { value: "210881@@盖州市", label: "盖州市" },
          { value: "210882@@大石桥市", label: "大石桥市" },
          { value: "210883@@其它区", label: "其它区" },
        ],
      },
      {
        value: "210900@@阜新市",
        label: "阜新市",
        children: [
          { value: "210902@@海州区", label: "海州区" },
          { value: "210903@@新邱区", label: "新邱区" },
          { value: "210904@@太平区", label: "太平区" },
          { value: "210905@@清河门区", label: "清河门区" },
          { value: "210911@@细河区", label: "细河区" },
          { value: "210921@@阜新蒙古族自治县", label: "阜新蒙古族自治县" },
          { value: "210922@@彰武县", label: "彰武县" },
          { value: "210923@@其它区", label: "其它区" },
        ],
      },
      {
        value: "211000@@辽阳市",
        label: "辽阳市",
        children: [
          { value: "211002@@白塔区", label: "白塔区" },
          { value: "211003@@文圣区", label: "文圣区" },
          { value: "211004@@宏伟区", label: "宏伟区" },
          { value: "211005@@弓长岭区", label: "弓长岭区" },
          { value: "211011@@太子河区", label: "太子河区" },
          { value: "211021@@辽阳县", label: "辽阳县" },
          { value: "211081@@灯塔市", label: "灯塔市" },
          { value: "211082@@其它区", label: "其它区" },
        ],
      },
      {
        value: "211100@@盘锦市",
        label: "盘锦市",
        children: [
          { value: "211102@@双台子区", label: "双台子区" },
          { value: "211103@@兴隆台区", label: "兴隆台区" },
          { value: "211121@@大洼县", label: "大洼县" },
          { value: "211122@@盘山县", label: "盘山县" },
          { value: "211123@@其它区", label: "其它区" },
        ],
      },
      {
        value: "211200@@铁岭市",
        label: "铁岭市",
        children: [
          { value: "211202@@银州区", label: "银州区" },
          { value: "211204@@清河区", label: "清河区" },
          { value: "211221@@铁岭县", label: "铁岭县" },
          { value: "211223@@西丰县", label: "西丰县" },
          { value: "211224@@昌图县", label: "昌图县" },
          { value: "211281@@调兵山市", label: "调兵山市" },
          { value: "211282@@开原市", label: "开原市" },
          { value: "211283@@其它区", label: "其它区" },
        ],
      },
      {
        value: "211300@@朝阳市",
        label: "朝阳市",
        children: [
          { value: "211302@@双塔区", label: "双塔区" },
          { value: "211303@@龙城区", label: "龙城区" },
          { value: "211321@@朝阳县", label: "朝阳县" },
          { value: "211322@@建平县", label: "建平县" },
          { value: "211324@@喀左", label: "喀左" },
          { value: "211381@@北票市", label: "北票市" },
          { value: "211382@@凌源市", label: "凌源市" },
          { value: "211383@@其它区", label: "其它区" },
        ],
      },
      {
        value: "211400@@葫芦岛市",
        label: "葫芦岛市",
        children: [
          { value: "211402@@连山区", label: "连山区" },
          { value: "211403@@龙港区", label: "龙港区" },
          { value: "211404@@南票区", label: "南票区" },
          { value: "211421@@绥中县", label: "绥中县" },
          { value: "211422@@建昌县", label: "建昌县" },
          { value: "211481@@兴城市", label: "兴城市" },
          { value: "211482@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "220000@@吉林",
    label: "吉林",
    children: [
      {
        value: "220100@@长春市",
        label: "长春市",
        children: [
          { value: "220102@@南关区", label: "南关区" },
          { value: "220103@@宽城区", label: "宽城区" },
          { value: "220104@@朝阳区", label: "朝阳区" },
          { value: "220105@@二道区", label: "二道区" },
          { value: "220106@@绿园区", label: "绿园区" },
          { value: "220112@@双阳区", label: "双阳区" },
          { value: "220122@@农安县", label: "农安县" },
          { value: "220181@@九台市", label: "九台市" },
          { value: "220182@@榆树市", label: "榆树市" },
          { value: "220183@@德惠市", label: "德惠市" },
          {
            value: "220184@@高新技术产业开发区",
            label: "高新技术产业开发区",
          },
          { value: "220185@@汽车产业开发区", label: "汽车产业开发区" },
          { value: "220186@@经济技术开发区", label: "经济技术开发区" },
          { value: "220187@@净月旅游开发区", label: "净月旅游开发区" },
          { value: "220188@@其它区", label: "其它区" },
        ],
      },
      {
        value: "220200@@吉林市",
        label: "吉林市",
        children: [
          { value: "220202@@昌邑区", label: "昌邑区" },
          { value: "220203@@龙潭区", label: "龙潭区" },
          { value: "220204@@船营区", label: "船营区" },
          { value: "220211@@丰满区", label: "丰满区" },
          { value: "220221@@永吉县", label: "永吉县" },
          { value: "220281@@蛟河市", label: "蛟河市" },
          { value: "220282@@桦甸市", label: "桦甸市" },
          { value: "220283@@舒兰市", label: "舒兰市" },
          { value: "220284@@磐石市", label: "磐石市" },
          { value: "220285@@其它区", label: "其它区" },
        ],
      },
      {
        value: "220300@@四平市",
        label: "四平市",
        children: [
          { value: "220302@@铁西区", label: "铁西区" },
          { value: "220303@@铁东区", label: "铁东区" },
          { value: "220322@@梨树县", label: "梨树县" },
          { value: "220323@@伊通满族自治县", label: "伊通满族自治县" },
          { value: "220381@@公主岭市", label: "公主岭市" },
          { value: "220382@@双辽市", label: "双辽市" },
          { value: "220383@@其它区", label: "其它区" },
        ],
      },
      {
        value: "220400@@辽源市",
        label: "辽源市",
        children: [
          { value: "220402@@龙山区", label: "龙山区" },
          { value: "220403@@西安区", label: "西安区" },
          { value: "220421@@东丰县", label: "东丰县" },
          { value: "220422@@东辽县", label: "东辽县" },
          { value: "220423@@其它区", label: "其它区" },
        ],
      },
      {
        value: "220500@@通化市",
        label: "通化市",
        children: [
          { value: "220502@@东昌区", label: "东昌区" },
          { value: "220503@@二道江区", label: "二道江区" },
          { value: "220521@@通化县", label: "通化县" },
          { value: "220523@@辉南县", label: "辉南县" },
          { value: "220524@@柳河县", label: "柳河县" },
          { value: "220581@@梅河口市", label: "梅河口市" },
          { value: "220582@@集安市", label: "集安市" },
          { value: "220583@@其它区", label: "其它区" },
        ],
      },
      {
        value: "220600@@白山市",
        label: "白山市",
        children: [
          { value: "220602@@浑江", label: "浑江" },
          { value: "220621@@抚松县", label: "抚松县" },
          { value: "220622@@靖宇县", label: "靖宇县" },
          { value: "220623@@长白朝鲜族自治县", label: "长白朝鲜族自治县" },
          { value: "220625@@江源县", label: "江源县" },
          { value: "220681@@临江市", label: "临江市" },
          { value: "220682@@其它区", label: "其它区" },
        ],
      },
      {
        value: "220700@@松原市",
        label: "松原市",
        children: [
          { value: "220702@@宁江区", label: "宁江区" },
          {
            value: "220721@@前郭尔罗斯蒙古族自治县",
            label: "前郭尔罗斯蒙古族自治县",
          },
          { value: "220722@@长岭县", label: "长岭县" },
          { value: "220723@@乾安县", label: "乾安县" },
          { value: "220724@@扶余县", label: "扶余县" },
          { value: "220725@@其它区", label: "其它区" },
        ],
      },
      {
        value: "220800@@白城市",
        label: "白城市",
        children: [
          { value: "220802@@洮北区", label: "洮北区" },
          { value: "220821@@镇赉县", label: "镇赉县" },
          { value: "220822@@通榆县", label: "通榆县" },
          { value: "220881@@洮南市", label: "洮南市" },
          { value: "220882@@大安市", label: "大安市" },
          { value: "220883@@其它区", label: "其它区" },
        ],
      },
      {
        value: "222400@@延边朝鲜族自治州",
        label: "延边朝鲜族自治州",
        children: [
          { value: "222401@@延吉市", label: "延吉市" },
          { value: "222402@@图们市", label: "图们市" },
          { value: "222403@@敦化市", label: "敦化市" },
          { value: "222404@@珲春市", label: "珲春市" },
          { value: "222405@@龙井市", label: "龙井市" },
          { value: "222406@@和龙市", label: "和龙市" },
          { value: "222424@@汪清县", label: "汪清县" },
          { value: "222426@@安图县", label: "安图县" },
          { value: "222427@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "230000@@黑龙江",
    label: "黑龙江",
    children: [
      {
        value: "230100@@哈尔滨市",
        label: "哈尔滨市",
        children: [
          { value: "230102@@道里区", label: "道里区" },
          { value: "230103@@南岗区", label: "南岗区" },
          { value: "230104@@道外区", label: "道外区" },
          { value: "230106@@香坊区", label: "香坊区" },
          { value: "230107@@动力区", label: "动力区" },
          { value: "230108@@平房区", label: "平房区" },
          { value: "230109@@松北区", label: "松北区" },
          { value: "230111@@呼兰区", label: "呼兰区" },
          { value: "230123@@依兰县", label: "依兰县" },
          { value: "230124@@方正县", label: "方正县" },
          { value: "230125@@宾县", label: "宾县" },
          { value: "230126@@巴彦县", label: "巴彦县" },
          { value: "230127@@木兰县", label: "木兰县" },
          { value: "230128@@通河县", label: "通河县" },
          { value: "230129@@延寿县", label: "延寿县" },
          { value: "230181@@阿城市", label: "阿城市" },
          { value: "230182@@双城市", label: "双城市" },
          { value: "230183@@尚志市", label: "尚志市" },
          { value: "230184@@五常市", label: "五常市" },
          { value: "230185@@阿城市", label: "阿城市" },
          { value: "230186@@其它区", label: "其它区" },
        ],
      },
      {
        value: "230200@@齐齐哈尔市",
        label: "齐齐哈尔市",
        children: [
          { value: "230202@@龙沙区", label: "龙沙区" },
          { value: "230203@@建华区", label: "建华区" },
          { value: "230204@@铁锋区", label: "铁锋区" },
          { value: "230205@@昂昂溪区", label: "昂昂溪区" },
          { value: "230206@@富拉尔基区", label: "富拉尔基区" },
          { value: "230207@@碾子山区", label: "碾子山区" },
          { value: "230208@@梅里斯达斡尔族区", label: "梅里斯达斡尔族区" },
          { value: "230221@@龙江县", label: "龙江县" },
          { value: "230223@@依安县", label: "依安县" },
          { value: "230224@@泰来县", label: "泰来县" },
          { value: "230225@@甘南县", label: "甘南县" },
          { value: "230227@@富裕县", label: "富裕县" },
          { value: "230229@@克山县", label: "克山县" },
          { value: "230230@@克东县", label: "克东县" },
          { value: "230231@@拜泉县", label: "拜泉县" },
          { value: "230281@@讷河市", label: "讷河市" },
          { value: "230282@@其它区", label: "其它区" },
        ],
      },
      {
        value: "230300@@鸡西市",
        label: "鸡西市",
        children: [
          { value: "230302@@鸡冠区", label: "鸡冠区" },
          { value: "230303@@恒山区", label: "恒山区" },
          { value: "230304@@滴道区", label: "滴道区" },
          { value: "230305@@梨树区", label: "梨树区" },
          { value: "230306@@城子河区", label: "城子河区" },
          { value: "230307@@麻山区", label: "麻山区" },
          { value: "230321@@鸡东县", label: "鸡东县" },
          { value: "230381@@虎林市", label: "虎林市" },
          { value: "230382@@密山市", label: "密山市" },
          { value: "230383@@其它区", label: "其它区" },
        ],
      },
      {
        value: "230400@@鹤岗市",
        label: "鹤岗市",
        children: [
          { value: "230402@@向阳区", label: "向阳区" },
          { value: "230403@@工农区", label: "工农区" },
          { value: "230404@@南山区", label: "南山区" },
          { value: "230405@@兴安区", label: "兴安区" },
          { value: "230406@@东山区", label: "东山区" },
          { value: "230407@@兴山区", label: "兴山区" },
          { value: "230421@@萝北县", label: "萝北县" },
          { value: "230422@@绥滨县", label: "绥滨县" },
          { value: "230423@@其它区", label: "其它区" },
        ],
      },
      {
        value: "230500@@双鸭山市",
        label: "双鸭山市",
        children: [
          { value: "230502@@尖山区", label: "尖山区" },
          { value: "230503@@岭东区", label: "岭东区" },
          { value: "230505@@四方台区", label: "四方台区" },
          { value: "230506@@宝山区", label: "宝山区" },
          { value: "230521@@集贤县", label: "集贤县" },
          { value: "230522@@友谊县", label: "友谊县" },
          { value: "230523@@宝清县", label: "宝清县" },
          { value: "230524@@饶河县", label: "饶河县" },
          { value: "230525@@其它区", label: "其它区" },
        ],
      },
      {
        value: "230600@@大庆市",
        label: "大庆市",
        children: [
          { value: "230602@@萨尔图区", label: "萨尔图区" },
          { value: "230603@@龙凤区", label: "龙凤区" },
          { value: "230604@@让胡路区", label: "让胡路区" },
          { value: "230605@@红岗区", label: "红岗区" },
          { value: "230606@@大同区", label: "大同区" },
          { value: "230621@@肇州县", label: "肇州县" },
          { value: "230622@@肇源县", label: "肇源县" },
          { value: "230623@@林甸县", label: "林甸县" },
          {
            value: "230624@@杜尔伯特蒙古族自治县",
            label: "杜尔伯特蒙古族自治县",
          },
          { value: "230625@@其它区", label: "其它区" },
        ],
      },
      {
        value: "230700@@伊春市",
        label: "伊春市",
        children: [
          { value: "230702@@伊春区", label: "伊春区" },
          { value: "230703@@南岔区", label: "南岔区" },
          { value: "230704@@友好区", label: "友好区" },
          { value: "230705@@西林区", label: "西林区" },
          { value: "230706@@翠峦区", label: "翠峦区" },
          { value: "230707@@新青区", label: "新青区" },
          { value: "230708@@美溪区", label: "美溪区" },
          { value: "230709@@金山屯区", label: "金山屯区" },
          { value: "230710@@五营区", label: "五营区" },
          { value: "230711@@乌马河区", label: "乌马河区" },
          { value: "230712@@汤旺河区", label: "汤旺河区" },
          { value: "230713@@带岭区", label: "带岭区" },
          { value: "230714@@乌伊岭区", label: "乌伊岭区" },
          { value: "230715@@红星区", label: "红星区" },
          { value: "230716@@上甘岭区", label: "上甘岭区" },
          { value: "230722@@嘉荫县", label: "嘉荫县" },
          { value: "230781@@铁力市", label: "铁力市" },
          { value: "230782@@其它区", label: "其它区" },
        ],
      },
      {
        value: "230800@@佳木斯市",
        label: "佳木斯市",
        children: [
          { value: "230802@@永红区", label: "永红区" },
          { value: "230803@@向阳区", label: "向阳区" },
          { value: "230804@@前进区", label: "前进区" },
          { value: "230805@@东风区", label: "东风区" },
          { value: "230811@@郊区", label: "郊区" },
          { value: "230822@@桦南县", label: "桦南县" },
          { value: "230826@@桦川县", label: "桦川县" },
          { value: "230828@@汤原县", label: "汤原县" },
          { value: "230833@@抚远县", label: "抚远县" },
          { value: "230881@@同江市", label: "同江市" },
          { value: "230882@@富锦市", label: "富锦市" },
          { value: "230883@@其它区", label: "其它区" },
        ],
      },
      {
        value: "230900@@七台河市",
        label: "七台河市",
        children: [
          { value: "230902@@新兴区", label: "新兴区" },
          { value: "230903@@桃山区", label: "桃山区" },
          { value: "230904@@茄子河区", label: "茄子河区" },
          { value: "230921@@勃利县", label: "勃利县" },
          { value: "230922@@其它区", label: "其它区" },
        ],
      },
      {
        value: "231000@@牡丹江市",
        label: "牡丹江市",
        children: [
          { value: "231002@@东安区", label: "东安区" },
          { value: "231003@@阳明区", label: "阳明区" },
          { value: "231004@@爱民区", label: "爱民区" },
          { value: "231005@@西安区", label: "西安区" },
          { value: "231024@@东宁县", label: "东宁县" },
          { value: "231025@@林口县", label: "林口县" },
          { value: "231081@@绥芬河市", label: "绥芬河市" },
          { value: "231083@@海林市", label: "海林市" },
          { value: "231084@@宁安市", label: "宁安市" },
          { value: "231085@@穆棱市", label: "穆棱市" },
          { value: "231086@@其它区", label: "其它区" },
        ],
      },
      {
        value: "231100@@黑河市",
        label: "黑河市",
        children: [
          { value: "231102@@爱辉区", label: "爱辉区" },
          { value: "231121@@嫩江县", label: "嫩江县" },
          { value: "231123@@逊克县", label: "逊克县" },
          { value: "231124@@孙吴县", label: "孙吴县" },
          { value: "231181@@北安市", label: "北安市" },
          { value: "231182@@五大连池市", label: "五大连池市" },
          { value: "231183@@其它区", label: "其它区" },
        ],
      },
      {
        value: "231200@@绥化市",
        label: "绥化市",
        children: [
          { value: "231202@@北林区", label: "北林区" },
          { value: "231221@@望奎县", label: "望奎县" },
          { value: "231222@@兰西县", label: "兰西县" },
          { value: "231223@@青冈县", label: "青冈县" },
          { value: "231224@@庆安县", label: "庆安县" },
          { value: "231225@@明水县", label: "明水县" },
          { value: "231226@@绥棱县", label: "绥棱县" },
          { value: "231281@@安达市", label: "安达市" },
          { value: "231282@@肇东市", label: "肇东市" },
          { value: "231283@@海伦市", label: "海伦市" },
          { value: "231284@@其它区", label: "其它区" },
        ],
      },
      {
        value: "232700@@大兴安岭地区",
        label: "大兴安岭地区",
        children: [
          { value: "232721@@呼玛县", label: "呼玛县" },
          { value: "232722@@塔河县", label: "塔河县" },
          { value: "232723@@漠河县", label: "漠河县" },
          { value: "232724@@加格达奇区", label: "加格达奇区" },
          { value: "232725@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "310000@@上海",
    label: "上海",
    children: [
      {
        value: "310100@@上海市",
        label: "上海市",
        children: [
          { value: "310101@@黄浦区", label: "黄浦区" },
          { value: "310103@@卢湾区", label: "卢湾区" },
          { value: "310104@@徐汇区", label: "徐汇区" },
          { value: "310105@@长宁区", label: "长宁区" },
          { value: "310106@@静安区", label: "静安区" },
          { value: "310107@@普陀区", label: "普陀区" },
          { value: "310108@@闸北区", label: "闸北区" },
          { value: "310109@@虹口区", label: "虹口区" },
          { value: "310110@@杨浦区", label: "杨浦区" },
          { value: "310112@@闵行区", label: "闵行区" },
          { value: "310113@@宝山区", label: "宝山区" },
          { value: "310114@@嘉定区", label: "嘉定区" },
          { value: "310115@@浦东新区", label: "浦东新区" },
          { value: "310116@@金山区", label: "金山区" },
          { value: "310117@@松江区", label: "松江区" },
          { value: "310118@@青浦区", label: "青浦区" },
          { value: "310119@@南汇区", label: "南汇区" },
          { value: "310120@@奉贤区", label: "奉贤区" },
          { value: "310152@@川沙区", label: "川沙区" },
          { value: "310230@@崇明县", label: "崇明县" },
          { value: "310231@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "320000@@江苏",
    label: "江苏",
    children: [
      {
        value: "320100@@南京市",
        label: "南京市",
        children: [
          { value: "320102@@玄武区", label: "玄武区" },
          { value: "320103@@白下区", label: "白下区" },
          { value: "320104@@秦淮区", label: "秦淮区" },
          { value: "320105@@建邺区", label: "建邺区" },
          { value: "320106@@鼓楼区", label: "鼓楼区" },
          { value: "320107@@下关区", label: "下关区" },
          { value: "320111@@浦口区", label: "浦口区" },
          { value: "320113@@栖霞区", label: "栖霞区" },
          { value: "320114@@雨花台区", label: "雨花台区" },
          { value: "320115@@江宁区", label: "江宁区" },
          { value: "320116@@六合区", label: "六合区" },
          { value: "320124@@溧水县", label: "溧水县" },
          { value: "320125@@高淳县", label: "高淳县" },
          { value: "320126@@其它区", label: "其它区" },
        ],
      },
      {
        value: "320200@@无锡市",
        label: "无锡市",
        children: [
          { value: "320202@@崇安区", label: "崇安区" },
          { value: "320203@@南长区", label: "南长区" },
          { value: "320204@@北塘区", label: "北塘区" },
          { value: "320205@@锡山区", label: "锡山区" },
          { value: "320206@@惠山区", label: "惠山区" },
          { value: "320211@@滨湖区", label: "滨湖区" },
          { value: "320281@@江阴市", label: "江阴市" },
          { value: "320282@@宜兴市", label: "宜兴市" },
          { value: "320296@@新区", label: "新区" },
          { value: "320297@@其它区", label: "其它区" },
        ],
      },
      {
        value: "320300@@徐州市",
        label: "徐州市",
        children: [
          { value: "320302@@鼓楼区", label: "鼓楼区" },
          { value: "320303@@云龙区", label: "云龙区" },
          { value: "320304@@九里区", label: "九里区" },
          { value: "320305@@贾汪区", label: "贾汪区" },
          { value: "320311@@泉山区", label: "泉山区" },
          { value: "320321@@丰县", label: "丰县" },
          { value: "320322@@沛县", label: "沛县" },
          { value: "320323@@铜山县", label: "铜山县" },
          { value: "320324@@睢宁县", label: "睢宁县" },
          { value: "320381@@新沂市", label: "新沂市" },
          { value: "320382@@邳州市", label: "邳州市" },
          { value: "320383@@其它区", label: "其它区" },
        ],
      },
      {
        value: "320400@@常州市",
        label: "常州市",
        children: [
          { value: "320402@@天宁区", label: "天宁区" },
          { value: "320404@@钟楼区", label: "钟楼区" },
          { value: "320405@@戚墅堰区", label: "戚墅堰区" },
          { value: "320411@@新北区", label: "新北区" },
          { value: "320412@@武进区", label: "武进区" },
          { value: "320481@@溧阳市", label: "溧阳市" },
          { value: "320482@@金坛市", label: "金坛市" },
          { value: "320483@@其它区", label: "其它区" },
        ],
      },
      {
        value: "320500@@苏州市",
        label: "苏州市",
        children: [
          { value: "320502@@沧浪区", label: "沧浪区" },
          { value: "320503@@平江区", label: "平江区" },
          { value: "320504@@金阊区", label: "金阊区" },
          { value: "320505@@虎丘区", label: "虎丘区" },
          { value: "320506@@吴中区", label: "吴中区" },
          { value: "320507@@相城区", label: "相城区" },
          { value: "320581@@常熟市", label: "常熟市" },
          { value: "320582@@张家港市", label: "张家港市" },
          { value: "320583@@昆山市", label: "昆山市" },
          { value: "320584@@吴江市", label: "吴江市" },
          { value: "320585@@太仓市", label: "太仓市" },
          { value: "320594@@新区", label: "新区" },
          { value: "320595@@园区", label: "园区" },
          { value: "320596@@其它区", label: "其它区" },
        ],
      },
      {
        value: "320600@@南通市",
        label: "南通市",
        children: [
          { value: "320602@@崇川区", label: "崇川区" },
          { value: "320611@@港闸区", label: "港闸区" },
          { value: "320612@@通州区", label: "通州区" },
          { value: "320621@@海安县", label: "海安县" },
          { value: "320623@@如东县", label: "如东县" },
          { value: "320681@@启东市", label: "启东市" },
          { value: "320682@@如皋市", label: "如皋市" },
          { value: "320683@@通州市", label: "通州市" },
          { value: "320684@@海门市", label: "海门市" },
          { value: "320693@@开发区", label: "开发区" },
          { value: "320694@@其它区", label: "其它区" },
        ],
      },
      {
        value: "320700@@连云港市",
        label: "连云港市",
        children: [
          { value: "320703@@连云区", label: "连云区" },
          { value: "320705@@新浦区", label: "新浦区" },
          { value: "320706@@海州区", label: "海州区" },
          { value: "320721@@赣榆县", label: "赣榆县" },
          { value: "320722@@东海县", label: "东海县" },
          { value: "320723@@灌云县", label: "灌云县" },
          { value: "320724@@灌南县", label: "灌南县" },
          { value: "320725@@其它区", label: "其它区" },
        ],
      },
      {
        value: "320800@@淮安市",
        label: "淮安市",
        children: [
          { value: "320802@@清江浦", label: "清江浦" },
          { value: "320803@@淮安", label: "淮安" },
          { value: "320804@@淮阴区", label: "淮阴区" },
          { value: "320811@@清浦区", label: "清浦区" },
          { value: "320826@@涟水县", label: "涟水县" },
          { value: "320829@@洪泽县", label: "洪泽县" },
          { value: "320830@@盱眙县", label: "盱眙县" },
          { value: "320831@@金湖县", label: "金湖县" },
          { value: "320832@@其它区", label: "其它区" },
        ],
      },
      {
        value: "320900@@盐城市",
        label: "盐城市",
        children: [
          { value: "320902@@亭湖区", label: "亭湖区" },
          { value: "320903@@盐都区", label: "盐都区" },
          { value: "320921@@响水县", label: "响水县" },
          { value: "320922@@滨海县", label: "滨海县" },
          { value: "320923@@阜宁县", label: "阜宁县" },
          { value: "320924@@射阳县", label: "射阳县" },
          { value: "320925@@建湖县", label: "建湖县" },
          { value: "320981@@东台市", label: "东台市" },
          { value: "320982@@大丰市", label: "大丰市" },
          { value: "320983@@其它区", label: "其它区" },
        ],
      },
      {
        value: "321000@@扬州市",
        label: "扬州市",
        children: [
          { value: "321002@@广陵区", label: "广陵区" },
          { value: "321003@@邗江区", label: "邗江区" },
          { value: "321011@@维扬区", label: "维扬区" },
          { value: "321023@@宝应县", label: "宝应县" },
          { value: "321081@@仪征市", label: "仪征市" },
          { value: "321084@@高邮市", label: "高邮市" },
          { value: "321088@@江都市", label: "江都市" },
          { value: "321092@@经济开发区", label: "经济开发区" },
          { value: "321093@@其它区", label: "其它区" },
        ],
      },
      {
        value: "321100@@镇江市",
        label: "镇江市",
        children: [
          { value: "321102@@京口区", label: "京口区" },
          { value: "321111@@润州区", label: "润州区" },
          { value: "321112@@丹徒区", label: "丹徒区" },
          { value: "321181@@丹阳市", label: "丹阳市" },
          { value: "321182@@扬中市", label: "扬中市" },
          { value: "321183@@句容市", label: "句容市" },
          { value: "321184@@其它区", label: "其它区" },
        ],
      },
      {
        value: "321200@@泰州市",
        label: "泰州市",
        children: [
          { value: "321202@@海陵区", label: "海陵区" },
          { value: "321203@@高港区", label: "高港区" },
          { value: "321281@@兴化市", label: "兴化市" },
          { value: "321282@@靖江市", label: "靖江市" },
          { value: "321283@@泰兴市", label: "泰兴市" },
          { value: "321284@@姜堰市", label: "姜堰市" },
          { value: "321285@@其它区", label: "其它区" },
        ],
      },
      {
        value: "321300@@宿迁市",
        label: "宿迁市",
        children: [
          { value: "321302@@宿城区", label: "宿城区" },
          { value: "321311@@宿豫区", label: "宿豫区" },
          { value: "321322@@沭阳县", label: "沭阳县" },
          { value: "321323@@泗阳县", label: "泗阳县" },
          { value: "321324@@泗洪县", label: "泗洪县" },
          { value: "321325@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "330000@@浙江",
    label: "浙江",
    children: [
      {
        value: "330100@@杭州市",
        label: "杭州市",
        children: [
          { value: "330102@@上城区", label: "上城区" },
          { value: "330103@@下城区", label: "下城区" },
          { value: "330104@@江干区", label: "江干区" },
          { value: "330105@@拱墅区", label: "拱墅区" },
          { value: "330106@@西湖区", label: "西湖区" },
          { value: "330108@@滨江区", label: "滨江区" },
          { value: "330109@@萧山区", label: "萧山区" },
          { value: "330110@@余杭区", label: "余杭区" },
          { value: "330122@@桐庐县", label: "桐庐县" },
          { value: "330127@@淳安县", label: "淳安县" },
          { value: "330182@@建德市", label: "建德市" },
          { value: "330183@@富阳市", label: "富阳市" },
          { value: "330185@@临安市", label: "临安市" },
          { value: "330186@@其它区", label: "其它区" },
        ],
      },
      {
        value: "330200@@宁波市",
        label: "宁波市",
        children: [
          { value: "330203@@海曙区", label: "海曙区" },
          { value: "330204@@江东区", label: "江东区" },
          { value: "330205@@江北区", label: "江北区" },
          { value: "330206@@北仑区", label: "北仑区" },
          { value: "330211@@镇海区", label: "镇海区" },
          { value: "330212@@鄞州区", label: "鄞州区" },
          { value: "330225@@象山县", label: "象山县" },
          { value: "330226@@宁海县", label: "宁海县" },
          { value: "330281@@余姚市", label: "余姚市" },
          { value: "330282@@慈溪市", label: "慈溪市" },
          { value: "330283@@奉化市", label: "奉化市" },
          { value: "330284@@其它区", label: "其它区" },
        ],
      },
      {
        value: "330300@@温州市",
        label: "温州市",
        children: [
          { value: "330302@@鹿城区", label: "鹿城区" },
          { value: "330303@@龙湾区", label: "龙湾区" },
          { value: "330304@@瓯海区", label: "瓯海区" },
          { value: "330322@@洞头县", label: "洞头县" },
          { value: "330324@@永嘉县", label: "永嘉县" },
          { value: "330326@@平阳县", label: "平阳县" },
          { value: "330327@@苍南县", label: "苍南县" },
          { value: "330328@@文成县", label: "文成县" },
          { value: "330329@@泰顺县", label: "泰顺县" },
          { value: "330381@@瑞安市", label: "瑞安市" },
          { value: "330382@@乐清市", label: "乐清市" },
          { value: "330383@@其它区", label: "其它区" },
        ],
      },
      {
        value: "330400@@嘉兴市",
        label: "嘉兴市",
        children: [
          { value: "330402@@南湖区", label: "南湖区" },
          { value: "330411@@秀洲区", label: "秀洲区" },
          { value: "330421@@嘉善县", label: "嘉善县" },
          { value: "330424@@海盐县", label: "海盐县" },
          { value: "330481@@海宁市", label: "海宁市" },
          { value: "330482@@平湖市", label: "平湖市" },
          { value: "330483@@桐乡市", label: "桐乡市" },
          { value: "330484@@其它区", label: "其它区" },
        ],
      },
      {
        value: "330500@@湖州市",
        label: "湖州市",
        children: [
          { value: "330502@@吴兴区", label: "吴兴区" },
          { value: "330503@@南浔区", label: "南浔区" },
          { value: "330521@@德清县", label: "德清县" },
          { value: "330522@@长兴县", label: "长兴县" },
          { value: "330523@@安吉县", label: "安吉县" },
          { value: "330524@@其它区", label: "其它区" },
        ],
      },
      {
        value: "330600@@绍兴市",
        label: "绍兴市",
        children: [
          { value: "330602@@越城区", label: "越城区" },
          { value: "330621@@柯桥", label: "柯桥" },
          { value: "330624@@新昌县", label: "新昌县" },
          { value: "330681@@诸暨市", label: "诸暨市" },
          { value: "330682@@上虞市", label: "上虞市" },
          { value: "330683@@嵊州市", label: "嵊州市" },
          { value: "330684@@其它区", label: "其它区" },
        ],
      },
      {
        value: "330700@@金华市",
        label: "金华市",
        children: [
          { value: "330702@@婺城区", label: "婺城区" },
          { value: "330703@@金东区", label: "金东区" },
          { value: "330723@@武义县", label: "武义县" },
          { value: "330726@@浦江县", label: "浦江县" },
          { value: "330727@@磐安县", label: "磐安县" },
          { value: "330781@@兰溪市", label: "兰溪市" },
          { value: "330782@@义乌市", label: "义乌市" },
          { value: "330783@@东阳市", label: "东阳市" },
          { value: "330784@@永康市", label: "永康市" },
          { value: "330785@@其它区", label: "其它区" },
        ],
      },
      {
        value: "330800@@衢州市",
        label: "衢州市",
        children: [
          { value: "330802@@柯城区", label: "柯城区" },
          { value: "330803@@衢江区", label: "衢江区" },
          { value: "330822@@常山县", label: "常山县" },
          { value: "330824@@开化县", label: "开化县" },
          { value: "330825@@龙游县", label: "龙游县" },
          { value: "330881@@江山市", label: "江山市" },
          { value: "330882@@其它区", label: "其它区" },
        ],
      },
      {
        value: "330900@@舟山市",
        label: "舟山市",
        children: [
          { value: "330902@@定海区", label: "定海区" },
          { value: "330903@@普陀区", label: "普陀区" },
          { value: "330921@@岱山县", label: "岱山县" },
          { value: "330922@@嵊泗县", label: "嵊泗县" },
          { value: "330923@@其它区", label: "其它区" },
        ],
      },
      {
        value: "331000@@台州市",
        label: "台州市",
        children: [
          { value: "331002@@椒江区", label: "椒江区" },
          { value: "331003@@黄岩区", label: "黄岩区" },
          { value: "331004@@路桥区", label: "路桥区" },
          { value: "331021@@玉环县", label: "玉环县" },
          { value: "331022@@三门县", label: "三门县" },
          { value: "331023@@天台县", label: "天台县" },
          { value: "331024@@仙居县", label: "仙居县" },
          { value: "331081@@温岭市", label: "温岭市" },
          { value: "331082@@临海市", label: "临海市" },
          { value: "331083@@其它区", label: "其它区" },
        ],
      },
      {
        value: "331100@@丽水市",
        label: "丽水市",
        children: [
          { value: "331102@@莲都区", label: "莲都区" },
          { value: "331121@@青田县", label: "青田县" },
          { value: "331122@@缙云县", label: "缙云县" },
          { value: "331123@@遂昌县", label: "遂昌县" },
          { value: "331124@@松阳县", label: "松阳县" },
          { value: "331125@@云和县", label: "云和县" },
          { value: "331126@@庆元县", label: "庆元县" },
          { value: "331127@@景宁畲族自治县", label: "景宁畲族自治县" },
          { value: "331181@@龙泉市", label: "龙泉市" },
          { value: "331182@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "340000@@安徽",
    label: "安徽",
    children: [
      {
        value: "340100@@合肥市",
        label: "合肥市",
        children: [
          { value: "340102@@瑶海区", label: "瑶海区" },
          { value: "340103@@庐阳区", label: "庐阳区" },
          { value: "340104@@蜀山区", label: "蜀山区" },
          { value: "340111@@包河区", label: "包河区" },
          { value: "340121@@长丰县", label: "长丰县" },
          { value: "340122@@肥东县", label: "肥东县" },
          { value: "340123@@肥西县", label: "肥西县" },
          { value: "340151@@高新区", label: "高新区" },
          { value: "340191@@中区", label: "中区" },
          { value: "340192@@其它区", label: "其它区" },
        ],
      },
      {
        value: "340200@@芜湖市",
        label: "芜湖市",
        children: [
          { value: "340202@@镜湖区", label: "镜湖区" },
          { value: "340203@@弋江区", label: "弋江区" },
          { value: "340207@@鸠江区", label: "鸠江区" },
          { value: "340208@@三山区", label: "三山区" },
          { value: "340221@@芜湖县", label: "芜湖县" },
          { value: "340222@@繁昌县", label: "繁昌县" },
          { value: "340223@@南陵县", label: "南陵县" },
          { value: "340224@@其它区", label: "其它区" },
        ],
      },
      {
        value: "340300@@蚌埠市",
        label: "蚌埠市",
        children: [
          { value: "340302@@龙子湖区", label: "龙子湖区" },
          { value: "340303@@蚌山区", label: "蚌山区" },
          { value: "340304@@禹会区", label: "禹会区" },
          { value: "340311@@淮上区", label: "淮上区" },
          { value: "340321@@怀远县", label: "怀远县" },
          { value: "340322@@五河县", label: "五河县" },
          { value: "340323@@固镇县", label: "固镇县" },
          { value: "340324@@其它区", label: "其它区" },
        ],
      },
      {
        value: "340400@@淮南市",
        label: "淮南市",
        children: [
          { value: "340402@@大通区", label: "大通区" },
          { value: "340403@@田家庵区", label: "田家庵区" },
          { value: "340404@@谢家集区", label: "谢家集区" },
          { value: "340405@@八公山区", label: "八公山区" },
          { value: "340406@@潘集区", label: "潘集区" },
          { value: "340421@@凤台县", label: "凤台县" },
          { value: "340422@@其它区", label: "其它区" },
        ],
      },
      {
        value: "340500@@马鞍山市",
        label: "马鞍山市",
        children: [
          { value: "340502@@金家庄区", label: "金家庄区" },
          { value: "340503@@花山区", label: "花山区" },
          { value: "340504@@雨山区", label: "雨山区" },
          { value: "340521@@当涂县", label: "当涂县" },
          { value: "340522@@其它区", label: "其它区" },
        ],
      },
      {
        value: "340600@@淮北市",
        label: "淮北市",
        children: [
          { value: "340602@@杜集区", label: "杜集区" },
          { value: "340603@@相山区", label: "相山区" },
          { value: "340604@@烈山区", label: "烈山区" },
          { value: "340621@@濉溪县", label: "濉溪县" },
          { value: "340622@@其它区", label: "其它区" },
        ],
      },
      {
        value: "340700@@铜陵市",
        label: "铜陵市",
        children: [
          { value: "340705@@铜官山区", label: "铜官山区" },
          { value: "340703@@狮子山区", label: "狮子山区" },
          { value: "340711@@郊区", label: "郊区" },
          { value: "340721@@义安", label: "义安" },
          { value: "340722@@其它区", label: "其它区" },
        ],
      },
      {
        value: "340800@@安庆市",
        label: "安庆市",
        children: [
          { value: "340802@@迎江区", label: "迎江区" },
          { value: "340803@@大观区", label: "大观区" },
          { value: "340811@@宜秀区", label: "宜秀区" },
          { value: "340822@@怀宁县", label: "怀宁县" },
          { value: "340823@@枞阳县", label: "枞阳县" },
          { value: "340824@@潜山县", label: "潜山县" },
          { value: "340825@@太湖县", label: "太湖县" },
          { value: "340826@@宿松县", label: "宿松县" },
          { value: "340827@@望江县", label: "望江县" },
          { value: "340828@@岳西县", label: "岳西县" },
          { value: "340881@@桐城市", label: "桐城市" },
          { value: "340882@@其它区", label: "其它区" },
        ],
      },
      {
        value: "341000@@黄山市",
        label: "黄山市",
        children: [
          { value: "341002@@屯溪区", label: "屯溪区" },
          { value: "341003@@黄山区", label: "黄山区" },
          { value: "341004@@徽州区", label: "徽州区" },
          { value: "341021@@歙县", label: "歙县" },
          { value: "341022@@休宁县", label: "休宁县" },
          { value: "341023@@黟县", label: "黟县" },
          { value: "341024@@祁门县", label: "祁门县" },
          { value: "341025@@其它区", label: "其它区" },
        ],
      },
      {
        value: "341100@@滁州市",
        label: "滁州市",
        children: [
          { value: "341102@@琅琊区", label: "琅琊区" },
          { value: "341103@@南谯区", label: "南谯区" },
          { value: "341122@@来安县", label: "来安县" },
          { value: "341124@@全椒县", label: "全椒县" },
          { value: "341125@@定远县", label: "定远县" },
          { value: "341126@@凤阳县", label: "凤阳县" },
          { value: "341181@@天长市", label: "天长市" },
          { value: "341182@@明光市", label: "明光市" },
          { value: "341183@@其它区", label: "其它区" },
        ],
      },
      {
        value: "341200@@阜阳市",
        label: "阜阳市",
        children: [
          { value: "341202@@颍州区", label: "颍州区" },
          { value: "341203@@颍东区", label: "颍东区" },
          { value: "341204@@颍泉区", label: "颍泉区" },
          { value: "341221@@临泉县", label: "临泉县" },
          { value: "341222@@太和县", label: "太和县" },
          { value: "341225@@阜南县", label: "阜南县" },
          { value: "341226@@颍上县", label: "颍上县" },
          { value: "341282@@界首市", label: "界首市" },
          { value: "341283@@其它区", label: "其它区" },
        ],
      },
      {
        value: "341300@@宿州市",
        label: "宿州市",
        children: [
          { value: "341302@@埇桥区", label: "埇桥区" },
          { value: "341321@@砀山县", label: "砀山县" },
          { value: "341322@@萧县", label: "萧县" },
          { value: "341323@@灵璧县", label: "灵璧县" },
          { value: "341324@@泗县", label: "泗县" },
          { value: "341325@@其它区", label: "其它区" },
        ],
      },
      {
        value: "341400@@巢湖市",
        label: "巢湖市",
        children: [
          { value: "341402@@居巢区", label: "居巢区" },
          { value: "341421@@庐江县", label: "庐江县" },
          { value: "341422@@无为县", label: "无为县" },
          { value: "341423@@含山县", label: "含山县" },
          { value: "341424@@和县", label: "和县" },
          { value: "341425@@其它区", label: "其它区" },
        ],
      },
      {
        value: "341500@@六安市",
        label: "六安市",
        children: [
          { value: "341502@@金安区", label: "金安区" },
          { value: "341503@@裕安区", label: "裕安区" },
          { value: "341521@@寿县", label: "寿县" },
          { value: "341522@@霍邱县", label: "霍邱县" },
          { value: "341523@@舒城县", label: "舒城县" },
          { value: "341524@@金寨县", label: "金寨县" },
          { value: "341525@@霍山县", label: "霍山县" },
          { value: "341526@@其它区", label: "其它区" },
        ],
      },
      {
        value: "341600@@亳州市",
        label: "亳州市",
        children: [
          { value: "341602@@谯城区", label: "谯城区" },
          { value: "341621@@涡阳县", label: "涡阳县" },
          { value: "341622@@蒙城县", label: "蒙城县" },
          { value: "341623@@利辛县", label: "利辛县" },
          { value: "341624@@其它区", label: "其它区" },
        ],
      },
      {
        value: "341700@@池州市",
        label: "池州市",
        children: [
          { value: "341702@@贵池区", label: "贵池区" },
          { value: "341721@@东至县", label: "东至县" },
          { value: "341722@@石台县", label: "石台县" },
          { value: "341723@@青阳县", label: "青阳县" },
          { value: "341724@@其它区", label: "其它区" },
        ],
      },
      {
        value: "341800@@宣城市",
        label: "宣城市",
        children: [
          { value: "341802@@宣州区", label: "宣州区" },
          { value: "341821@@郎溪县", label: "郎溪县" },
          { value: "341822@@广德县", label: "广德县" },
          { value: "341823@@泾县", label: "泾县" },
          { value: "341824@@绩溪县", label: "绩溪县" },
          { value: "341825@@旌德县", label: "旌德县" },
          { value: "341881@@宁国市", label: "宁国市" },
          { value: "341882@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "350000@@福建",
    label: "福建",
    children: [
      {
        value: "350100@@福州市",
        label: "福州市",
        children: [
          { value: "350102@@鼓楼区", label: "鼓楼区" },
          { value: "350103@@台江区", label: "台江区" },
          { value: "350104@@仓山区", label: "仓山区" },
          { value: "350105@@马尾区", label: "马尾区" },
          { value: "350111@@晋安区", label: "晋安区" },
          { value: "350121@@闽侯县", label: "闽侯县" },
          { value: "350122@@连江县", label: "连江县" },
          { value: "350123@@罗源县", label: "罗源县" },
          { value: "350124@@闽清县", label: "闽清县" },
          { value: "350125@@永泰县", label: "永泰县" },
          { value: "350128@@平潭县", label: "平潭县" },
          { value: "350181@@福清市", label: "福清市" },
          { value: "350182@@长乐市", label: "长乐市" },
          { value: "350183@@其它区", label: "其它区" },
        ],
      },
      {
        value: "350200@@厦门市",
        label: "厦门市",
        children: [
          { value: "350203@@思明区", label: "思明区" },
          { value: "350205@@海沧区", label: "海沧区" },
          { value: "350206@@湖里区", label: "湖里区" },
          { value: "350211@@集美区", label: "集美区" },
          { value: "350212@@同安区", label: "同安区" },
          { value: "350213@@翔安区", label: "翔安区" },
          { value: "350214@@其它区", label: "其它区" },
        ],
      },
      {
        value: "350300@@莆田市",
        label: "莆田市",
        children: [
          { value: "350302@@城厢区", label: "城厢区" },
          { value: "350303@@涵江区", label: "涵江区" },
          { value: "350304@@荔城区", label: "荔城区" },
          { value: "350305@@秀屿区", label: "秀屿区" },
          { value: "350322@@仙游县", label: "仙游县" },
          { value: "350323@@其它区", label: "其它区" },
        ],
      },
      {
        value: "350400@@三明市",
        label: "三明市",
        children: [
          { value: "350402@@梅列区", label: "梅列区" },
          { value: "350403@@三元区", label: "三元区" },
          { value: "350421@@明溪县", label: "明溪县" },
          { value: "350423@@清流县", label: "清流县" },
          { value: "350424@@宁化县", label: "宁化县" },
          { value: "350425@@大田县", label: "大田县" },
          { value: "350426@@尤溪县", label: "尤溪县" },
          { value: "350427@@沙县", label: "沙县" },
          { value: "350428@@将乐县", label: "将乐县" },
          { value: "350429@@泰宁县", label: "泰宁县" },
          { value: "350430@@建宁县", label: "建宁县" },
          { value: "350481@@永安市", label: "永安市" },
          { value: "350482@@其它区", label: "其它区" },
        ],
      },
      {
        value: "350500@@泉州市",
        label: "泉州市",
        children: [
          { value: "350502@@鲤城区", label: "鲤城区" },
          { value: "350503@@丰泽区", label: "丰泽区" },
          { value: "350504@@洛江区", label: "洛江区" },
          { value: "350505@@泉港区", label: "泉港区" },
          { value: "350521@@惠安县", label: "惠安县" },
          { value: "350524@@安溪县", label: "安溪县" },
          { value: "350525@@永春县", label: "永春县" },
          { value: "350526@@德化县", label: "德化县" },
          { value: "350527@@金门县", label: "金门县" },
          { value: "350581@@石狮市", label: "石狮市" },
          { value: "350582@@晋江市", label: "晋江市" },
          { value: "350583@@南安市", label: "南安市" },
          { value: "350584@@其它区", label: "其它区" },
        ],
      },
      {
        value: "350600@@漳州市",
        label: "漳州市",
        children: [
          { value: "350602@@芗城区", label: "芗城区" },
          { value: "350603@@龙文区", label: "龙文区" },
          { value: "350622@@云霄县", label: "云霄县" },
          { value: "350623@@漳浦县", label: "漳浦县" },
          { value: "350624@@诏安县", label: "诏安县" },
          { value: "350625@@长泰县", label: "长泰县" },
          { value: "350626@@东山县", label: "东山县" },
          { value: "350627@@南靖县", label: "南靖县" },
          { value: "350628@@平和县", label: "平和县" },
          { value: "350629@@华安县", label: "华安县" },
          { value: "350681@@龙海市", label: "龙海市" },
          { value: "350682@@其它区", label: "其它区" },
        ],
      },
      {
        value: "350700@@南平市",
        label: "南平市",
        children: [
          { value: "350702@@延平区", label: "延平区" },
          { value: "350721@@顺昌县", label: "顺昌县" },
          { value: "350722@@浦城县", label: "浦城县" },
          { value: "350723@@光泽县", label: "光泽县" },
          { value: "350724@@松溪县", label: "松溪县" },
          { value: "350725@@政和县", label: "政和县" },
          { value: "350781@@邵武市", label: "邵武市" },
          { value: "350782@@武夷山市", label: "武夷山市" },
          { value: "350783@@建瓯市", label: "建瓯市" },
          { value: "350784@@建阳市", label: "建阳市" },
          { value: "350785@@其它区", label: "其它区" },
        ],
      },
      {
        value: "350800@@龙岩市",
        label: "龙岩市",
        children: [
          { value: "350802@@新罗区", label: "新罗区" },
          { value: "350821@@长汀县", label: "长汀县" },
          { value: "350822@@永定县", label: "永定县" },
          { value: "350823@@上杭县", label: "上杭县" },
          { value: "350824@@武平县", label: "武平县" },
          { value: "350825@@连城县", label: "连城县" },
          { value: "350881@@漳平市", label: "漳平市" },
          { value: "350882@@其它区", label: "其它区" },
        ],
      },
      {
        value: "350900@@宁德市",
        label: "宁德市",
        children: [
          { value: "350902@@蕉城区", label: "蕉城区" },
          { value: "350921@@霞浦县", label: "霞浦县" },
          { value: "350922@@古田县", label: "古田县" },
          { value: "350923@@屏南县", label: "屏南县" },
          { value: "350924@@寿宁县", label: "寿宁县" },
          { value: "350925@@周宁县", label: "周宁县" },
          { value: "350926@@柘荣县", label: "柘荣县" },
          { value: "350981@@福安市", label: "福安市" },
          { value: "350982@@福鼎市", label: "福鼎市" },
          { value: "350983@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "360000@@江西",
    label: "江西",
    children: [
      {
        value: "360100@@南昌市",
        label: "南昌市",
        children: [
          { value: "360102@@东湖区", label: "东湖区" },
          { value: "360103@@西湖区", label: "西湖区" },
          { value: "360104@@青云谱区", label: "青云谱区" },
          { value: "360105@@湾里区", label: "湾里区" },
          { value: "360111@@青山湖区", label: "青山湖区" },
          { value: "360121@@南昌县", label: "南昌县" },
          { value: "360122@@新建县", label: "新建县" },
          { value: "360123@@安义县", label: "安义县" },
          { value: "360124@@进贤县", label: "进贤县" },
          { value: "360125@@红谷滩新区", label: "红谷滩新区" },
          { value: "360126@@经济技术开发区", label: "经济技术开发区" },
          { value: "360127@@昌北区", label: "昌北区" },
          { value: "360128@@其它区", label: "其它区" },
        ],
      },
      {
        value: "360200@@景德镇市",
        label: "景德镇市",
        children: [
          { value: "360202@@昌江区", label: "昌江区" },
          { value: "360203@@珠山区", label: "珠山区" },
          { value: "360222@@浮梁县", label: "浮梁县" },
          { value: "360281@@乐平市", label: "乐平市" },
          { value: "360282@@其它区", label: "其它区" },
        ],
      },
      {
        value: "360300@@萍乡市",
        label: "萍乡市",
        children: [
          { value: "360302@@安源区", label: "安源区" },
          { value: "360313@@湘东区", label: "湘东区" },
          { value: "360321@@莲花县", label: "莲花县" },
          { value: "360322@@上栗县", label: "上栗县" },
          { value: "360323@@芦溪县", label: "芦溪县" },
          { value: "360324@@其它区", label: "其它区" },
        ],
      },
      {
        value: "360400@@九江市",
        label: "九江市",
        children: [
          { value: "360402@@濂溪", label: "濂溪" },
          { value: "360403@@浔阳区", label: "浔阳区" },
          { value: "360421@@九江县", label: "九江县" },
          { value: "360423@@武宁县", label: "武宁县" },
          { value: "360424@@修水县", label: "修水县" },
          { value: "360425@@永修县", label: "永修县" },
          { value: "360426@@德安县", label: "德安县" },
          { value: "360427@@庐山", label: "庐山" },
          { value: "360428@@都昌县", label: "都昌县" },
          { value: "360429@@湖口县", label: "湖口县" },
          { value: "360430@@彭泽县", label: "彭泽县" },
          { value: "360481@@瑞昌市", label: "瑞昌市" },
          { value: "360482@@其它区", label: "其它区" },
        ],
      },
      {
        value: "360500@@新余市",
        label: "新余市",
        children: [
          { value: "360502@@渝水区", label: "渝水区" },
          { value: "360521@@分宜县", label: "分宜县" },
          { value: "360522@@其它区", label: "其它区" },
        ],
      },
      {
        value: "360600@@鹰潭市",
        label: "鹰潭市",
        children: [
          { value: "360602@@月湖区", label: "月湖区" },
          { value: "360622@@余江县", label: "余江县" },
          { value: "360681@@贵溪市", label: "贵溪市" },
          { value: "360682@@其它区", label: "其它区" },
        ],
      },
      {
        value: "360700@@赣州市",
        label: "赣州市",
        children: [
          { value: "360702@@章贡区", label: "章贡区" },
          { value: "360721@@赣县", label: "赣县" },
          { value: "360722@@信丰县", label: "信丰县" },
          { value: "360723@@大余县", label: "大余县" },
          { value: "360724@@上犹县", label: "上犹县" },
          { value: "360725@@崇义县", label: "崇义县" },
          { value: "360726@@安远县", label: "安远县" },
          { value: "360727@@龙南县", label: "龙南县" },
          { value: "360728@@定南县", label: "定南县" },
          { value: "360729@@全南县", label: "全南县" },
          { value: "360730@@宁都县", label: "宁都县" },
          { value: "360731@@于都县", label: "于都县" },
          { value: "360732@@兴国县", label: "兴国县" },
          { value: "360733@@会昌县", label: "会昌县" },
          { value: "360734@@寻乌县", label: "寻乌县" },
          { value: "360735@@石城县", label: "石城县" },
          { value: "360751@@黄金区", label: "黄金区" },
          { value: "360781@@瑞金市", label: "瑞金市" },
          { value: "360782@@南康市", label: "南康市" },
          { value: "360783@@其它区", label: "其它区" },
        ],
      },
      {
        value: "360800@@吉安市",
        label: "吉安市",
        children: [
          { value: "360802@@吉州区", label: "吉州区" },
          { value: "360803@@青原区", label: "青原区" },
          { value: "360821@@吉安县", label: "吉安县" },
          { value: "360822@@吉水县", label: "吉水县" },
          { value: "360823@@峡江县", label: "峡江县" },
          { value: "360824@@新干县", label: "新干县" },
          { value: "360825@@永丰县", label: "永丰县" },
          { value: "360826@@泰和县", label: "泰和县" },
          { value: "360827@@遂川县", label: "遂川县" },
          { value: "360828@@万安县", label: "万安县" },
          { value: "360829@@安福县", label: "安福县" },
          { value: "360830@@永新县", label: "永新县" },
          { value: "360881@@井冈山市", label: "井冈山市" },
          { value: "360882@@其它区", label: "其它区" },
        ],
      },
      {
        value: "360900@@宜春市",
        label: "宜春市",
        children: [
          { value: "360902@@袁州区", label: "袁州区" },
          { value: "360921@@奉新县", label: "奉新县" },
          { value: "360922@@万载县", label: "万载县" },
          { value: "360923@@上高县", label: "上高县" },
          { value: "360924@@宜丰县", label: "宜丰县" },
          { value: "360925@@靖安县", label: "靖安县" },
          { value: "360926@@铜鼓县", label: "铜鼓县" },
          { value: "360981@@丰城市", label: "丰城市" },
          { value: "360982@@樟树市", label: "樟树市" },
          { value: "360983@@高安市", label: "高安市" },
          { value: "360984@@其它区", label: "其它区" },
        ],
      },
      {
        value: "361000@@抚州市",
        label: "抚州市",
        children: [
          { value: "361002@@临川区", label: "临川区" },
          { value: "361021@@南城县", label: "南城县" },
          { value: "361022@@黎川县", label: "黎川县" },
          { value: "361023@@南丰县", label: "南丰县" },
          { value: "361024@@崇仁县", label: "崇仁县" },
          { value: "361025@@乐安县", label: "乐安县" },
          { value: "361026@@宜黄县", label: "宜黄县" },
          { value: "361027@@金溪县", label: "金溪县" },
          { value: "361028@@资溪县", label: "资溪县" },
          { value: "361029@@东乡县", label: "东乡县" },
          { value: "361030@@广昌县", label: "广昌县" },
          { value: "361031@@其它区", label: "其它区" },
        ],
      },
      {
        value: "361100@@上饶市",
        label: "上饶市",
        children: [
          { value: "361102@@信州区", label: "信州区" },
          { value: "361121@@上饶县", label: "上饶县" },
          { value: "361122@@广丰县", label: "广丰县" },
          { value: "361123@@玉山县", label: "玉山县" },
          { value: "361124@@铅山县", label: "铅山县" },
          { value: "361125@@横峰县", label: "横峰县" },
          { value: "361126@@弋阳县", label: "弋阳县" },
          { value: "361127@@余干县", label: "余干县" },
          { value: "361128@@鄱阳县", label: "鄱阳县" },
          { value: "361129@@万年县", label: "万年县" },
          { value: "361130@@婺源县", label: "婺源县" },
          { value: "361181@@德兴市", label: "德兴市" },
          { value: "361182@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "370000@@山东",
    label: "山东",
    children: [
      {
        value: "370100@@济南市",
        label: "济南市",
        children: [
          { value: "370102@@历下区", label: "历下区" },
          { value: "370103@@市中区", label: "市中区" },
          { value: "370104@@槐荫区", label: "槐荫区" },
          { value: "370105@@天桥区", label: "天桥区" },
          { value: "370112@@历城区", label: "历城区" },
          { value: "370113@@长清区", label: "长清区" },
          { value: "370124@@平阴县", label: "平阴县" },
          { value: "370125@@济阳县", label: "济阳县" },
          { value: "370126@@商河县", label: "商河县" },
          { value: "370181@@章丘市", label: "章丘市" },
          { value: "370182@@其它区", label: "其它区" },
        ],
      },
      {
        value: "370200@@青岛市",
        label: "青岛市",
        children: [
          { value: "370202@@市南区", label: "市南区" },
          { value: "370203@@市北区", label: "市北区" },
          { value: "370205@@四方区", label: "四方区" },
          { value: "370211@@黄岛区", label: "黄岛区" },
          { value: "370212@@崂山区", label: "崂山区" },
          { value: "370213@@李沧区", label: "李沧区" },
          { value: "370214@@城阳区", label: "城阳区" },
          { value: "370251@@开发区", label: "开发区" },
          { value: "370281@@胶州市", label: "胶州市" },
          { value: "370282@@即墨市", label: "即墨市" },
          { value: "370283@@平度市", label: "平度市" },
          { value: "370284@@胶南市", label: "胶南市" },
          { value: "370285@@莱西市", label: "莱西市" },
          { value: "370286@@其它区", label: "其它区" },
        ],
      },
      {
        value: "370300@@淄博市",
        label: "淄博市",
        children: [
          { value: "370302@@淄川区", label: "淄川区" },
          { value: "370303@@张店区", label: "张店区" },
          { value: "370304@@博山区", label: "博山区" },
          { value: "370305@@临淄区", label: "临淄区" },
          { value: "370306@@周村区", label: "周村区" },
          { value: "370321@@桓台县", label: "桓台县" },
          { value: "370322@@高青县", label: "高青县" },
          { value: "370323@@沂源县", label: "沂源县" },
          { value: "370324@@其它区", label: "其它区" },
        ],
      },
      {
        value: "370400@@枣庄市",
        label: "枣庄市",
        children: [
          { value: "370402@@市中区", label: "市中区" },
          { value: "370403@@薛城区", label: "薛城区" },
          { value: "370404@@峄城区", label: "峄城区" },
          { value: "370405@@台儿庄区", label: "台儿庄区" },
          { value: "370406@@山亭区", label: "山亭区" },
          { value: "370481@@滕州市", label: "滕州市" },
          { value: "370482@@其它区", label: "其它区" },
        ],
      },
      {
        value: "370500@@东营市",
        label: "东营市",
        children: [
          { value: "370502@@东营区", label: "东营区" },
          { value: "370503@@河口区", label: "河口区" },
          { value: "370521@@垦利县", label: "垦利县" },
          { value: "370522@@利津县", label: "利津县" },
          { value: "370523@@广饶县", label: "广饶县" },
          { value: "370589@@西城区", label: "西城区" },
          { value: "370590@@东城区", label: "东城区" },
          { value: "370591@@其它区", label: "其它区" },
        ],
      },
      {
        value: "370600@@烟台市",
        label: "烟台市",
        children: [
          { value: "370602@@芝罘区", label: "芝罘区" },
          { value: "370611@@福山区", label: "福山区" },
          { value: "370612@@牟平区", label: "牟平区" },
          { value: "370613@@莱山区", label: "莱山区" },
          { value: "370634@@长岛县", label: "长岛县" },
          { value: "370681@@龙口市", label: "龙口市" },
          { value: "370682@@莱阳市", label: "莱阳市" },
          { value: "370683@@莱州市", label: "莱州市" },
          { value: "370684@@蓬莱市", label: "蓬莱市" },
          { value: "370685@@招远市", label: "招远市" },
          { value: "370686@@栖霞市", label: "栖霞市" },
          { value: "370687@@海阳市", label: "海阳市" },
          { value: "370688@@其它区", label: "其它区" },
        ],
      },
      {
        value: "370700@@潍坊市",
        label: "潍坊市",
        children: [
          { value: "370702@@潍城区", label: "潍城区" },
          { value: "370703@@寒亭区", label: "寒亭区" },
          { value: "370704@@坊子区", label: "坊子区" },
          { value: "370705@@奎文区", label: "奎文区" },
          { value: "370724@@临朐县", label: "临朐县" },
          { value: "370725@@昌乐县", label: "昌乐县" },
          { value: "370751@@开发区", label: "开发区" },
          { value: "370781@@青州市", label: "青州市" },
          { value: "370782@@诸城市", label: "诸城市" },
          { value: "370783@@寿光市", label: "寿光市" },
          { value: "370784@@安丘市", label: "安丘市" },
          { value: "370785@@高密市", label: "高密市" },
          { value: "370786@@昌邑市", label: "昌邑市" },
          { value: "370787@@其它区", label: "其它区" },
        ],
      },
      {
        value: "370800@@济宁市",
        label: "济宁市",
        children: [
          { value: "370802@@市中区", label: "市中区" },
          { value: "370811@@任城区", label: "任城区" },
          { value: "370826@@微山县", label: "微山县" },
          { value: "370827@@鱼台县", label: "鱼台县" },
          { value: "370828@@金乡县", label: "金乡县" },
          { value: "370829@@嘉祥县", label: "嘉祥县" },
          { value: "370830@@汶上县", label: "汶上县" },
          { value: "370831@@泗水县", label: "泗水县" },
          { value: "370832@@梁山县", label: "梁山县" },
          { value: "370881@@曲阜市", label: "曲阜市" },
          { value: "370882@@兖州市", label: "兖州市" },
          { value: "370883@@邹城市", label: "邹城市" },
          { value: "370884@@其它区", label: "其它区" },
        ],
      },
      {
        value: "370900@@泰安市",
        label: "泰安市",
        children: [
          { value: "370902@@泰山区", label: "泰山区" },
          { value: "370903@@岱岳区", label: "岱岳区" },
          { value: "370921@@宁阳县", label: "宁阳县" },
          { value: "370923@@东平县", label: "东平县" },
          { value: "370982@@新泰市", label: "新泰市" },
          { value: "370983@@肥城市", label: "肥城市" },
          { value: "370984@@其它区", label: "其它区" },
        ],
      },
      {
        value: "371000@@威海市",
        label: "威海市",
        children: [
          { value: "371002@@环翠区", label: "环翠区" },
          { value: "371081@@文登市", label: "文登市" },
          { value: "371082@@荣成市", label: "荣成市" },
          { value: "371083@@乳山市", label: "乳山市" },
          { value: "371084@@其它区", label: "其它区" },
        ],
      },
      {
        value: "371100@@日照市",
        label: "日照市",
        children: [
          { value: "371102@@东港区", label: "东港区" },
          { value: "371103@@岚山区", label: "岚山区" },
          { value: "371121@@五莲县", label: "五莲县" },
          { value: "371122@@莒县", label: "莒县" },
          { value: "371123@@其它区", label: "其它区" },
        ],
      },
      {
        value: "371200@@莱芜市",
        label: "莱芜市",
        children: [
          { value: "371202@@莱城区", label: "莱城区" },
          { value: "371203@@钢城区", label: "钢城区" },
          { value: "371204@@其它区", label: "其它区" },
        ],
      },
      {
        value: "371300@@临沂市",
        label: "临沂市",
        children: [
          { value: "371302@@兰山区", label: "兰山区" },
          { value: "371311@@罗庄区", label: "罗庄区" },
          { value: "371312@@河东区", label: "河东区" },
          { value: "371321@@沂南县", label: "沂南县" },
          { value: "371322@@郯城县", label: "郯城县" },
          { value: "371323@@沂水县", label: "沂水县" },
          { value: "371324@@兰陵", label: "兰陵" },
          { value: "371325@@费县", label: "费县" },
          { value: "371326@@平邑县", label: "平邑县" },
          { value: "371327@@莒南县", label: "莒南县" },
          { value: "371328@@蒙阴县", label: "蒙阴县" },
          { value: "371329@@临沭县", label: "临沭县" },
          { value: "371330@@其它区", label: "其它区" },
        ],
      },
      {
        value: "371400@@德州市",
        label: "德州市",
        children: [
          { value: "371402@@德城区", label: "德城区" },
          { value: "371421@@陵城", label: "陵城" },
          { value: "371422@@宁津县", label: "宁津县" },
          { value: "371423@@庆云县", label: "庆云县" },
          { value: "371424@@临邑县", label: "临邑县" },
          { value: "371425@@齐河县", label: "齐河县" },
          { value: "371426@@平原县", label: "平原县" },
          { value: "371427@@夏津县", label: "夏津县" },
          { value: "371428@@武城县", label: "武城县" },
          { value: "371451@@开发区", label: "开发区" },
          { value: "371481@@乐陵市", label: "乐陵市" },
          { value: "371482@@禹城市", label: "禹城市" },
          { value: "371483@@其它区", label: "其它区" },
        ],
      },
      {
        value: "371500@@聊城市",
        label: "聊城市",
        children: [
          { value: "371502@@东昌府区", label: "东昌府区" },
          { value: "371521@@阳谷县", label: "阳谷县" },
          { value: "371522@@莘县", label: "莘县" },
          { value: "371523@@茌平县", label: "茌平县" },
          { value: "371524@@东阿县", label: "东阿县" },
          { value: "371525@@冠县", label: "冠县" },
          { value: "371526@@高唐县", label: "高唐县" },
          { value: "371581@@临清市", label: "临清市" },
          { value: "371582@@其它区", label: "其它区" },
        ],
      },
      {
        value: "371600@@滨州市",
        label: "滨州市",
        children: [
          { value: "371602@@滨城区", label: "滨城区" },
          { value: "371621@@惠民县", label: "惠民县" },
          { value: "371622@@阳信县", label: "阳信县" },
          { value: "371623@@无棣县", label: "无棣县" },
          { value: "371624@@沾化县", label: "沾化县" },
          { value: "371625@@博兴县", label: "博兴县" },
          { value: "371626@@邹平县", label: "邹平县" },
          { value: "371627@@其它区", label: "其它区" },
        ],
      },
      {
        value: "371700@@菏泽市",
        label: "菏泽市",
        children: [
          { value: "371702@@牡丹区", label: "牡丹区" },
          { value: "371721@@曹县", label: "曹县" },
          { value: "371722@@单县", label: "单县" },
          { value: "371723@@成武县", label: "成武县" },
          { value: "371724@@巨野县", label: "巨野县" },
          { value: "371725@@郓城县", label: "郓城县" },
          { value: "371726@@鄄城县", label: "鄄城县" },
          { value: "371727@@定陶县", label: "定陶县" },
          { value: "371728@@东明县", label: "东明县" },
          { value: "371729@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "410000@@河南",
    label: "河南",
    children: [
      {
        value: "410100@@郑州市",
        label: "郑州市",
        children: [
          { value: "410102@@中原区", label: "中原区" },
          { value: "410103@@二七区", label: "二七区" },
          { value: "410104@@管城回族区", label: "管城回族区" },
          { value: "410105@@金水区", label: "金水区" },
          { value: "410106@@上街区", label: "上街区" },
          { value: "410108@@惠济区", label: "惠济区" },
          { value: "410122@@中牟县", label: "中牟县" },
          { value: "410181@@巩义市", label: "巩义市" },
          { value: "410182@@荥阳市", label: "荥阳市" },
          { value: "410183@@新密市", label: "新密市" },
          { value: "410184@@新郑市", label: "新郑市" },
          { value: "410185@@登封市", label: "登封市" },
          { value: "410186@@郑东新区", label: "郑东新区" },
          { value: "410187@@高新区", label: "高新区" },
          { value: "410188@@其它区", label: "其它区" },
        ],
      },
      {
        value: "410200@@开封市",
        label: "开封市",
        children: [
          { value: "410202@@龙亭区", label: "龙亭区" },
          { value: "410203@@顺河回族区", label: "顺河回族区" },
          { value: "410204@@鼓楼区", label: "鼓楼区" },
          { value: "410205@@禹王台区", label: "禹王台区" },
          { value: "410211@@金明区", label: "金明区" },
          { value: "410221@@杞县", label: "杞县" },
          { value: "410222@@通许县", label: "通许县" },
          { value: "410223@@尉氏县", label: "尉氏县" },
          { value: "410224@@祥符", label: "祥符" },
          { value: "410225@@兰考县", label: "兰考县" },
          { value: "410226@@其它区", label: "其它区" },
        ],
      },
      {
        value: "410300@@洛阳市",
        label: "洛阳市",
        children: [
          { value: "410302@@老城区", label: "老城区" },
          { value: "410303@@西工区", label: "西工区" },
          { value: "410304@@瀍河回族", label: "瀍河回族" },
          { value: "410305@@涧西区", label: "涧西区" },
          { value: "410306@@吉利区", label: "吉利区" },
          { value: "410307@@洛龙区", label: "洛龙区" },
          { value: "410322@@孟津县", label: "孟津县" },
          { value: "410323@@新安县", label: "新安县" },
          { value: "410324@@栾川县", label: "栾川县" },
          { value: "410325@@嵩县", label: "嵩县" },
          { value: "410326@@汝阳县", label: "汝阳县" },
          { value: "410327@@宜阳县", label: "宜阳县" },
          { value: "410328@@洛宁县", label: "洛宁县" },
          { value: "410329@@伊川县", label: "伊川县" },
          { value: "410381@@偃师市", label: "偃师市" },
          { value: "471004@@高新区", label: "高新区" },
          { value: "471005@@其它区", label: "其它区" },
        ],
      },
      {
        value: "410400@@平顶山市",
        label: "平顶山市",
        children: [
          { value: "410402@@新华区", label: "新华区" },
          { value: "410403@@卫东区", label: "卫东区" },
          { value: "410404@@石龙区", label: "石龙区" },
          { value: "410411@@湛河区", label: "湛河区" },
          { value: "410421@@宝丰县", label: "宝丰县" },
          { value: "410422@@叶县", label: "叶县" },
          { value: "410423@@鲁山县", label: "鲁山县" },
          { value: "410425@@郏县", label: "郏县" },
          { value: "410481@@舞钢市", label: "舞钢市" },
          { value: "410482@@汝州市", label: "汝州市" },
          { value: "410483@@其它区", label: "其它区" },
        ],
      },
      {
        value: "410500@@安阳市",
        label: "安阳市",
        children: [
          { value: "410502@@文峰区", label: "文峰区" },
          { value: "410503@@北关区", label: "北关区" },
          { value: "410505@@殷都区", label: "殷都区" },
          { value: "410506@@龙安区", label: "龙安区" },
          { value: "410522@@安阳县", label: "安阳县" },
          { value: "410523@@汤阴县", label: "汤阴县" },
          { value: "410526@@滑县", label: "滑县" },
          { value: "410527@@内黄县", label: "内黄县" },
          { value: "410581@@林州市", label: "林州市" },
          { value: "410582@@其它区", label: "其它区" },
        ],
      },
      {
        value: "410600@@鹤壁市",
        label: "鹤壁市",
        children: [
          { value: "410602@@鹤山区", label: "鹤山区" },
          { value: "410603@@山城区", label: "山城区" },
          { value: "410611@@淇滨区", label: "淇滨区" },
          { value: "410621@@浚县", label: "浚县" },
          { value: "410622@@淇县", label: "淇县" },
          { value: "410623@@其它区", label: "其它区" },
        ],
      },
      {
        value: "410700@@新乡市",
        label: "新乡市",
        children: [
          { value: "410702@@红旗区", label: "红旗区" },
          { value: "410703@@卫滨区", label: "卫滨区" },
          { value: "410704@@凤泉区", label: "凤泉区" },
          { value: "410711@@牧野区", label: "牧野区" },
          { value: "410721@@新乡县", label: "新乡县" },
          { value: "410724@@获嘉县", label: "获嘉县" },
          { value: "410725@@原阳县", label: "原阳县" },
          { value: "410726@@延津县", label: "延津县" },
          { value: "410727@@封丘县", label: "封丘县" },
          { value: "410728@@长垣县", label: "长垣县" },
          { value: "410781@@卫辉市", label: "卫辉市" },
          { value: "410782@@辉县市", label: "辉县市" },
          { value: "410783@@其它区", label: "其它区" },
        ],
      },
      {
        value: "410800@@焦作市",
        label: "焦作市",
        children: [
          { value: "410802@@解放区", label: "解放区" },
          { value: "410803@@中站区", label: "中站区" },
          { value: "410804@@马村区", label: "马村区" },
          { value: "410811@@山阳区", label: "山阳区" },
          { value: "410821@@修武县", label: "修武县" },
          { value: "410822@@博爱县", label: "博爱县" },
          { value: "410823@@武陟县", label: "武陟县" },
          { value: "410825@@温县", label: "温县" },
          { value: "410882@@沁阳市", label: "沁阳市" },
          { value: "410883@@孟州市", label: "孟州市" },
          { value: "410884@@其它区", label: "其它区" },
        ],
      },
      {
        value: "410881@@济源市",
        label: "济源市",
        children: [
          { value: "419001001@@沁园街道", label: "沁园街道" },
          { value: "419001002@@济水街道", label: "济水街道" },
          { value: "419001003@@北海街道", label: "北海街道" },
          { value: "419001004@@天坛街道", label: "天坛街道" },
          { value: "419001005@@玉泉街道", label: "玉泉街道" },
          { value: "419001100@@克井镇", label: "克井镇" },
          { value: "419001101@@五龙口镇", label: "五龙口镇" },
          { value: "419001102@@轵城镇", label: "轵城镇" },
          { value: "419001103@@承留镇", label: "承留镇" },
          { value: "419001104@@邵原镇", label: "邵原镇" },
          { value: "419001105@@坡头镇", label: "坡头镇" },
          { value: "419001106@@梨林镇", label: "梨林镇" },
          { value: "419001107@@大峪镇", label: "大峪镇" },
          { value: "419001108@@思礼镇", label: "思礼镇" },
          { value: "419001109@@王屋镇", label: "王屋镇" },
          { value: "419001110@@下冶镇", label: "下冶镇" },
        ],
      },
      {
        value: "410900@@濮阳市",
        label: "濮阳市",
        children: [
          { value: "410902@@华龙区", label: "华龙区" },
          { value: "410922@@清丰县", label: "清丰县" },
          { value: "410923@@南乐县", label: "南乐县" },
          { value: "410926@@范县", label: "范县" },
          { value: "410927@@台前县", label: "台前县" },
          { value: "410928@@濮阳县", label: "濮阳县" },
          { value: "410929@@其它区", label: "其它区" },
        ],
      },
      {
        value: "411000@@许昌市",
        label: "许昌市",
        children: [
          { value: "411002@@魏都区", label: "魏都区" },
          { value: "411023@@许昌县", label: "许昌县" },
          { value: "411024@@鄢陵县", label: "鄢陵县" },
          { value: "411025@@襄城县", label: "襄城县" },
          { value: "411081@@禹州市", label: "禹州市" },
          { value: "411082@@长葛市", label: "长葛市" },
          { value: "411083@@其它区", label: "其它区" },
        ],
      },
      {
        value: "411100@@漯河市",
        label: "漯河市",
        children: [
          { value: "411102@@源汇区", label: "源汇区" },
          { value: "411103@@郾城区", label: "郾城区" },
          { value: "411104@@召陵区", label: "召陵区" },
          { value: "411121@@舞阳县", label: "舞阳县" },
          { value: "411122@@临颍县", label: "临颍县" },
          { value: "411123@@其它区", label: "其它区" },
        ],
      },
      {
        value: "411200@@三门峡市",
        label: "三门峡市",
        children: [
          { value: "411202@@湖滨区", label: "湖滨区" },
          { value: "411221@@渑池县", label: "渑池县" },
          { value: "411222@@陕州", label: "陕州" },
          { value: "411224@@卢氏县", label: "卢氏县" },
          { value: "411281@@义马市", label: "义马市" },
          { value: "411282@@灵宝市", label: "灵宝市" },
          { value: "411283@@其它区", label: "其它区" },
        ],
      },
      {
        value: "411300@@南阳市",
        label: "南阳市",
        children: [
          { value: "411302@@宛城区", label: "宛城区" },
          { value: "411303@@卧龙区", label: "卧龙区" },
          { value: "411321@@南召县", label: "南召县" },
          { value: "411322@@方城县", label: "方城县" },
          { value: "411323@@西峡县", label: "西峡县" },
          { value: "411324@@镇平县", label: "镇平县" },
          { value: "411325@@内乡县", label: "内乡县" },
          { value: "411326@@淅川县", label: "淅川县" },
          { value: "411327@@社旗县", label: "社旗县" },
          { value: "411328@@唐河县", label: "唐河县" },
          { value: "411329@@新野县", label: "新野县" },
          { value: "411330@@桐柏县", label: "桐柏县" },
          { value: "411381@@邓州市", label: "邓州市" },
          { value: "411382@@其它区", label: "其它区" },
        ],
      },
      {
        value: "411400@@商丘市",
        label: "商丘市",
        children: [
          { value: "411402@@梁园区", label: "梁园区" },
          { value: "411403@@睢阳区", label: "睢阳区" },
          { value: "411421@@民权县", label: "民权县" },
          { value: "411422@@睢县", label: "睢县" },
          { value: "411423@@宁陵县", label: "宁陵县" },
          { value: "411424@@柘城县", label: "柘城县" },
          { value: "411425@@虞城县", label: "虞城县" },
          { value: "411426@@夏邑县", label: "夏邑县" },
          { value: "411481@@永城市", label: "永城市" },
          { value: "411482@@其它区", label: "其它区" },
        ],
      },
      {
        value: "411500@@信阳市",
        label: "信阳市",
        children: [
          { value: "411502@@浉河区", label: "浉河区" },
          { value: "411503@@平桥区", label: "平桥区" },
          { value: "411521@@罗山县", label: "罗山县" },
          { value: "411522@@光山县", label: "光山县" },
          { value: "411523@@新县", label: "新县" },
          { value: "411524@@商城县", label: "商城县" },
          { value: "411525@@固始县", label: "固始县" },
          { value: "411526@@潢川县", label: "潢川县" },
          { value: "411527@@淮滨县", label: "淮滨县" },
          { value: "411528@@息县", label: "息县" },
          { value: "411529@@其它区", label: "其它区" },
        ],
      },
      {
        value: "411600@@周口市",
        label: "周口市",
        children: [
          { value: "411602@@川汇区", label: "川汇区" },
          { value: "411621@@扶沟县", label: "扶沟县" },
          { value: "411622@@西华县", label: "西华县" },
          { value: "411623@@商水县", label: "商水县" },
          { value: "411624@@沈丘县", label: "沈丘县" },
          { value: "411625@@郸城县", label: "郸城县" },
          { value: "411626@@淮阳县", label: "淮阳县" },
          { value: "411627@@太康县", label: "太康县" },
          { value: "411628@@鹿邑县", label: "鹿邑县" },
          { value: "411681@@项城市", label: "项城市" },
          { value: "411682@@其它区", label: "其它区" },
        ],
      },
      {
        value: "411700@@驻马店市",
        label: "驻马店市",
        children: [
          { value: "411702@@驿城区", label: "驿城区" },
          { value: "411721@@西平县", label: "西平县" },
          { value: "411722@@上蔡县", label: "上蔡县" },
          { value: "411723@@平舆县", label: "平舆县" },
          { value: "411724@@正阳县", label: "正阳县" },
          { value: "411725@@确山县", label: "确山县" },
          { value: "411726@@泌阳县", label: "泌阳县" },
          { value: "411727@@汝南县", label: "汝南县" },
          { value: "411728@@遂平县", label: "遂平县" },
          { value: "411729@@新蔡县", label: "新蔡县" },
          { value: "411730@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "420000@@湖北",
    label: "湖北",
    children: [
      {
        value: "420100@@武汉市",
        label: "武汉市",
        children: [
          { value: "420102@@江岸区", label: "江岸区" },
          { value: "420103@@江汉区", label: "江汉区" },
          { value: "420104@@硚口区", label: "硚口区" },
          { value: "420105@@汉阳区", label: "汉阳区" },
          { value: "420106@@武昌区", label: "武昌区" },
          { value: "420107@@青山区", label: "青山区" },
          { value: "420111@@洪山区", label: "洪山区" },
          { value: "420112@@东西湖区", label: "东西湖区" },
          { value: "420113@@汉南区", label: "汉南区" },
          { value: "420114@@蔡甸区", label: "蔡甸区" },
          { value: "420115@@江夏区", label: "江夏区" },
          { value: "420116@@黄陂区", label: "黄陂区" },
          { value: "420117@@新洲区", label: "新洲区" },
          { value: "420118@@其它区", label: "其它区" },
        ],
      },
      {
        value: "420200@@黄石市",
        label: "黄石市",
        children: [
          { value: "420202@@黄石港区", label: "黄石港区" },
          { value: "420203@@西塞山区", label: "西塞山区" },
          { value: "420204@@下陆区", label: "下陆区" },
          { value: "420205@@铁山区", label: "铁山区" },
          { value: "420222@@阳新县", label: "阳新县" },
          { value: "420281@@大冶市", label: "大冶市" },
          { value: "420282@@其它区", label: "其它区" },
        ],
      },
      {
        value: "420300@@十堰市",
        label: "十堰市",
        children: [
          { value: "420302@@茅箭区", label: "茅箭区" },
          { value: "420303@@张湾区", label: "张湾区" },
          { value: "420321@@郧阳", label: "郧阳" },
          { value: "420322@@郧西县", label: "郧西县" },
          { value: "420323@@竹山县", label: "竹山县" },
          { value: "420324@@竹溪县", label: "竹溪县" },
          { value: "420325@@房县", label: "房县" },
          { value: "420381@@丹江口市", label: "丹江口市" },
          { value: "420382@@城区", label: "城区" },
          { value: "420383@@其它区", label: "其它区" },
        ],
      },
      {
        value: "420500@@宜昌市",
        label: "宜昌市",
        children: [
          { value: "420502@@西陵区", label: "西陵区" },
          { value: "420503@@伍家岗区", label: "伍家岗区" },
          { value: "420504@@点军区", label: "点军区" },
          { value: "420505@@猇亭区", label: "猇亭区" },
          { value: "420506@@夷陵区", label: "夷陵区" },
          { value: "420525@@远安县", label: "远安县" },
          { value: "420526@@兴山县", label: "兴山县" },
          { value: "420527@@秭归县", label: "秭归县" },
          { value: "420528@@长阳土家族自治县", label: "长阳土家族自治县" },
          { value: "420529@@五峰土家族自治县", label: "五峰土家族自治县" },
          { value: "420551@@葛洲坝区", label: "葛洲坝区" },
          { value: "420552@@开发区", label: "开发区" },
          { value: "420581@@宜都市", label: "宜都市" },
          { value: "420582@@当阳市", label: "当阳市" },
          { value: "420583@@枝江市", label: "枝江市" },
          { value: "420584@@其它区", label: "其它区" },
        ],
      },
      {
        value: "420600@@襄阳市",
        label: "襄阳市",
        children: [
          { value: "420602@@襄城区", label: "襄城区" },
          { value: "420606@@樊城区", label: "樊城区" },
          { value: "420607@@襄州区", label: "襄州区" },
          { value: "420624@@南漳县", label: "南漳县" },
          { value: "420625@@谷城县", label: "谷城县" },
          { value: "420626@@保康县", label: "保康县" },
          { value: "420682@@老河口市", label: "老河口市" },
          { value: "420683@@枣阳市", label: "枣阳市" },
          { value: "420684@@宜城市", label: "宜城市" },
          { value: "420685@@其它区", label: "其它区" },
        ],
      },
      {
        value: "420700@@鄂州市",
        label: "鄂州市",
        children: [
          { value: "420702@@梁子湖区", label: "梁子湖区" },
          { value: "420703@@华容区", label: "华容区" },
          { value: "420704@@鄂城区", label: "鄂城区" },
          { value: "420705@@其它区", label: "其它区" },
        ],
      },
      {
        value: "420800@@荆门市",
        label: "荆门市",
        children: [
          { value: "420802@@东宝区", label: "东宝区" },
          { value: "420804@@掇刀区", label: "掇刀区" },
          { value: "420821@@京山县", label: "京山县" },
          { value: "420822@@沙洋县", label: "沙洋县" },
          { value: "420881@@钟祥市", label: "钟祥市" },
          { value: "420882@@其它区", label: "其它区" },
        ],
      },
      {
        value: "420900@@孝感市",
        label: "孝感市",
        children: [
          { value: "420902@@孝南区", label: "孝南区" },
          { value: "420921@@孝昌县", label: "孝昌县" },
          { value: "420922@@大悟县", label: "大悟县" },
          { value: "420923@@云梦县", label: "云梦县" },
          { value: "420981@@应城市", label: "应城市" },
          { value: "420982@@安陆市", label: "安陆市" },
          { value: "420984@@汉川市", label: "汉川市" },
          { value: "420985@@其它区", label: "其它区" },
        ],
      },
      {
        value: "421000@@荆州市",
        label: "荆州市",
        children: [
          { value: "421002@@沙市区", label: "沙市区" },
          { value: "421003@@荆州区", label: "荆州区" },
          { value: "421022@@公安县", label: "公安县" },
          { value: "421023@@监利县", label: "监利县" },
          { value: "421024@@江陵县", label: "江陵县" },
          { value: "421081@@石首市", label: "石首市" },
          { value: "421083@@洪湖市", label: "洪湖市" },
          { value: "421087@@松滋市", label: "松滋市" },
          { value: "421088@@其它区", label: "其它区" },
        ],
      },
      {
        value: "421100@@黄冈市",
        label: "黄冈市",
        children: [
          { value: "421102@@黄州区", label: "黄州区" },
          { value: "421121@@团风县", label: "团风县" },
          { value: "421122@@红安县", label: "红安县" },
          { value: "421123@@罗田县", label: "罗田县" },
          { value: "421124@@英山县", label: "英山县" },
          { value: "421125@@浠水县", label: "浠水县" },
          { value: "421126@@蕲春县", label: "蕲春县" },
          { value: "421127@@黄梅县", label: "黄梅县" },
          { value: "421181@@麻城市", label: "麻城市" },
          { value: "421182@@武穴市", label: "武穴市" },
          { value: "421183@@其它区", label: "其它区" },
        ],
      },
      {
        value: "421200@@咸宁市",
        label: "咸宁市",
        children: [
          { value: "421202@@咸安区", label: "咸安区" },
          { value: "421221@@嘉鱼县", label: "嘉鱼县" },
          { value: "421222@@通城县", label: "通城县" },
          { value: "421223@@崇阳县", label: "崇阳县" },
          { value: "421224@@通山县", label: "通山县" },
          { value: "421281@@赤壁市", label: "赤壁市" },
          { value: "421282@@温泉城区", label: "温泉城区" },
          { value: "421283@@其它区", label: "其它区" },
        ],
      },
      {
        value: "421300@@随州市",
        label: "随州市",
        children: [
          { value: "421302@@曾都区", label: "曾都区" },
          { value: "421321@@随县", label: "随县" },
          { value: "421381@@广水市", label: "广水市" },
          { value: "421382@@其它区", label: "其它区" },
        ],
      },
      {
        value: "422800@@恩施土家族苗族自治州",
        label: "恩施土家族苗族自治州",
        children: [
          { value: "422801@@恩施市", label: "恩施市" },
          { value: "422802@@利川市", label: "利川市" },
          { value: "422822@@建始县", label: "建始县" },
          { value: "422823@@巴东县", label: "巴东县" },
          { value: "422825@@宣恩县", label: "宣恩县" },
          { value: "422826@@咸丰县", label: "咸丰县" },
          { value: "422827@@来凤县", label: "来凤县" },
          { value: "422828@@鹤峰县", label: "鹤峰县" },
          { value: "422829@@其它区", label: "其它区" },
        ],
      },
      {
        value: "429004@@仙桃市",
        label: "仙桃市",
        children: [
          { value: "429004001@@沙嘴街道", label: "沙嘴街道" },
          { value: "429004002@@干河街道", label: "干河街道" },
          { value: "429004003@@龙华山办事处", label: "龙华山办事处" },
          { value: "429004100@@郑场镇", label: "郑场镇" },
          { value: "429004101@@毛嘴镇", label: "毛嘴镇" },
          { value: "429004102@@豆河镇", label: "豆河镇" },
          { value: "429004103@@三伏潭镇", label: "三伏潭镇" },
          { value: "429004104@@胡场镇", label: "胡场镇" },
          { value: "429004105@@长倘口镇", label: "长倘口镇" },
          { value: "429004106@@西流河镇", label: "西流河镇" },
          { value: "429004107@@沙湖镇", label: "沙湖镇" },
          { value: "429004108@@杨林尾镇", label: "杨林尾镇" },
          { value: "429004109@@彭场镇", label: "彭场镇" },
          { value: "429004110@@张沟镇", label: "张沟镇" },
          { value: "429004111@@郭河镇", label: "郭河镇" },
          { value: "429004112@@沔城回族镇", label: "沔城回族镇" },
          { value: "429004113@@通海口镇", label: "通海口镇" },
          { value: "429004114@@陈场镇", label: "陈场镇" },
          { value: "429004400@@工业园区", label: "工业园区" },
          { value: "429004401@@九合垸原种场", label: "九合垸原种场" },
          { value: "429004402@@沙湖原种场", label: "沙湖原种场" },
          { value: "429004404@@五湖渔场", label: "五湖渔场" },
          { value: "429004405@@赵西垸林场", label: "赵西垸林场" },
          { value: "429004407@@畜禽良种场", label: "畜禽良种场" },
          { value: "429004408@@排湖风景区", label: "排湖风景区" },
        ],
      },
      {
        value: "429005@@潜江市",
        label: "潜江市",
        children: [
          { value: "429005001@@园林办事处", label: "园林办事处" },
          { value: "429005002@@杨市办事处", label: "杨市办事处" },
          { value: "429005003@@周矶办事处", label: "周矶办事处" },
          { value: "429005004@@广华办事处", label: "广华办事处" },
          { value: "429005005@@泰丰办事处", label: "泰丰办事处" },
          { value: "429005006@@高场办事处", label: "高场办事处" },
          { value: "429005100@@竹根滩镇", label: "竹根滩镇" },
          { value: "429005101@@渔洋镇", label: "渔洋镇" },
          { value: "429005102@@王场镇", label: "王场镇" },
          { value: "429005103@@高石碑镇", label: "高石碑镇" },
          { value: "429005104@@熊口镇", label: "熊口镇" },
          { value: "429005105@@老新镇", label: "老新镇" },
          { value: "429005106@@浩口镇", label: "浩口镇" },
          { value: "429005107@@积玉口镇", label: "积玉口镇" },
          { value: "429005108@@张金镇", label: "张金镇" },
          { value: "429005109@@龙湾镇", label: "龙湾镇" },
          { value: "429005400@@江汉石油管理局", label: "江汉石油管理局" },
          { value: "429005401@@潜江经济开发区", label: "潜江经济开发区" },
          { value: "429005450@@周矶管理区", label: "周矶管理区" },
          { value: "429005451@@后湖管理区", label: "后湖管理区" },
          { value: "429005452@@熊口管理区", label: "熊口管理区" },
          { value: "429005453@@总口管理区", label: "总口管理区" },
          { value: "429005454@@白鹭湖管理区", label: "白鹭湖管理区" },
          { value: "429005455@@运粮湖管理区", label: "运粮湖管理区" },
          { value: "429005457@@浩口原种场", label: "浩口原种场" },
        ],
      },
      {
        value: "429006@@天门市",
        label: "天门市",
        children: [
          { value: "429006001@@竟陵街道", label: "竟陵街道" },
          { value: "429006002@@侨乡街道开发区", label: "侨乡街道开发区" },
          { value: "429006003@@杨林街道", label: "杨林街道" },
          { value: "429006100@@多宝镇", label: "多宝镇" },
          { value: "429006101@@拖市镇", label: "拖市镇" },
          { value: "429006102@@张港镇", label: "张港镇" },
          { value: "429006103@@蒋场镇", label: "蒋场镇" },
          { value: "429006104@@汪场镇", label: "汪场镇" },
          { value: "429006105@@渔薪镇", label: "渔薪镇" },
          { value: "429006106@@黄潭镇", label: "黄潭镇" },
          { value: "429006107@@岳口镇", label: "岳口镇" },
          { value: "429006108@@横林镇", label: "横林镇" },
          { value: "429006109@@彭市镇", label: "彭市镇" },
          { value: "429006110@@麻洋镇", label: "麻洋镇" },
          { value: "429006111@@多祥镇", label: "多祥镇" },
          { value: "429006112@@干驿镇", label: "干驿镇" },
          { value: "429006113@@马湾镇", label: "马湾镇" },
          { value: "429006114@@卢市镇", label: "卢市镇" },
          { value: "429006115@@小板镇", label: "小板镇" },
          { value: "429006116@@九真镇", label: "九真镇" },
          { value: "429006118@@皂市镇", label: "皂市镇" },
          { value: "429006119@@胡市镇", label: "胡市镇" },
          { value: "429006120@@石河镇", label: "石河镇" },
          { value: "429006121@@佛子山镇", label: "佛子山镇" },
          { value: "429006201@@净潭乡", label: "净潭乡" },
          { value: "429006450@@蒋湖农场", label: "蒋湖农场" },
          { value: "429006451@@白茅湖农场", label: "白茅湖农场" },
          { value: "429006452@@沉湖管委会", label: "沉湖管委会" },
        ],
      },
      {
        value: "429021@@神农架林区",
        label: "神农架林区",
        children: [
          { value: "429021100@@松柏镇", label: "松柏镇" },
          { value: "429021101@@阳日镇", label: "阳日镇" },
          { value: "429021102@@木鱼镇", label: "木鱼镇" },
          { value: "429021103@@红坪镇", label: "红坪镇" },
          { value: "429021104@@新华镇", label: "新华镇" },
          { value: "429021105@@九湖镇", label: "九湖镇" },
          { value: "429021200@@宋洛乡", label: "宋洛乡" },
          { value: "429021202@@下谷坪土家族乡", label: "下谷坪土家族乡" },
        ],
      },
    ],
  },
  {
    value: "430000@@湖南",
    label: "湖南",
    children: [
      {
        value: "430100@@长沙市",
        label: "长沙市",
        children: [
          { value: "430102@@芙蓉区", label: "芙蓉区" },
          { value: "430103@@天心区", label: "天心区" },
          { value: "430104@@岳麓区", label: "岳麓区" },
          { value: "430105@@开福区", label: "开福区" },
          { value: "430111@@雨花区", label: "雨花区" },
          { value: "430121@@长沙县", label: "长沙县" },
          { value: "430122@@望城县", label: "望城县" },
          { value: "430124@@宁乡县", label: "宁乡县" },
          { value: "430181@@浏阳市", label: "浏阳市" },
          { value: "430182@@其它区", label: "其它区" },
        ],
      },
      {
        value: "430200@@株洲市",
        label: "株洲市",
        children: [
          { value: "430202@@荷塘区", label: "荷塘区" },
          { value: "430203@@芦淞区", label: "芦淞区" },
          { value: "430204@@石峰区", label: "石峰区" },
          { value: "430211@@天元区", label: "天元区" },
          { value: "430221@@株洲县", label: "株洲县" },
          { value: "430223@@攸县", label: "攸县" },
          { value: "430224@@茶陵县", label: "茶陵县" },
          { value: "430225@@炎陵县", label: "炎陵县" },
          { value: "430281@@醴陵市", label: "醴陵市" },
          { value: "430282@@其它区", label: "其它区" },
        ],
      },
      {
        value: "430300@@湘潭市",
        label: "湘潭市",
        children: [
          { value: "430302@@雨湖区", label: "雨湖区" },
          { value: "430304@@岳塘区", label: "岳塘区" },
          { value: "430321@@湘潭县", label: "湘潭县" },
          { value: "430381@@湘乡市", label: "湘乡市" },
          { value: "430382@@韶山市", label: "韶山市" },
          { value: "430383@@其它区", label: "其它区" },
        ],
      },
      {
        value: "430400@@衡阳市",
        label: "衡阳市",
        children: [
          { value: "430405@@珠晖区", label: "珠晖区" },
          { value: "430406@@雁峰区", label: "雁峰区" },
          { value: "430407@@石鼓区", label: "石鼓区" },
          { value: "430408@@蒸湘区", label: "蒸湘区" },
          { value: "430412@@南岳区", label: "南岳区" },
          { value: "430421@@衡阳县", label: "衡阳县" },
          { value: "430422@@衡南县", label: "衡南县" },
          { value: "430423@@衡山县", label: "衡山县" },
          { value: "430424@@衡东县", label: "衡东县" },
          { value: "430426@@祁东县", label: "祁东县" },
          { value: "430481@@耒阳市", label: "耒阳市" },
          { value: "430482@@常宁市", label: "常宁市" },
          { value: "430483@@其它区", label: "其它区" },
        ],
      },
      {
        value: "430500@@邵阳市",
        label: "邵阳市",
        children: [
          { value: "430502@@双清区", label: "双清区" },
          { value: "430503@@大祥区", label: "大祥区" },
          { value: "430511@@北塔区", label: "北塔区" },
          { value: "430521@@邵东县", label: "邵东县" },
          { value: "430522@@新邵县", label: "新邵县" },
          { value: "430523@@邵阳县", label: "邵阳县" },
          { value: "430524@@隆回县", label: "隆回县" },
          { value: "430525@@洞口县", label: "洞口县" },
          { value: "430527@@绥宁县", label: "绥宁县" },
          { value: "430528@@新宁县", label: "新宁县" },
          { value: "430529@@城步苗族自治县", label: "城步苗族自治县" },
          { value: "430581@@武冈市", label: "武冈市" },
          { value: "430582@@其它区", label: "其它区" },
        ],
      },
      {
        value: "430600@@岳阳市",
        label: "岳阳市",
        children: [
          { value: "430602@@岳阳楼区", label: "岳阳楼区" },
          { value: "430603@@云溪区", label: "云溪区" },
          { value: "430611@@君山区", label: "君山区" },
          { value: "430621@@岳阳县", label: "岳阳县" },
          { value: "430623@@华容县", label: "华容县" },
          { value: "430624@@湘阴县", label: "湘阴县" },
          { value: "430626@@平江县", label: "平江县" },
          { value: "430681@@汨罗市", label: "汨罗市" },
          { value: "430682@@临湘市", label: "临湘市" },
          { value: "430683@@其它区", label: "其它区" },
        ],
      },
      {
        value: "430700@@常德市",
        label: "常德市",
        children: [
          { value: "430702@@武陵区", label: "武陵区" },
          { value: "430703@@鼎城区", label: "鼎城区" },
          { value: "430721@@安乡县", label: "安乡县" },
          { value: "430722@@汉寿县", label: "汉寿县" },
          { value: "430723@@澧县", label: "澧县" },
          { value: "430724@@临澧县", label: "临澧县" },
          { value: "430725@@桃源县", label: "桃源县" },
          { value: "430726@@石门县", label: "石门县" },
          { value: "430781@@津市市", label: "津市市" },
          { value: "430782@@其它区", label: "其它区" },
        ],
      },
      {
        value: "430800@@张家界市",
        label: "张家界市",
        children: [
          { value: "430802@@永定区", label: "永定区" },
          { value: "430811@@武陵源区", label: "武陵源区" },
          { value: "430821@@慈利县", label: "慈利县" },
          { value: "430822@@桑植县", label: "桑植县" },
          { value: "430823@@其它区", label: "其它区" },
        ],
      },
      {
        value: "430900@@益阳市",
        label: "益阳市",
        children: [
          { value: "430902@@资阳区", label: "资阳区" },
          { value: "430903@@赫山区", label: "赫山区" },
          { value: "430921@@南县", label: "南县" },
          { value: "430922@@桃江县", label: "桃江县" },
          { value: "430923@@安化县", label: "安化县" },
          { value: "430981@@沅江市", label: "沅江市" },
          { value: "430982@@其它区", label: "其它区" },
        ],
      },
      {
        value: "431000@@郴州市",
        label: "郴州市",
        children: [
          { value: "431002@@北湖区", label: "北湖区" },
          { value: "431003@@苏仙区", label: "苏仙区" },
          { value: "431021@@桂阳县", label: "桂阳县" },
          { value: "431022@@宜章县", label: "宜章县" },
          { value: "431023@@永兴县", label: "永兴县" },
          { value: "431024@@嘉禾县", label: "嘉禾县" },
          { value: "431025@@临武县", label: "临武县" },
          { value: "431026@@汝城县", label: "汝城县" },
          { value: "431027@@桂东县", label: "桂东县" },
          { value: "431028@@安仁县", label: "安仁县" },
          { value: "431081@@资兴市", label: "资兴市" },
          { value: "431082@@其它区", label: "其它区" },
        ],
      },
      {
        value: "431100@@永州市",
        label: "永州市",
        children: [
          { value: "431102@@零陵区", label: "零陵区" },
          { value: "431103@@冷水滩区", label: "冷水滩区" },
          { value: "431121@@祁阳县", label: "祁阳县" },
          { value: "431122@@东安县", label: "东安县" },
          { value: "431123@@双牌县", label: "双牌县" },
          { value: "431124@@道县", label: "道县" },
          { value: "431125@@江永县", label: "江永县" },
          { value: "431126@@宁远县", label: "宁远县" },
          { value: "431127@@蓝山县", label: "蓝山县" },
          { value: "431128@@新田县", label: "新田县" },
          { value: "431129@@江华瑶族自治县", label: "江华瑶族自治县" },
          { value: "431130@@其它区", label: "其它区" },
        ],
      },
      {
        value: "431200@@怀化市",
        label: "怀化市",
        children: [
          { value: "431202@@鹤城区", label: "鹤城区" },
          { value: "431221@@中方县", label: "中方县" },
          { value: "431222@@沅陵县", label: "沅陵县" },
          { value: "431223@@辰溪县", label: "辰溪县" },
          { value: "431224@@溆浦县", label: "溆浦县" },
          { value: "431225@@会同县", label: "会同县" },
          { value: "431226@@麻阳苗族自治县", label: "麻阳苗族自治县" },
          { value: "431227@@新晃侗族自治县", label: "新晃侗族自治县" },
          { value: "431228@@芷江侗族自治县", label: "芷江侗族自治县" },
          {
            value: "431229@@靖州苗族侗族自治县",
            label: "靖州苗族侗族自治县",
          },
          { value: "431230@@通道侗族自治县", label: "通道侗族自治县" },
          { value: "431281@@洪江市", label: "洪江市" },
          { value: "431282@@其它区", label: "其它区" },
        ],
      },
      {
        value: "431300@@娄底市",
        label: "娄底市",
        children: [
          { value: "431302@@娄星区", label: "娄星区" },
          { value: "431321@@双峰县", label: "双峰县" },
          { value: "431322@@新化县", label: "新化县" },
          { value: "431381@@冷水江市", label: "冷水江市" },
          { value: "431382@@涟源市", label: "涟源市" },
          { value: "431383@@其它区", label: "其它区" },
        ],
      },
      {
        value: "433100@@湘西土家族苗族自治州",
        label: "湘西土家族苗族自治州",
        children: [
          { value: "433101@@吉首市", label: "吉首市" },
          { value: "433122@@泸溪县", label: "泸溪县" },
          { value: "433123@@凤凰县", label: "凤凰县" },
          { value: "433124@@花垣县", label: "花垣县" },
          { value: "433125@@保靖县", label: "保靖县" },
          { value: "433126@@古丈县", label: "古丈县" },
          { value: "433127@@永顺县", label: "永顺县" },
          { value: "433130@@龙山县", label: "龙山县" },
          { value: "433131@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "440000@@广东",
    label: "广东",
    children: [
      {
        value: "440100@@广州市",
        label: "广州市",
        children: [
          { value: "440103@@荔湾区", label: "荔湾区" },
          { value: "440104@@越秀区", label: "越秀区" },
          { value: "440105@@海珠区", label: "海珠区" },
          { value: "440106@@天河区", label: "天河区" },
          { value: "440111@@白云区", label: "白云区" },
          { value: "440112@@黄埔区", label: "黄埔区" },
          { value: "440113@@番禺区", label: "番禺区" },
          { value: "440114@@花都区", label: "花都区" },
          { value: "440115@@南沙区", label: "南沙区" },
          { value: "440116@@萝岗区", label: "萝岗区" },
          { value: "440183@@增城市", label: "增城市" },
          { value: "440184@@从化市", label: "从化市" },
          { value: "440188@@东山区", label: "东山区" },
          { value: "440189@@其它区", label: "其它区" },
        ],
      },
      {
        value: "440200@@韶关市",
        label: "韶关市",
        children: [
          { value: "440203@@武江区", label: "武江区" },
          { value: "440204@@浈江区", label: "浈江区" },
          { value: "440205@@曲江区", label: "曲江区" },
          { value: "440222@@始兴县", label: "始兴县" },
          { value: "440224@@仁化县", label: "仁化县" },
          { value: "440229@@翁源县", label: "翁源县" },
          { value: "440232@@乳源瑶族自治县", label: "乳源瑶族自治县" },
          { value: "440233@@新丰县", label: "新丰县" },
          { value: "440281@@乐昌市", label: "乐昌市" },
          { value: "440282@@南雄市", label: "南雄市" },
          { value: "440283@@其它区", label: "其它区" },
        ],
      },
      {
        value: "440300@@深圳市",
        label: "深圳市",
        children: [
          { value: "440303@@罗湖区", label: "罗湖区" },
          { value: "440304@@福田区", label: "福田区" },
          { value: "440305@@南山区", label: "南山区" },
          { value: "440306@@宝安区", label: "宝安区" },
          { value: "440307@@龙岗区", label: "龙岗区" },
          { value: "440308@@盐田区", label: "盐田区" },
          { value: "440309@@其它区", label: "其它区" },
        ],
      },
      {
        value: "440400@@珠海市",
        label: "珠海市",
        children: [
          { value: "440402@@香洲区", label: "香洲区" },
          { value: "440403@@斗门区", label: "斗门区" },
          { value: "440404@@金湾区", label: "金湾区" },
          { value: "440486@@金唐区", label: "金唐区" },
          { value: "440487@@南湾区", label: "南湾区" },
          { value: "440488@@其它区", label: "其它区" },
        ],
      },
      {
        value: "440500@@汕头市",
        label: "汕头市",
        children: [
          { value: "440507@@龙湖区", label: "龙湖区" },
          { value: "440511@@金平区", label: "金平区" },
          { value: "440512@@濠江区", label: "濠江区" },
          { value: "440513@@潮阳区", label: "潮阳区" },
          { value: "440514@@潮南区", label: "潮南区" },
          { value: "440515@@澄海区", label: "澄海区" },
          { value: "440523@@南澳县", label: "南澳县" },
          { value: "440524@@其它区", label: "其它区" },
        ],
      },
      {
        value: "440600@@佛山市",
        label: "佛山市",
        children: [
          { value: "440604@@禅城区", label: "禅城区" },
          { value: "440605@@南海区", label: "南海区" },
          { value: "440606@@顺德区", label: "顺德区" },
          { value: "440607@@三水区", label: "三水区" },
          { value: "440608@@高明区", label: "高明区" },
          { value: "440609@@其它区", label: "其它区" },
        ],
      },
      {
        value: "440700@@江门市",
        label: "江门市",
        children: [
          { value: "440703@@蓬江区", label: "蓬江区" },
          { value: "440704@@江海区", label: "江海区" },
          { value: "440705@@新会区", label: "新会区" },
          { value: "440781@@台山市", label: "台山市" },
          { value: "440783@@开平市", label: "开平市" },
          { value: "440784@@鹤山市", label: "鹤山市" },
          { value: "440785@@恩平市", label: "恩平市" },
          { value: "440786@@其它区", label: "其它区" },
        ],
      },
      {
        value: "440800@@湛江市",
        label: "湛江市",
        children: [
          { value: "440802@@赤坎区", label: "赤坎区" },
          { value: "440803@@霞山区", label: "霞山区" },
          { value: "440804@@坡头区", label: "坡头区" },
          { value: "440811@@麻章区", label: "麻章区" },
          { value: "440823@@遂溪县", label: "遂溪县" },
          { value: "440825@@徐闻县", label: "徐闻县" },
          { value: "440881@@廉江市", label: "廉江市" },
          { value: "440882@@雷州市", label: "雷州市" },
          { value: "440883@@吴川市", label: "吴川市" },
          { value: "440884@@其它区", label: "其它区" },
        ],
      },
      {
        value: "440900@@茂名市",
        label: "茂名市",
        children: [
          { value: "440902@@茂南区", label: "茂南区" },
          { value: "440903@@电白", label: "电白" },
          { value: "440923@@电白县", label: "电白县" },
          { value: "440981@@高州市", label: "高州市" },
          { value: "440982@@化州市", label: "化州市" },
          { value: "440983@@信宜市", label: "信宜市" },
          { value: "440984@@其它区", label: "其它区" },
        ],
      },
      {
        value: "441200@@肇庆市",
        label: "肇庆市",
        children: [
          { value: "441202@@端州区", label: "端州区" },
          { value: "441203@@鼎湖区", label: "鼎湖区" },
          { value: "441223@@广宁县", label: "广宁县" },
          { value: "441224@@怀集县", label: "怀集县" },
          { value: "441225@@封开县", label: "封开县" },
          { value: "441226@@德庆县", label: "德庆县" },
          { value: "441283@@高要市", label: "高要市" },
          { value: "441284@@四会市", label: "四会市" },
          { value: "441285@@其它区", label: "其它区" },
        ],
      },
      {
        value: "441300@@惠州市",
        label: "惠州市",
        children: [
          { value: "441302@@惠城区", label: "惠城区" },
          { value: "441303@@惠阳区", label: "惠阳区" },
          { value: "441322@@博罗县", label: "博罗县" },
          { value: "441323@@惠东县", label: "惠东县" },
          { value: "441324@@龙门县", label: "龙门县" },
          { value: "441325@@其它区", label: "其它区" },
        ],
      },
      {
        value: "441400@@梅州市",
        label: "梅州市",
        children: [
          { value: "441402@@梅江区", label: "梅江区" },
          { value: "441421@@梅县", label: "梅县" },
          { value: "441422@@大埔县", label: "大埔县" },
          { value: "441423@@丰顺县", label: "丰顺县" },
          { value: "441424@@五华县", label: "五华县" },
          { value: "441426@@平远县", label: "平远县" },
          { value: "441427@@蕉岭县", label: "蕉岭县" },
          { value: "441481@@兴宁市", label: "兴宁市" },
          { value: "441482@@其它区", label: "其它区" },
        ],
      },
      {
        value: "441500@@汕尾市",
        label: "汕尾市",
        children: [
          { value: "441502@@城区", label: "城区" },
          { value: "441521@@海丰县", label: "海丰县" },
          { value: "441523@@陆河县", label: "陆河县" },
          { value: "441581@@陆丰市", label: "陆丰市" },
          { value: "441582@@其它区", label: "其它区" },
        ],
      },
      {
        value: "441600@@河源市",
        label: "河源市",
        children: [
          { value: "441602@@源城区", label: "源城区" },
          { value: "441621@@紫金县", label: "紫金县" },
          { value: "441622@@龙川县", label: "龙川县" },
          { value: "441623@@连平县", label: "连平县" },
          { value: "441624@@和平县", label: "和平县" },
          { value: "441625@@东源县", label: "东源县" },
          { value: "441626@@其它区", label: "其它区" },
        ],
      },
      {
        value: "441700@@阳江市",
        label: "阳江市",
        children: [
          { value: "441702@@江城区", label: "江城区" },
          { value: "441721@@阳西县", label: "阳西县" },
          { value: "441723@@阳东县", label: "阳东县" },
          { value: "441781@@阳春市", label: "阳春市" },
          { value: "441782@@其它区", label: "其它区" },
        ],
      },
      {
        value: "441800@@清远市",
        label: "清远市",
        children: [
          { value: "441802@@清城区", label: "清城区" },
          { value: "441821@@佛冈县", label: "佛冈县" },
          { value: "441823@@阳山县", label: "阳山县" },
          {
            value: "441825@@连山壮族瑶族自治县",
            label: "连山壮族瑶族自治县",
          },
          { value: "441826@@连南瑶族自治县", label: "连南瑶族自治县" },
          { value: "441827@@清新县", label: "清新县" },
          { value: "441881@@英德市", label: "英德市" },
          { value: "441882@@连州市", label: "连州市" },
          { value: "441883@@其它区", label: "其它区" },
        ],
      },
      {
        value: "441900@@东莞市",
        label: "东莞市",
        children: [
          { value: "441901@@莞城区", label: "莞城区" },
          { value: "441902@@南城区", label: "南城区" },
          { value: "441904@@万江区", label: "万江区" },
          { value: "441901101@@石碣镇", label: "石碣镇" },
          { value: "441901102@@石龙镇", label: "石龙镇" },
          { value: "441901103@@茶山镇", label: "茶山镇" },
          { value: "441901104@@石排镇", label: "石排镇" },
          { value: "441901105@@企石镇", label: "企石镇" },
          { value: "441901106@@横沥镇", label: "横沥镇" },
          { value: "441901107@@桥头镇", label: "桥头镇" },
          { value: "441901108@@谢岗镇", label: "谢岗镇" },
          { value: "441901109@@东坑镇", label: "东坑镇" },
          { value: "441901110@@常平镇", label: "常平镇" },
          { value: "441901111@@寮步镇", label: "寮步镇" },
          { value: "441901113@@大朗镇", label: "大朗镇" },
          { value: "441901126@@麻涌镇", label: "麻涌镇" },
          { value: "441901128@@中堂镇", label: "中堂镇" },
          { value: "441901129@@高埗镇", label: "高埗镇" },
          { value: "441901112@@樟木头镇", label: "樟木头镇" },
          { value: "441901118@@大岭山镇", label: "大岭山镇" },
          { value: "441901127@@望牛墩镇", label: "望牛墩镇" },
          { value: "441901114@@黄江镇", label: "黄江镇" },
          { value: "441901125@@洪梅镇", label: "洪梅镇" },
          { value: "441901115@@清溪镇", label: "清溪镇" },
          { value: "441901123@@沙田镇", label: "沙田镇" },
          { value: "441901124@@道滘镇", label: "道滘镇" },
          { value: "441901116@@塘厦镇", label: "塘厦镇" },
          { value: "441901121@@虎门镇", label: "虎门镇" },
          { value: "441901122@@厚街镇", label: "厚街镇" },
          { value: "441901117@@凤岗镇", label: "凤岗镇" },
          { value: "441901119@@长安镇", label: "长安镇" },
        ],
      },
      {
        value: "442000@@中山市",
        label: "中山市",
        children: [
          { value: "442100@@东区", label: "东区" },
          { value: "442200@@南区", label: "南区" },
          { value: "442300@@西区", label: "西区" },
          { value: "442400@@石岐区", label: "石岐区" },
          { value: "442500@@五桂山区", label: "五桂山区" },
        ],
      },
      {
        value: "445100@@潮州市",
        label: "潮州市",
        children: [
          { value: "445102@@湘桥区", label: "湘桥区" },
          { value: "445121@@潮安县", label: "潮安县" },
          { value: "445122@@饶平县", label: "饶平县" },
          { value: "445185@@枫溪区", label: "枫溪区" },
          { value: "445186@@其它区", label: "其它区" },
        ],
      },
      {
        value: "445200@@揭阳市",
        label: "揭阳市",
        children: [
          { value: "445202@@榕城区", label: "榕城区" },
          { value: "445221@@揭东县", label: "揭东县" },
          { value: "445222@@揭西县", label: "揭西县" },
          { value: "445224@@惠来县", label: "惠来县" },
          { value: "445281@@普宁市", label: "普宁市" },
          { value: "445284@@东山区", label: "东山区" },
          { value: "445285@@其它区", label: "其它区" },
        ],
      },
      {
        value: "445300@@云浮市",
        label: "云浮市",
        children: [
          { value: "445302@@云城区", label: "云城区" },
          { value: "445321@@新兴县", label: "新兴县" },
          { value: "445322@@郁南县", label: "郁南县" },
          { value: "445323@@云安县", label: "云安县" },
          { value: "445381@@罗定市", label: "罗定市" },
          { value: "445382@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "450000@@广西",
    label: "广西",
    children: [
      {
        value: "450100@@南宁市",
        label: "南宁市",
        children: [
          { value: "450102@@兴宁区", label: "兴宁区" },
          { value: "450103@@青秀区", label: "青秀区" },
          { value: "450105@@江南区", label: "江南区" },
          { value: "450107@@西乡塘区", label: "西乡塘区" },
          { value: "450108@@良庆区", label: "良庆区" },
          { value: "450109@@邕宁区", label: "邕宁区" },
          { value: "450122@@武鸣县", label: "武鸣县" },
          { value: "450123@@隆安县", label: "隆安县" },
          { value: "450124@@马山县", label: "马山县" },
          { value: "450125@@上林县", label: "上林县" },
          { value: "450126@@宾阳县", label: "宾阳县" },
          { value: "450127@@横县", label: "横县" },
          { value: "450128@@其它区", label: "其它区" },
        ],
      },
      {
        value: "450200@@柳州市",
        label: "柳州市",
        children: [
          { value: "450202@@城中区", label: "城中区" },
          { value: "450203@@鱼峰区", label: "鱼峰区" },
          { value: "450204@@柳南区", label: "柳南区" },
          { value: "450205@@柳北区", label: "柳北区" },
          { value: "450221@@柳江县", label: "柳江县" },
          { value: "450222@@柳城县", label: "柳城县" },
          { value: "450223@@鹿寨县", label: "鹿寨县" },
          { value: "450224@@融安县", label: "融安县" },
          { value: "450225@@融水苗族自治县", label: "融水苗族自治县" },
          { value: "450226@@三江侗族自治县", label: "三江侗族自治县" },
          { value: "450227@@其它区", label: "其它区" },
        ],
      },
      {
        value: "450300@@桂林市",
        label: "桂林市",
        children: [
          { value: "450302@@秀峰区", label: "秀峰区" },
          { value: "450303@@叠彩区", label: "叠彩区" },
          { value: "450304@@象山区", label: "象山区" },
          { value: "450305@@七星区", label: "七星区" },
          { value: "450311@@雁山区", label: "雁山区" },
          { value: "450321@@阳朔县", label: "阳朔县" },
          { value: "450322@@临桂县", label: "临桂县" },
          { value: "450323@@灵川县", label: "灵川县" },
          { value: "450324@@全州县", label: "全州县" },
          { value: "450325@@兴安县", label: "兴安县" },
          { value: "450326@@永福县", label: "永福县" },
          { value: "450327@@灌阳县", label: "灌阳县" },
          { value: "450328@@龙胜各族自治县", label: "龙胜各族自治县" },
          { value: "450329@@资源县", label: "资源县" },
          { value: "450330@@平乐县", label: "平乐县" },
          { value: "450331@@荔浦县", label: "荔浦县" },
          { value: "450332@@恭城瑶族自治县", label: "恭城瑶族自治县" },
          { value: "450333@@其它区", label: "其它区" },
        ],
      },
      {
        value: "450400@@梧州市",
        label: "梧州市",
        children: [
          { value: "450403@@万秀区", label: "万秀区" },
          { value: "450404@@蝶山区", label: "蝶山区" },
          { value: "450405@@长洲区", label: "长洲区" },
          { value: "450421@@苍梧县", label: "苍梧县" },
          { value: "450422@@藤县", label: "藤县" },
          { value: "450423@@蒙山县", label: "蒙山县" },
          { value: "450481@@岑溪市", label: "岑溪市" },
          { value: "450482@@其它区", label: "其它区" },
        ],
      },
      {
        value: "450500@@北海市",
        label: "北海市",
        children: [
          { value: "450502@@海城区", label: "海城区" },
          { value: "450503@@银海区", label: "银海区" },
          { value: "450512@@铁山港区", label: "铁山港区" },
          { value: "450521@@合浦县", label: "合浦县" },
          { value: "450522@@其它区", label: "其它区" },
        ],
      },
      {
        value: "450600@@防城港市",
        label: "防城港市",
        children: [
          { value: "450602@@港口区", label: "港口区" },
          { value: "450603@@防城区", label: "防城区" },
          { value: "450621@@上思县", label: "上思县" },
          { value: "450681@@东兴市", label: "东兴市" },
          { value: "450682@@其它区", label: "其它区" },
        ],
      },
      {
        value: "450700@@钦州市",
        label: "钦州市",
        children: [
          { value: "450702@@钦南区", label: "钦南区" },
          { value: "450703@@钦北区", label: "钦北区" },
          { value: "450721@@灵山县", label: "灵山县" },
          { value: "450722@@浦北县", label: "浦北县" },
          { value: "450723@@其它区", label: "其它区" },
        ],
      },
      {
        value: "450800@@贵港市",
        label: "贵港市",
        children: [
          { value: "450802@@港北区", label: "港北区" },
          { value: "450803@@港南区", label: "港南区" },
          { value: "450804@@覃塘区", label: "覃塘区" },
          { value: "450821@@平南县", label: "平南县" },
          { value: "450881@@桂平市", label: "桂平市" },
          { value: "450882@@其它区", label: "其它区" },
        ],
      },
      {
        value: "450900@@玉林市",
        label: "玉林市",
        children: [
          { value: "450902@@玉州区", label: "玉州区" },
          { value: "450921@@容县", label: "容县" },
          { value: "450922@@陆川县", label: "陆川县" },
          { value: "450923@@博白县", label: "博白县" },
          { value: "450924@@兴业县", label: "兴业县" },
          { value: "450981@@北流市", label: "北流市" },
          { value: "450982@@其它区", label: "其它区" },
        ],
      },
      {
        value: "451000@@百色市",
        label: "百色市",
        children: [
          { value: "451002@@右江区", label: "右江区" },
          { value: "451021@@田阳县", label: "田阳县" },
          { value: "451022@@田东县", label: "田东县" },
          { value: "451023@@平果县", label: "平果县" },
          { value: "451024@@德保县", label: "德保县" },
          { value: "451025@@靖西县", label: "靖西县" },
          { value: "451026@@那坡县", label: "那坡县" },
          { value: "451027@@凌云县", label: "凌云县" },
          { value: "451028@@乐业县", label: "乐业县" },
          { value: "451029@@田林县", label: "田林县" },
          { value: "451030@@西林县", label: "西林县" },
          { value: "451031@@隆林各族自治县", label: "隆林各族自治县" },
          { value: "451032@@其它区", label: "其它区" },
        ],
      },
      {
        value: "451100@@贺州市",
        label: "贺州市",
        children: [
          { value: "451102@@八步区", label: "八步区" },
          { value: "451121@@昭平县", label: "昭平县" },
          { value: "451122@@钟山县", label: "钟山县" },
          { value: "451123@@富川瑶族自治县", label: "富川瑶族自治县" },
          { value: "451124@@其它区", label: "其它区" },
        ],
      },
      {
        value: "451200@@河池市",
        label: "河池市",
        children: [
          { value: "451202@@金城江区", label: "金城江区" },
          { value: "451221@@南丹县", label: "南丹县" },
          { value: "451222@@天峨县", label: "天峨县" },
          { value: "451223@@凤山县", label: "凤山县" },
          { value: "451224@@东兰县", label: "东兰县" },
          { value: "451225@@罗城仫佬族自治县", label: "罗城仫佬族自治县" },
          { value: "451226@@环江毛南族自治县", label: "环江毛南族自治县" },
          { value: "451227@@巴马瑶族自治县", label: "巴马瑶族自治县" },
          { value: "451228@@都安瑶族自治县", label: "都安瑶族自治县" },
          { value: "451229@@大化瑶族自治县", label: "大化瑶族自治县" },
          { value: "451281@@宜州市", label: "宜州市" },
          { value: "451282@@其它区", label: "其它区" },
        ],
      },
      {
        value: "451300@@来宾市",
        label: "来宾市",
        children: [
          { value: "451302@@兴宾区", label: "兴宾区" },
          { value: "451321@@忻城县", label: "忻城县" },
          { value: "451322@@象州县", label: "象州县" },
          { value: "451323@@武宣县", label: "武宣县" },
          { value: "451324@@金秀瑶族自治县", label: "金秀瑶族自治县" },
          { value: "451381@@合山市", label: "合山市" },
          { value: "451382@@其它区", label: "其它区" },
        ],
      },
      {
        value: "451400@@崇左市",
        label: "崇左市",
        children: [
          { value: "451402@@江州区", label: "江州区" },
          { value: "451421@@扶绥县", label: "扶绥县" },
          { value: "451422@@宁明县", label: "宁明县" },
          { value: "451423@@龙州县", label: "龙州县" },
          { value: "451424@@大新县", label: "大新县" },
          { value: "451425@@天等县", label: "天等县" },
          { value: "451481@@凭祥市", label: "凭祥市" },
          { value: "451482@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "460000@@海南",
    label: "海南",
    children: [
      {
        value: "460100@@海口市",
        label: "海口市",
        children: [
          { value: "460105@@秀英区", label: "秀英区" },
          { value: "460106@@龙华区", label: "龙华区" },
          { value: "460107@@琼山区", label: "琼山区" },
          { value: "460108@@美兰区", label: "美兰区" },
          { value: "460109@@其它区", label: "其它区" },
        ],
      },
      {
        value: "460200@@三亚市",
        label: "三亚市",
        children: [
          { value: "460202@@海棠", label: "海棠" },
          { value: "460203@@吉阳", label: "吉阳" },
          { value: "460204@@天涯", label: "天涯" },
          { value: "460205@@崖州", label: "崖州" },
          { value: "460201100@@海棠湾镇", label: "海棠湾镇" },
          { value: "460201101@@吉阳镇", label: "吉阳镇" },
          { value: "460201102@@凤凰镇", label: "凤凰镇" },
          { value: "460201103@@崖城镇", label: "崖城镇" },
          { value: "460201104@@天涯镇", label: "天涯镇" },
          { value: "460201105@@育才镇", label: "育才镇" },
          { value: "460201400@@国营南田农场", label: "国营南田农场" },
          { value: "460201401@@国营南新农场", label: "国营南新农场" },
          { value: "460201403@@国营立才农场", label: "国营立才农场" },
          { value: "460201404@@国营南滨农场", label: "国营南滨农场" },
          { value: "460201451@@河西区街道", label: "河西区街道" },
          { value: "460201452@@河东区街道", label: "河东区街道" },
        ],
      },
      {
        value: "469001@@五指山市",
        label: "五指山市",
        children: [
          { value: "469001100@@通什镇", label: "通什镇" },
          { value: "469001101@@南圣镇", label: "南圣镇" },
          { value: "469001102@@毛阳镇", label: "毛阳镇" },
          { value: "469001103@@番阳镇", label: "番阳镇" },
          { value: "469001200@@畅好乡", label: "畅好乡" },
          { value: "469001201@@毛道乡", label: "毛道乡" },
          { value: "469001202@@水满乡", label: "水满乡" },
          { value: "469001400@@国营畅好农场", label: "国营畅好农场" },
        ],
      },
      {
        value: "469002@@琼海市",
        label: "琼海市",
        children: [
          { value: "469002100@@嘉积镇", label: "嘉积镇" },
          { value: "469002101@@万泉镇", label: "万泉镇" },
          { value: "469002102@@石壁镇", label: "石壁镇" },
          { value: "469002103@@中原镇", label: "中原镇" },
          { value: "469002104@@博鳌镇", label: "博鳌镇" },
          { value: "469002105@@阳江镇", label: "阳江镇" },
          { value: "469002106@@龙江镇", label: "龙江镇" },
          { value: "469002107@@潭门镇", label: "潭门镇" },
          { value: "469002108@@塔洋镇", label: "塔洋镇" },
          { value: "469002109@@长坡镇", label: "长坡镇" },
          { value: "469002110@@大路镇", label: "大路镇" },
          { value: "469002111@@会山镇", label: "会山镇" },
          { value: "469002400@@国营东太农场", label: "国营东太农场" },
          { value: "469002402@@国营东红农场", label: "国营东红农场" },
          { value: "469002403@@国营东升农场", label: "国营东升农场" },
          { value: "469002500@@彬村山华侨农场", label: "彬村山华侨农场" },
        ],
      },
      {
        value: "469003@@儋州市",
        label: "儋州市",
        children: [
          { value: "469003100@@那大镇", label: "那大镇" },
          { value: "469003101@@和庆镇", label: "和庆镇" },
          { value: "469003102@@南丰镇", label: "南丰镇" },
          { value: "469003103@@大成镇", label: "大成镇" },
          { value: "469003104@@雅星镇", label: "雅星镇" },
          { value: "469003105@@兰洋镇", label: "兰洋镇" },
          { value: "469003106@@光村镇", label: "光村镇" },
          { value: "469003107@@木棠镇", label: "木棠镇" },
          { value: "469003108@@海头镇", label: "海头镇" },
          { value: "469003109@@峨蔓镇", label: "峨蔓镇" },
          { value: "469003110@@三都镇", label: "三都镇" },
          { value: "469003111@@王五镇", label: "王五镇" },
          { value: "469003112@@白马井镇", label: "白马井镇" },
          { value: "469003113@@中和镇", label: "中和镇" },
          { value: "469003114@@排浦镇", label: "排浦镇" },
          { value: "469003115@@东成镇", label: "东成镇" },
          { value: "469003116@@新州镇", label: "新州镇" },
          { value: "469003400@@国营西培农场", label: "国营西培农场" },
          { value: "469003404@@国营西联农场", label: "国营西联农场" },
          { value: "469003405@@国营蓝洋农场", label: "国营蓝洋农场" },
          { value: "469003407@@国营八一农场", label: "国营八一农场" },
          { value: "469003499@@洋浦经济开发区", label: "洋浦经济开发区" },
          { value: "469003500@@华南热作学院", label: "华南热作学院" },
        ],
      },
      {
        value: "469005@@文昌市",
        label: "文昌市",
        children: [
          { value: "469005100@@文城镇", label: "文城镇" },
          { value: "469005101@@重兴镇", label: "重兴镇" },
          { value: "469005102@@蓬莱镇", label: "蓬莱镇" },
          { value: "469005103@@会文镇", label: "会文镇" },
          { value: "469005104@@东路镇", label: "东路镇" },
          { value: "469005105@@潭牛镇", label: "潭牛镇" },
          { value: "469005106@@东阁镇", label: "东阁镇" },
          { value: "469005107@@文教镇", label: "文教镇" },
          { value: "469005108@@东郊镇", label: "东郊镇" },
          { value: "469005109@@龙楼镇", label: "龙楼镇" },
          { value: "469005110@@昌洒镇", label: "昌洒镇" },
          { value: "469005111@@翁田镇", label: "翁田镇" },
          { value: "469005112@@抱罗镇", label: "抱罗镇" },
          { value: "469005113@@冯坡镇", label: "冯坡镇" },
          { value: "469005114@@锦山镇", label: "锦山镇" },
          { value: "469005115@@铺前镇", label: "铺前镇" },
          { value: "469005116@@公坡镇", label: "公坡镇" },
          { value: "469005400@@国营东路农场", label: "国营东路农场" },
          { value: "469005401@@国营南阳农场", label: "国营南阳农场" },
          { value: "469005402@@国营罗豆农场", label: "国营罗豆农场" },
        ],
      },
      {
        value: "469006@@万宁市",
        label: "万宁市",
        children: [
          { value: "469006100@@万城镇", label: "万城镇" },
          { value: "469006101@@龙滚镇", label: "龙滚镇" },
          { value: "469006102@@和乐镇", label: "和乐镇" },
          { value: "469006103@@后安镇", label: "后安镇" },
          { value: "469006104@@大茂镇", label: "大茂镇" },
          { value: "469006105@@东澳镇", label: "东澳镇" },
          { value: "469006106@@礼纪镇", label: "礼纪镇" },
          { value: "469006107@@长丰镇", label: "长丰镇" },
          { value: "469006108@@山根镇", label: "山根镇" },
          { value: "469006109@@北大镇", label: "北大镇" },
          { value: "469006110@@南桥镇", label: "南桥镇" },
          { value: "469006111@@三更罗镇", label: "三更罗镇" },
          { value: "469006400@@国营东兴农场", label: "国营东兴农场" },
          { value: "469006401@@国营东和农场", label: "国营东和农场" },
          { value: "469006404@@国营新中农场", label: "国营新中农场" },
          { value: "469006500@@兴隆华侨农场", label: "兴隆华侨农场" },
          {
            value: "469006501@@地方国营六连林场",
            label: "地方国营六连林场",
          },
        ],
      },
      {
        value: "469007@@东方市",
        label: "东方市",
        children: [
          { value: "469007100@@八所镇", label: "八所镇" },
          { value: "469007101@@东河镇", label: "东河镇" },
          { value: "469007102@@大田镇", label: "大田镇" },
          { value: "469007103@@感城镇", label: "感城镇" },
          { value: "469007104@@板桥镇", label: "板桥镇" },
          { value: "469007105@@三家镇", label: "三家镇" },
          { value: "469007106@@四更镇", label: "四更镇" },
          { value: "469007107@@新龙镇", label: "新龙镇" },
          { value: "469007200@@天安乡", label: "天安乡" },
          { value: "469007201@@江边乡", label: "江边乡" },
          { value: "469007400@@国营广坝农场", label: "国营广坝农场" },
          { value: "469007500@@东方华侨农场", label: "东方华侨农场" },
        ],
      },
      {
        value: "469025@@定安县",
        label: "定安县",
        children: [
          { value: "469021100@@定城镇", label: "定城镇" },
          { value: "469021101@@新竹镇", label: "新竹镇" },
          { value: "469021102@@龙湖镇", label: "龙湖镇" },
          { value: "469021103@@黄竹镇", label: "黄竹镇" },
          { value: "469021104@@雷鸣镇", label: "雷鸣镇" },
          { value: "469021105@@龙门镇", label: "龙门镇" },
          { value: "469021106@@龙河镇", label: "龙河镇" },
          { value: "469021107@@岭口镇", label: "岭口镇" },
          { value: "469021108@@翰林镇", label: "翰林镇" },
          { value: "469021109@@富文镇", label: "富文镇" },
          { value: "469021400@@国营中瑞农场", label: "国营中瑞农场" },
          { value: "469021401@@国营南海农场", label: "国营南海农场" },
          { value: "469021402@@国营金鸡岭农场", label: "国营金鸡岭农场" },
        ],
      },
      {
        value: "469026@@屯昌县",
        label: "屯昌县",
        children: [
          { value: "469022100@@屯城镇", label: "屯城镇" },
          { value: "469022101@@新兴镇", label: "新兴镇" },
          { value: "469022102@@枫木镇", label: "枫木镇" },
          { value: "469022103@@乌坡镇", label: "乌坡镇" },
          { value: "469022104@@南吕镇", label: "南吕镇" },
          { value: "469022105@@南坤镇", label: "南坤镇" },
          { value: "469022106@@坡心镇", label: "坡心镇" },
          { value: "469022107@@西昌镇", label: "西昌镇" },
          { value: "469022400@@国营中建农场", label: "国营中建农场" },
          { value: "469022401@@国营中坤农场", label: "国营中坤农场" },
        ],
      },
      {
        value: "469027@@澄迈县",
        label: "澄迈县",
        children: [
          { value: "469023100@@金江镇", label: "金江镇" },
          { value: "469023101@@老城镇", label: "老城镇" },
          { value: "469023102@@瑞溪镇", label: "瑞溪镇" },
          { value: "469023103@@永发镇", label: "永发镇" },
          { value: "469023104@@加乐镇", label: "加乐镇" },
          { value: "469023105@@文儒镇", label: "文儒镇" },
          { value: "469023106@@中兴镇", label: "中兴镇" },
          { value: "469023107@@仁兴镇", label: "仁兴镇" },
          { value: "469023108@@福山镇", label: "福山镇" },
          { value: "469023109@@桥头镇", label: "桥头镇" },
          { value: "469023110@@大丰镇", label: "大丰镇" },
          { value: "469023400@@国营红光农场", label: "国营红光农场" },
          { value: "469023401@@国营红岗农场", label: "国营红岗农场" },
          { value: "469023402@@国营西达农场", label: "国营西达农场" },
          { value: "469023403@@国营昆仑农场", label: "国营昆仑农场" },
          { value: "469023404@@国营和岭农场", label: "国营和岭农场" },
          { value: "469023405@@国营金安农场", label: "国营金安农场" },
        ],
      },
      {
        value: "469028@@临高县",
        label: "临高县",
        children: [
          { value: "469024100@@临城镇", label: "临城镇" },
          { value: "469024101@@波莲镇", label: "波莲镇" },
          { value: "469024102@@东英镇", label: "东英镇" },
          { value: "469024103@@博厚镇", label: "博厚镇" },
          { value: "469024104@@皇桐镇", label: "皇桐镇" },
          { value: "469024105@@多文镇", label: "多文镇" },
          { value: "469024106@@和舍镇", label: "和舍镇" },
          { value: "469024107@@南宝镇", label: "南宝镇" },
          { value: "469024108@@新盈镇", label: "新盈镇" },
          { value: "469024109@@调楼镇", label: "调楼镇" },
          { value: "469024400@@国营红华农场", label: "国营红华农场" },
          { value: "469024401@@国营加来农场", label: "国营加来农场" },
        ],
      },
      {
        value: "469030@@白沙黎族自治县",
        label: "白沙黎族自治县",
        children: [
          { value: "469025100@@牙叉镇", label: "牙叉镇" },
          { value: "469025101@@七坊镇", label: "七坊镇" },
          { value: "469025102@@邦溪镇", label: "邦溪镇" },
          { value: "469025103@@打安镇", label: "打安镇" },
          { value: "469025200@@细水乡", label: "细水乡" },
          { value: "469025201@@元门乡", label: "元门乡" },
          { value: "469025202@@南开乡", label: "南开乡" },
          { value: "469025203@@阜龙乡", label: "阜龙乡" },
          { value: "469025204@@青松乡", label: "青松乡" },
          { value: "469025205@@金波乡", label: "金波乡" },
          { value: "469025206@@荣邦乡", label: "荣邦乡" },
          { value: "469025401@@国营白沙农场", label: "国营白沙农场" },
          { value: "469025404@@国营龙江农场", label: "国营龙江农场" },
          { value: "469025408@@国营邦溪农场", label: "国营邦溪农场" },
        ],
      },
      {
        value: "469031@@昌江黎族自治县",
        label: "昌江黎族自治县",
        children: [
          { value: "469026100@@石碌镇", label: "石碌镇" },
          { value: "469026101@@叉河镇", label: "叉河镇" },
          { value: "469026102@@十月田镇", label: "十月田镇" },
          { value: "469026103@@乌烈镇", label: "乌烈镇" },
          { value: "469026104@@昌化镇", label: "昌化镇" },
          { value: "469026105@@海尾镇", label: "海尾镇" },
          { value: "469026106@@七叉镇", label: "七叉镇" },
          { value: "469026200@@王下乡", label: "王下乡" },
          { value: "469026401@@国营红林农场", label: "国营红林农场" },
          { value: "469026500@@国营霸王岭林场", label: "国营霸王岭林场" },
          {
            value: "469026501@@海南矿业联合有限公司",
            label: "海南矿业联合有限公司",
          },
        ],
      },
      {
        value: "469033@@乐东黎族自治县",
        label: "乐东黎族自治县",
        children: [
          { value: "469027100@@抱由镇", label: "抱由镇" },
          { value: "469027101@@万冲镇", label: "万冲镇" },
          { value: "469027102@@大安镇", label: "大安镇" },
          { value: "469027103@@志仲镇", label: "志仲镇" },
          { value: "469027104@@千家镇", label: "千家镇" },
          { value: "469027105@@九所镇", label: "九所镇" },
          { value: "469027106@@利国镇", label: "利国镇" },
          { value: "469027107@@黄流镇", label: "黄流镇" },
          { value: "469027108@@佛罗镇", label: "佛罗镇" },
          { value: "469027109@@尖峰镇", label: "尖峰镇" },
          { value: "469027110@@莺歌海镇", label: "莺歌海镇" },
          { value: "469027401@@国营山荣农场", label: "国营山荣农场" },
          { value: "469027402@@国营乐光农场", label: "国营乐光农场" },
          { value: "469027405@@国营保国农场", label: "国营保国农场" },
          {
            value: "469027500@@国营尖峰岭林业公司",
            label: "国营尖峰岭林业公司",
          },
          { value: "469027501@@国营莺歌海盐场", label: "国营莺歌海盐场" },
        ],
      },
      {
        value: "469034@@陵水黎族自治县",
        label: "陵水黎族自治县",
        children: [
          { value: "469028100@@椰林镇", label: "椰林镇" },
          { value: "469028101@@光坡镇", label: "光坡镇" },
          { value: "469028102@@三才镇", label: "三才镇" },
          { value: "469028103@@英州镇", label: "英州镇" },
          { value: "469028104@@隆广镇", label: "隆广镇" },
          { value: "469028105@@文罗镇", label: "文罗镇" },
          { value: "469028106@@本号镇", label: "本号镇" },
          { value: "469028107@@新村镇", label: "新村镇" },
          { value: "469028108@@黎安镇", label: "黎安镇" },
          { value: "469028200@@提蒙乡", label: "提蒙乡" },
          { value: "469028201@@群英乡", label: "群英乡" },
          { value: "469028400@@国营岭门农场", label: "国营岭门农场" },
          { value: "469028401@@国营南平农场", label: "国营南平农场" },
          {
            value: "469028500@@国营吊罗山林业公司",
            label: "国营吊罗山林业公司",
          },
        ],
      },
      {
        value: "469035@@保亭黎族苗族自治县",
        label: "保亭黎族苗族自治县",
        children: [
          { value: "469029100@@保城镇", label: "保城镇" },
          { value: "469029101@@什玲镇", label: "什玲镇" },
          { value: "469029102@@加茂镇", label: "加茂镇" },
          { value: "469029103@@响水镇", label: "响水镇" },
          { value: "469029104@@新政镇", label: "新政镇" },
          { value: "469029105@@三道镇", label: "三道镇" },
          { value: "469029200@@六弓乡", label: "六弓乡" },
          { value: "469029201@@南林乡", label: "南林乡" },
          { value: "469029202@@毛感乡", label: "毛感乡" },
          { value: "469029401@@国营新星农场", label: "国营新星农场" },
          {
            value: "469029402@@海南保亭热带作物研究所",
            label: "海南保亭热带作物研究所",
          },
          { value: "469029403@@国营金江农场", label: "国营金江农场" },
          { value: "469029405@@国营三道农场", label: "国营三道农场" },
        ],
      },
      {
        value: "469036@@琼中黎族苗族自治县",
        label: "琼中黎族苗族自治县",
        children: [
          { value: "469030100@@营根镇", label: "营根镇" },
          { value: "469030101@@湾岭镇", label: "湾岭镇" },
          { value: "469030102@@黎母山镇", label: "黎母山镇" },
          { value: "469030103@@和平镇", label: "和平镇" },
          { value: "469030104@@长征镇", label: "长征镇" },
          { value: "469030105@@红毛镇", label: "红毛镇" },
          { value: "469030106@@中平镇", label: "中平镇" },
          { value: "469030200@@吊罗山乡", label: "吊罗山乡" },
          { value: "469030201@@上安乡", label: "上安乡" },
          { value: "469030202@@什运乡", label: "什运乡" },
          { value: "469030402@@国营阳江农场", label: "国营阳江农场" },
          { value: "469030403@@国营乌石农场", label: "国营乌石农场" },
          { value: "469030406@@国营加钗农场", label: "国营加钗农场" },
          { value: "469030407@@国营长征农场", label: "国营长征农场" },
          {
            value: "469030500@@国营黎母山林业公司",
            label: "国营黎母山林业公司",
          },
        ],
      },
      {
        value: "469037@@西沙群岛",
        label: "西沙群岛",
        children: [{ value: "469037@@西沙群岛", label: "西沙群岛" }],
      },
      {
        value: "469038@@南沙群岛",
        label: "南沙群岛",
        children: [{ value: "469038@@南沙群岛", label: "南沙群岛" }],
      },
      {
        value: "469039@@中沙群岛的岛礁及其海域",
        label: "中沙群岛的岛礁及其海域",
        children: [
          {
            value: "469039@@中沙群岛的岛礁及其海域",
            label: "中沙群岛的岛礁及其海域",
          },
        ],
      },
    ],
  },
  {
    value: "500000@@重庆",
    label: "重庆",
    children: [
      {
        value: "500100@@重庆市",
        label: "重庆市",
        children: [
          { value: "500101@@万州区", label: "万州区" },
          { value: "500102@@涪陵区", label: "涪陵区" },
          { value: "500103@@渝中区", label: "渝中区" },
          { value: "500104@@大渡口区", label: "大渡口区" },
          { value: "500105@@江北区", label: "江北区" },
          { value: "500106@@沙坪坝区", label: "沙坪坝区" },
          { value: "500107@@九龙坡区", label: "九龙坡区" },
          { value: "500108@@南岸区", label: "南岸区" },
          { value: "500109@@北碚区", label: "北碚区" },
          { value: "500110@@万盛区", label: "万盛区" },
          { value: "500111@@双桥区", label: "双桥区" },
          { value: "500112@@渝北区", label: "渝北区" },
          { value: "500113@@巴南区", label: "巴南区" },
          { value: "500114@@黔江区", label: "黔江区" },
          { value: "500115@@长寿区", label: "长寿区" },
          { value: "500222@@綦江县", label: "綦江县" },
          { value: "500223@@潼南县", label: "潼南县" },
          { value: "500224@@铜梁县", label: "铜梁县" },
          { value: "500225@@大足县", label: "大足县" },
          { value: "500226@@荣昌县", label: "荣昌县" },
          { value: "500227@@璧山县", label: "璧山县" },
          { value: "500228@@梁平县", label: "梁平县" },
          { value: "500229@@城口县", label: "城口县" },
          { value: "500230@@丰都县", label: "丰都县" },
          { value: "500231@@垫江县", label: "垫江县" },
          { value: "500232@@武隆县", label: "武隆县" },
          { value: "500233@@忠县", label: "忠县" },
          { value: "500234@@开州", label: "开州" },
          { value: "500235@@云阳县", label: "云阳县" },
          { value: "500236@@奉节县", label: "奉节县" },
          { value: "500237@@巫山县", label: "巫山县" },
          { value: "500238@@巫溪县", label: "巫溪县" },
          { value: "500240@@石柱土家族自治县", label: "石柱土家族自治县" },
          {
            value: "500241@@秀山土家族苗族自治县",
            label: "秀山土家族苗族自治县",
          },
          {
            value: "500242@@酉阳土家族苗族自治县",
            label: "酉阳土家族苗族自治县",
          },
          {
            value: "500243@@彭水苗族土家族自治县",
            label: "彭水苗族土家族自治县",
          },
          { value: "500381@@江津区", label: "江津区" },
          { value: "500382@@合川区", label: "合川区" },
          { value: "500383@@永川区", label: "永川区" },
          { value: "500384@@南川区", label: "南川区" },
          { value: "500385@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "510000@@四川",
    label: "四川",
    children: [
      {
        value: "510100@@成都市",
        label: "成都市",
        children: [
          { value: "510104@@锦江区", label: "锦江区" },
          { value: "510105@@青羊区", label: "青羊区" },
          { value: "510106@@金牛区", label: "金牛区" },
          { value: "510107@@武侯区", label: "武侯区" },
          { value: "510108@@成华区", label: "成华区" },
          { value: "510112@@龙泉驿区", label: "龙泉驿区" },
          { value: "510113@@青白江区", label: "青白江区" },
          { value: "510114@@新都区", label: "新都区" },
          { value: "510115@@温江区", label: "温江区" },
          { value: "510121@@金堂县", label: "金堂县" },
          { value: "510122@@双流县", label: "双流县" },
          { value: "510124@@郫县", label: "郫县" },
          { value: "510129@@大邑县", label: "大邑县" },
          { value: "510131@@蒲江县", label: "蒲江县" },
          { value: "510132@@新津县", label: "新津县" },
          { value: "510181@@都江堰市", label: "都江堰市" },
          { value: "510182@@彭州市", label: "彭州市" },
          { value: "510183@@邛崃市", label: "邛崃市" },
          { value: "510184@@崇州市", label: "崇州市" },
          { value: "510185@@其它区", label: "其它区" },
        ],
      },
      {
        value: "510300@@自贡市",
        label: "自贡市",
        children: [
          { value: "510302@@自流井区", label: "自流井区" },
          { value: "510303@@贡井区", label: "贡井区" },
          { value: "510304@@大安区", label: "大安区" },
          { value: "510311@@沿滩区", label: "沿滩区" },
          { value: "510321@@荣县", label: "荣县" },
          { value: "510322@@富顺县", label: "富顺县" },
          { value: "510323@@其它区", label: "其它区" },
        ],
      },
      {
        value: "510400@@攀枝花市",
        label: "攀枝花市",
        children: [
          { value: "510402@@东区", label: "东区" },
          { value: "510403@@西区", label: "西区" },
          { value: "510411@@仁和区", label: "仁和区" },
          { value: "510421@@米易县", label: "米易县" },
          { value: "510422@@盐边县", label: "盐边县" },
          { value: "510423@@其它区", label: "其它区" },
        ],
      },
      {
        value: "510500@@泸州市",
        label: "泸州市",
        children: [
          { value: "510502@@江阳区", label: "江阳区" },
          { value: "510503@@纳溪区", label: "纳溪区" },
          { value: "510504@@龙马潭区", label: "龙马潭区" },
          { value: "510521@@泸县", label: "泸县" },
          { value: "510522@@合江县", label: "合江县" },
          { value: "510524@@叙永县", label: "叙永县" },
          { value: "510525@@古蔺县", label: "古蔺县" },
          { value: "510526@@其它区", label: "其它区" },
        ],
      },
      {
        value: "510600@@德阳市",
        label: "德阳市",
        children: [
          { value: "510603@@旌阳区", label: "旌阳区" },
          { value: "510623@@中江县", label: "中江县" },
          { value: "510626@@罗江县", label: "罗江县" },
          { value: "510681@@广汉市", label: "广汉市" },
          { value: "510682@@什邡市", label: "什邡市" },
          { value: "510683@@绵竹市", label: "绵竹市" },
          { value: "510684@@其它区", label: "其它区" },
        ],
      },
      {
        value: "510700@@绵阳市",
        label: "绵阳市",
        children: [
          { value: "510703@@涪城区", label: "涪城区" },
          { value: "510704@@游仙区", label: "游仙区" },
          { value: "510722@@三台县", label: "三台县" },
          { value: "510723@@盐亭县", label: "盐亭县" },
          { value: "510724@@安州", label: "安州" },
          { value: "510725@@梓潼县", label: "梓潼县" },
          { value: "510726@@北川羌族自治县", label: "北川羌族自治县" },
          { value: "510727@@平武县", label: "平武县" },
          { value: "510751@@高新区", label: "高新区" },
          { value: "510781@@江油市", label: "江油市" },
          { value: "510782@@其它区", label: "其它区" },
        ],
      },
      {
        value: "510800@@广元市",
        label: "广元市",
        children: [
          { value: "510802@@利州区", label: "利州区" },
          { value: "510811@@昭化", label: "昭化" },
          { value: "510812@@朝天区", label: "朝天区" },
          { value: "510821@@旺苍县", label: "旺苍县" },
          { value: "510822@@青川县", label: "青川县" },
          { value: "510823@@剑阁县", label: "剑阁县" },
          { value: "510824@@苍溪县", label: "苍溪县" },
          { value: "510825@@其它区", label: "其它区" },
        ],
      },
      {
        value: "510900@@遂宁市",
        label: "遂宁市",
        children: [
          { value: "510903@@船山区", label: "船山区" },
          { value: "510904@@安居区", label: "安居区" },
          { value: "510921@@蓬溪县", label: "蓬溪县" },
          { value: "510922@@射洪县", label: "射洪县" },
          { value: "510923@@大英县", label: "大英县" },
          { value: "510924@@其它区", label: "其它区" },
        ],
      },
      {
        value: "511000@@内江市",
        label: "内江市",
        children: [
          { value: "511002@@市中区", label: "市中区" },
          { value: "511011@@东兴区", label: "东兴区" },
          { value: "511024@@威远县", label: "威远县" },
          { value: "511025@@资中县", label: "资中县" },
          { value: "511028@@隆昌县", label: "隆昌县" },
          { value: "511029@@其它区", label: "其它区" },
        ],
      },
      {
        value: "511100@@乐山市",
        label: "乐山市",
        children: [
          { value: "511102@@市中区", label: "市中区" },
          { value: "511111@@沙湾区", label: "沙湾区" },
          { value: "511112@@五通桥区", label: "五通桥区" },
          { value: "511113@@金口河区", label: "金口河区" },
          { value: "511123@@犍为县", label: "犍为县" },
          { value: "511124@@井研县", label: "井研县" },
          { value: "511126@@夹江县", label: "夹江县" },
          { value: "511129@@沐川县", label: "沐川县" },
          { value: "511132@@峨边彝族自治县", label: "峨边彝族自治县" },
          { value: "511133@@马边彝族自治县", label: "马边彝族自治县" },
          { value: "511181@@峨眉山市", label: "峨眉山市" },
          { value: "511182@@其它区", label: "其它区" },
        ],
      },
      {
        value: "511300@@南充市",
        label: "南充市",
        children: [
          { value: "511302@@顺庆区", label: "顺庆区" },
          { value: "511303@@高坪区", label: "高坪区" },
          { value: "511304@@嘉陵区", label: "嘉陵区" },
          { value: "511321@@南部县", label: "南部县" },
          { value: "511322@@营山县", label: "营山县" },
          { value: "511323@@蓬安县", label: "蓬安县" },
          { value: "511324@@仪陇县", label: "仪陇县" },
          { value: "511325@@西充县", label: "西充县" },
          { value: "511381@@阆中市", label: "阆中市" },
          { value: "511382@@其它区", label: "其它区" },
        ],
      },
      {
        value: "511400@@眉山市",
        label: "眉山市",
        children: [
          { value: "511402@@东坡区", label: "东坡区" },
          { value: "511421@@仁寿县", label: "仁寿县" },
          { value: "511422@@彭山县", label: "彭山县" },
          { value: "511423@@洪雅县", label: "洪雅县" },
          { value: "511424@@丹棱县", label: "丹棱县" },
          { value: "511425@@青神县", label: "青神县" },
          { value: "511426@@其它区", label: "其它区" },
        ],
      },
      {
        value: "511500@@宜宾市",
        label: "宜宾市",
        children: [
          { value: "511502@@翠屏区", label: "翠屏区" },
          { value: "511521@@宜宾县", label: "宜宾县" },
          { value: "511522@@南溪县", label: "南溪县" },
          { value: "511523@@江安县", label: "江安县" },
          { value: "511524@@长宁县", label: "长宁县" },
          { value: "511525@@高县", label: "高县" },
          { value: "511526@@珙县", label: "珙县" },
          { value: "511527@@筠连县", label: "筠连县" },
          { value: "511528@@兴文县", label: "兴文县" },
          { value: "511529@@屏山县", label: "屏山县" },
          { value: "511530@@其它区", label: "其它区" },
        ],
      },
      {
        value: "511600@@广安市",
        label: "广安市",
        children: [
          { value: "511602@@广安区", label: "广安区" },
          { value: "511621@@岳池县", label: "岳池县" },
          { value: "511622@@武胜县", label: "武胜县" },
          { value: "511623@@邻水县", label: "邻水县" },
          { value: "511681@@华蓥市", label: "华蓥市" },
          { value: "511682@@市辖区", label: "市辖区" },
          { value: "511683@@其它区", label: "其它区" },
        ],
      },
      {
        value: "511700@@达州市",
        label: "达州市",
        children: [
          { value: "511702@@通川区", label: "通川区" },
          { value: "511721@@达川", label: "达川" },
          { value: "511722@@宣汉县", label: "宣汉县" },
          { value: "511723@@开江县", label: "开江县" },
          { value: "511724@@大竹县", label: "大竹县" },
          { value: "511725@@渠县", label: "渠县" },
          { value: "511781@@万源市", label: "万源市" },
          { value: "511782@@其它区", label: "其它区" },
        ],
      },
      {
        value: "511800@@雅安市",
        label: "雅安市",
        children: [
          { value: "511802@@雨城区", label: "雨城区" },
          { value: "511821@@名山县", label: "名山县" },
          { value: "511822@@荥经县", label: "荥经县" },
          { value: "511823@@汉源县", label: "汉源县" },
          { value: "511824@@石棉县", label: "石棉县" },
          { value: "511825@@天全县", label: "天全县" },
          { value: "511826@@芦山县", label: "芦山县" },
          { value: "511827@@宝兴县", label: "宝兴县" },
          { value: "511828@@其它区", label: "其它区" },
        ],
      },
      {
        value: "511900@@巴中市",
        label: "巴中市",
        children: [
          { value: "511902@@巴州区", label: "巴州区" },
          { value: "511921@@通江县", label: "通江县" },
          { value: "511922@@南江县", label: "南江县" },
          { value: "511923@@平昌县", label: "平昌县" },
          { value: "511924@@其它区", label: "其它区" },
        ],
      },
      {
        value: "512000@@资阳市",
        label: "资阳市",
        children: [
          { value: "512002@@雁江区", label: "雁江区" },
          { value: "512021@@安岳县", label: "安岳县" },
          { value: "512022@@乐至县", label: "乐至县" },
          { value: "512081@@简阳市", label: "简阳市" },
          { value: "512082@@其它区", label: "其它区" },
        ],
      },
      {
        value: "513200@@阿坝藏族羌族自治州",
        label: "阿坝藏族羌族自治州",
        children: [
          { value: "513221@@汶川县", label: "汶川县" },
          { value: "513222@@理县", label: "理县" },
          { value: "513223@@茂县", label: "茂县" },
          { value: "513224@@松潘县", label: "松潘县" },
          { value: "513225@@九寨沟县", label: "九寨沟县" },
          { value: "513226@@金川县", label: "金川县" },
          { value: "513227@@小金县", label: "小金县" },
          { value: "513228@@黑水县", label: "黑水县" },
          { value: "513229@@马尔康县", label: "马尔康县" },
          { value: "513230@@壤塘县", label: "壤塘县" },
          { value: "513231@@阿坝县", label: "阿坝县" },
          { value: "513232@@若尔盖县", label: "若尔盖县" },
          { value: "513233@@红原县", label: "红原县" },
          { value: "513234@@其它区", label: "其它区" },
        ],
      },
      {
        value: "513300@@甘孜藏族自治州",
        label: "甘孜藏族自治州",
        children: [
          { value: "513321@@康定县", label: "康定县" },
          { value: "513322@@泸定县", label: "泸定县" },
          { value: "513323@@丹巴县", label: "丹巴县" },
          { value: "513324@@九龙县", label: "九龙县" },
          { value: "513325@@雅江县", label: "雅江县" },
          { value: "513326@@道孚县", label: "道孚县" },
          { value: "513327@@炉霍县", label: "炉霍县" },
          { value: "513328@@甘孜县", label: "甘孜县" },
          { value: "513329@@新龙县", label: "新龙县" },
          { value: "513330@@德格县", label: "德格县" },
          { value: "513331@@白玉县", label: "白玉县" },
          { value: "513332@@石渠县", label: "石渠县" },
          { value: "513333@@色达县", label: "色达县" },
          { value: "513334@@理塘县", label: "理塘县" },
          { value: "513335@@巴塘县", label: "巴塘县" },
          { value: "513336@@乡城县", label: "乡城县" },
          { value: "513337@@稻城县", label: "稻城县" },
          { value: "513338@@得荣县", label: "得荣县" },
          { value: "513339@@其它区", label: "其它区" },
        ],
      },
      {
        value: "513400@@凉山彝族自治州",
        label: "凉山彝族自治州",
        children: [
          { value: "513401@@西昌市", label: "西昌市" },
          { value: "513422@@木里藏族自治县", label: "木里藏族自治县" },
          { value: "513423@@盐源县", label: "盐源县" },
          { value: "513424@@德昌县", label: "德昌县" },
          { value: "513425@@会理县", label: "会理县" },
          { value: "513426@@会东县", label: "会东县" },
          { value: "513427@@宁南县", label: "宁南县" },
          { value: "513428@@普格县", label: "普格县" },
          { value: "513429@@布拖县", label: "布拖县" },
          { value: "513430@@金阳县", label: "金阳县" },
          { value: "513431@@昭觉县", label: "昭觉县" },
          { value: "513432@@喜德县", label: "喜德县" },
          { value: "513433@@冕宁县", label: "冕宁县" },
          { value: "513434@@越西县", label: "越西县" },
          { value: "513435@@甘洛县", label: "甘洛县" },
          { value: "513436@@美姑县", label: "美姑县" },
          { value: "513437@@雷波县", label: "雷波县" },
          { value: "513438@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "520000@@贵州",
    label: "贵州",
    children: [
      {
        value: "520100@@贵阳市",
        label: "贵阳市",
        children: [
          { value: "520102@@南明区", label: "南明区" },
          { value: "520103@@云岩区", label: "云岩区" },
          { value: "520111@@花溪区", label: "花溪区" },
          { value: "520112@@乌当区", label: "乌当区" },
          { value: "520113@@白云区", label: "白云区" },
          { value: "520114@@小河区", label: "小河区" },
          { value: "520121@@开阳县", label: "开阳县" },
          { value: "520122@@息烽县", label: "息烽县" },
          { value: "520123@@修文县", label: "修文县" },
          { value: "520151@@观山湖", label: "观山湖" },
          { value: "520181@@清镇市", label: "清镇市" },
          { value: "520182@@其它区", label: "其它区" },
        ],
      },
      {
        value: "520200@@六盘水市",
        label: "六盘水市",
        children: [
          { value: "520201@@钟山区", label: "钟山区" },
          { value: "520203@@六枝特区", label: "六枝特区" },
          { value: "520221@@水城县", label: "水城县" },
          { value: "520222@@盘县", label: "盘县" },
          { value: "520223@@其它区", label: "其它区" },
        ],
      },
      {
        value: "520300@@遵义市",
        label: "遵义市",
        children: [
          { value: "520302@@红花岗区", label: "红花岗区" },
          { value: "520303@@汇川区", label: "汇川区" },
          { value: "520321@@播州", label: "播州" },
          { value: "520322@@桐梓县", label: "桐梓县" },
          { value: "520323@@绥阳县", label: "绥阳县" },
          { value: "520324@@正安县", label: "正安县" },
          {
            value: "520325@@道真仡佬族苗族自治县",
            label: "道真仡佬族苗族自治县",
          },
          {
            value: "520326@@务川仡佬族苗族自治县",
            label: "务川仡佬族苗族自治县",
          },
          { value: "520327@@凤冈县", label: "凤冈县" },
          { value: "520328@@湄潭县", label: "湄潭县" },
          { value: "520329@@余庆县", label: "余庆县" },
          { value: "520330@@习水县", label: "习水县" },
          { value: "520381@@赤水市", label: "赤水市" },
          { value: "520382@@仁怀市", label: "仁怀市" },
          { value: "520383@@其它区", label: "其它区" },
        ],
      },
      {
        value: "520400@@安顺市",
        label: "安顺市",
        children: [
          { value: "520402@@西秀区", label: "西秀区" },
          { value: "520421@@平坝县", label: "平坝县" },
          { value: "520422@@普定县", label: "普定县" },
          {
            value: "520423@@镇宁布依族苗族自治县",
            label: "镇宁布依族苗族自治县",
          },
          {
            value: "520424@@关岭布依族苗族自治县",
            label: "关岭布依族苗族自治县",
          },
          {
            value: "520425@@紫云苗族布依族自治县",
            label: "紫云苗族布依族自治县",
          },
          { value: "520426@@其它区", label: "其它区" },
        ],
      },
      {
        value: "522200@@铜仁地区",
        label: "铜仁地区",
        children: [
          { value: "522201@@碧江", label: "碧江" },
          { value: "522222@@江口县", label: "江口县" },
          { value: "522223@@玉屏侗族自治县", label: "玉屏侗族自治县" },
          { value: "522224@@石阡县", label: "石阡县" },
          { value: "522225@@思南县", label: "思南县" },
          {
            value: "522226@@印江土家族苗族自治县",
            label: "印江土家族苗族自治县",
          },
          { value: "522227@@德江县", label: "德江县" },
          { value: "522228@@沿河土家族自治县", label: "沿河土家族自治县" },
          { value: "522229@@松桃苗族自治县", label: "松桃苗族自治县" },
          { value: "522230@@万山特区", label: "万山特区" },
          { value: "522231@@其它区", label: "其它区" },
        ],
      },
      {
        value: "522300@@黔西南布依族苗族自治州",
        label: "黔西南布依族苗族自治州",
        children: [
          { value: "522301@@兴义市", label: "兴义市" },
          { value: "522322@@兴仁县", label: "兴仁县" },
          { value: "522323@@普安县", label: "普安县" },
          { value: "522324@@晴隆县", label: "晴隆县" },
          { value: "522325@@贞丰县", label: "贞丰县" },
          { value: "522326@@望谟县", label: "望谟县" },
          { value: "522327@@册亨县", label: "册亨县" },
          { value: "522328@@安龙县", label: "安龙县" },
          { value: "522329@@其它区", label: "其它区" },
        ],
      },
      {
        value: "522400@@毕节地区",
        label: "毕节地区",
        children: [
          { value: "522401@@七星关", label: "七星关" },
          { value: "522422@@大方县", label: "大方县" },
          { value: "522423@@黔西县", label: "黔西县" },
          { value: "522424@@金沙县", label: "金沙县" },
          { value: "522425@@织金县", label: "织金县" },
          { value: "522426@@纳雍县", label: "纳雍县" },
          {
            value: "522427@@威宁彝族回族苗族自治县",
            label: "威宁彝族回族苗族自治县",
          },
          { value: "522428@@赫章县", label: "赫章县" },
          { value: "522429@@其它区", label: "其它区" },
        ],
      },
      {
        value: "522600@@黔东南苗族侗族自治州",
        label: "黔东南苗族侗族自治州",
        children: [
          { value: "522601@@凯里市", label: "凯里市" },
          { value: "522622@@黄平县", label: "黄平县" },
          { value: "522623@@施秉县", label: "施秉县" },
          { value: "522624@@三穗县", label: "三穗县" },
          { value: "522625@@镇远县", label: "镇远县" },
          { value: "522626@@岑巩县", label: "岑巩县" },
          { value: "522627@@天柱县", label: "天柱县" },
          { value: "522628@@锦屏县", label: "锦屏县" },
          { value: "522629@@剑河县", label: "剑河县" },
          { value: "522630@@台江县", label: "台江县" },
          { value: "522631@@黎平县", label: "黎平县" },
          { value: "522632@@榕江县", label: "榕江县" },
          { value: "522633@@从江县", label: "从江县" },
          { value: "522634@@雷山县", label: "雷山县" },
          { value: "522635@@麻江县", label: "麻江县" },
          { value: "522636@@丹寨县", label: "丹寨县" },
          { value: "522637@@其它区", label: "其它区" },
        ],
      },
      {
        value: "522700@@黔南布依族苗族自治州",
        label: "黔南布依族苗族自治州",
        children: [
          { value: "522701@@都匀市", label: "都匀市" },
          { value: "522702@@福泉市", label: "福泉市" },
          { value: "522722@@荔波县", label: "荔波县" },
          { value: "522723@@贵定县", label: "贵定县" },
          { value: "522725@@瓮安县", label: "瓮安县" },
          { value: "522726@@独山县", label: "独山县" },
          { value: "522727@@平塘县", label: "平塘县" },
          { value: "522728@@罗甸县", label: "罗甸县" },
          { value: "522729@@长顺县", label: "长顺县" },
          { value: "522730@@龙里县", label: "龙里县" },
          { value: "522731@@惠水县", label: "惠水县" },
          { value: "522732@@三都水族自治县", label: "三都水族自治县" },
          { value: "522733@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "530000@@云南",
    label: "云南",
    children: [
      {
        value: "530100@@昆明市",
        label: "昆明市",
        children: [
          { value: "530102@@五华区", label: "五华区" },
          { value: "530103@@盘龙区", label: "盘龙区" },
          { value: "530111@@官渡区", label: "官渡区" },
          { value: "530112@@西山区", label: "西山区" },
          { value: "530113@@东川区", label: "东川区" },
          { value: "530121@@呈贡县", label: "呈贡县" },
          { value: "530122@@晋宁县", label: "晋宁县" },
          { value: "530124@@富民县", label: "富民县" },
          { value: "530125@@宜良县", label: "宜良县" },
          { value: "530126@@石林彝族自治县", label: "石林彝族自治县" },
          { value: "530127@@嵩明县", label: "嵩明县" },
          {
            value: "530128@@禄劝彝族苗族自治县",
            label: "禄劝彝族苗族自治县",
          },
          {
            value: "530129@@寻甸回族彝族自治县",
            label: "寻甸回族彝族自治县",
          },
          { value: "530181@@安宁市", label: "安宁市" },
          { value: "530182@@其它区", label: "其它区" },
        ],
      },
      {
        value: "530300@@曲靖市",
        label: "曲靖市",
        children: [
          { value: "530302@@麒麟区", label: "麒麟区" },
          { value: "530321@@马龙县", label: "马龙县" },
          { value: "530322@@陆良县", label: "陆良县" },
          { value: "530323@@师宗县", label: "师宗县" },
          { value: "530324@@罗平县", label: "罗平县" },
          { value: "530325@@富源县", label: "富源县" },
          { value: "530326@@会泽县", label: "会泽县" },
          { value: "530328@@沾益县", label: "沾益县" },
          { value: "530381@@宣威市", label: "宣威市" },
          { value: "530382@@其它区", label: "其它区" },
        ],
      },
      {
        value: "530400@@玉溪市",
        label: "玉溪市",
        children: [
          { value: "530402@@红塔区", label: "红塔区" },
          { value: "530421@@江川县", label: "江川县" },
          { value: "530422@@澄江县", label: "澄江县" },
          { value: "530423@@通海县", label: "通海县" },
          { value: "530424@@华宁县", label: "华宁县" },
          { value: "530425@@易门县", label: "易门县" },
          { value: "530426@@峨山彝族自治县", label: "峨山彝族自治县" },
          {
            value: "530427@@新平彝族傣族自治县",
            label: "新平彝族傣族自治县",
          },
          {
            value: "530428@@元江哈尼族彝族傣族自治县",
            label: "元江哈尼族彝族傣族自治县",
          },
          { value: "530429@@其它区", label: "其它区" },
        ],
      },
      {
        value: "530500@@保山市",
        label: "保山市",
        children: [
          { value: "530502@@隆阳区", label: "隆阳区" },
          { value: "530521@@施甸县", label: "施甸县" },
          { value: "530522@@腾冲县", label: "腾冲县" },
          { value: "530523@@龙陵县", label: "龙陵县" },
          { value: "530524@@昌宁县", label: "昌宁县" },
          { value: "530525@@其它区", label: "其它区" },
        ],
      },
      {
        value: "530600@@昭通市",
        label: "昭通市",
        children: [
          { value: "530602@@昭阳区", label: "昭阳区" },
          { value: "530621@@鲁甸县", label: "鲁甸县" },
          { value: "530622@@巧家县", label: "巧家县" },
          { value: "530623@@盐津县", label: "盐津县" },
          { value: "530624@@大关县", label: "大关县" },
          { value: "530625@@永善县", label: "永善县" },
          { value: "530626@@绥江县", label: "绥江县" },
          { value: "530627@@镇雄县", label: "镇雄县" },
          { value: "530628@@彝良县", label: "彝良县" },
          { value: "530629@@威信县", label: "威信县" },
          { value: "530630@@水富县", label: "水富县" },
          { value: "530631@@其它区", label: "其它区" },
        ],
      },
      {
        value: "530700@@丽江市",
        label: "丽江市",
        children: [
          { value: "530702@@古城区", label: "古城区" },
          { value: "530721@@玉龙纳西族自治县", label: "玉龙纳西族自治县" },
          { value: "530722@@永胜县", label: "永胜县" },
          { value: "530723@@华坪县", label: "华坪县" },
          { value: "530724@@宁蒗彝族自治县", label: "宁蒗彝族自治县" },
          { value: "530725@@其它区", label: "其它区" },
        ],
      },
      {
        value: "530800@@普洱市",
        label: "普洱市",
        children: [
          { value: "530802@@思茅区", label: "思茅区" },
          {
            value: "530821@@宁洱哈尼族彝族自治县",
            label: "宁洱哈尼族彝族自治县",
          },
          { value: "530822@@墨江哈尼族自治县", label: "墨江哈尼族自治县" },
          { value: "530823@@景东彝族自治县", label: "景东彝族自治县" },
          {
            value: "530824@@景谷傣族彝族自治县",
            label: "景谷傣族彝族自治县",
          },
          {
            value: "530825@@镇沅彝族哈尼族拉祜族自治县",
            label: "镇沅彝族哈尼族拉祜族自治县",
          },
          {
            value: "530826@@江城哈尼族彝族自治县",
            label: "江城哈尼族彝族自治县",
          },
          {
            value: "530827@@孟连傣族拉祜族佤族自治县",
            label: "孟连傣族拉祜族佤族自治县",
          },
          { value: "530828@@澜沧拉祜族自治县", label: "澜沧拉祜族自治县" },
          { value: "530829@@西盟佤族自治县", label: "西盟佤族自治县" },
          { value: "530830@@其它区", label: "其它区" },
        ],
      },
      {
        value: "530900@@临沧市",
        label: "临沧市",
        children: [
          { value: "530902@@临翔区", label: "临翔区" },
          { value: "530921@@凤庆县", label: "凤庆县" },
          { value: "530922@@云县", label: "云县" },
          { value: "530923@@永德县", label: "永德县" },
          { value: "530924@@镇康县", label: "镇康县" },
          {
            value: "530925@@双江拉祜族佤族布朗族傣族自治县",
            label: "双江拉祜族佤族布朗族傣族自治县",
          },
          {
            value: "530926@@耿马傣族佤族自治县",
            label: "耿马傣族佤族自治县",
          },
          { value: "530927@@沧源佤族自治县", label: "沧源佤族自治县" },
          { value: "530928@@其它区", label: "其它区" },
        ],
      },
      {
        value: "532300@@楚雄彝族自治州",
        label: "楚雄彝族自治州",
        children: [
          { value: "532301@@楚雄市", label: "楚雄市" },
          { value: "532322@@双柏县", label: "双柏县" },
          { value: "532323@@牟定县", label: "牟定县" },
          { value: "532324@@南华县", label: "南华县" },
          { value: "532325@@姚安县", label: "姚安县" },
          { value: "532326@@大姚县", label: "大姚县" },
          { value: "532327@@永仁县", label: "永仁县" },
          { value: "532328@@元谋县", label: "元谋县" },
          { value: "532329@@武定县", label: "武定县" },
          { value: "532331@@禄丰县", label: "禄丰县" },
          { value: "532332@@其它区", label: "其它区" },
        ],
      },
      {
        value: "532500@@红河哈尼族彝族自治州",
        label: "红河哈尼族彝族自治州",
        children: [
          { value: "532501@@个旧市", label: "个旧市" },
          { value: "532502@@开远市", label: "开远市" },
          { value: "532522@@蒙自县", label: "蒙自县" },
          { value: "532523@@屏边苗族自治县", label: "屏边苗族自治县" },
          { value: "532524@@建水县", label: "建水县" },
          { value: "532525@@石屏县", label: "石屏县" },
          { value: "532526@@弥勒县", label: "弥勒县" },
          { value: "532527@@泸西县", label: "泸西县" },
          { value: "532528@@元阳县", label: "元阳县" },
          { value: "532529@@红河县", label: "红河县" },
          {
            value: "532530@@金平苗族瑶族傣族自治县",
            label: "金平苗族瑶族傣族自治县",
          },
          { value: "532531@@绿春县", label: "绿春县" },
          { value: "532532@@河口瑶族自治县", label: "河口瑶族自治县" },
          { value: "532533@@其它区", label: "其它区" },
        ],
      },
      {
        value: "532600@@文山壮族苗族自治州",
        label: "文山壮族苗族自治州",
        children: [
          { value: "532621@@文山县", label: "文山县" },
          { value: "532622@@砚山县", label: "砚山县" },
          { value: "532623@@西畴县", label: "西畴县" },
          { value: "532624@@麻栗坡县", label: "麻栗坡县" },
          { value: "532625@@马关县", label: "马关县" },
          { value: "532626@@丘北县", label: "丘北县" },
          { value: "532627@@广南县", label: "广南县" },
          { value: "532628@@富宁县", label: "富宁县" },
          { value: "532629@@其它区", label: "其它区" },
        ],
      },
      {
        value: "532800@@西双版纳傣族自治州",
        label: "西双版纳傣族自治州",
        children: [
          { value: "532801@@景洪市", label: "景洪市" },
          { value: "532822@@勐海县", label: "勐海县" },
          { value: "532823@@勐腊县", label: "勐腊县" },
          { value: "532824@@其它区", label: "其它区" },
        ],
      },
      {
        value: "532900@@大理白族自治州",
        label: "大理白族自治州",
        children: [
          { value: "532901@@大理市", label: "大理市" },
          { value: "532922@@漾濞彝族自治县", label: "漾濞彝族自治县" },
          { value: "532923@@祥云县", label: "祥云县" },
          { value: "532924@@宾川县", label: "宾川县" },
          { value: "532925@@弥渡县", label: "弥渡县" },
          { value: "532926@@南涧彝族自治县", label: "南涧彝族自治县" },
          {
            value: "532927@@巍山彝族回族自治县",
            label: "巍山彝族回族自治县",
          },
          { value: "532928@@永平县", label: "永平县" },
          { value: "532929@@云龙县", label: "云龙县" },
          { value: "532930@@洱源县", label: "洱源县" },
          { value: "532931@@剑川县", label: "剑川县" },
          { value: "532932@@鹤庆县", label: "鹤庆县" },
          { value: "532933@@其它区", label: "其它区" },
        ],
      },
      {
        value: "533100@@德宏傣族景颇族自治州",
        label: "德宏傣族景颇族自治州",
        children: [
          { value: "533102@@瑞丽市", label: "瑞丽市" },
          { value: "533103@@芒市", label: "芒市" },
          { value: "533122@@梁河县", label: "梁河县" },
          { value: "533123@@盈江县", label: "盈江县" },
          { value: "533124@@陇川县", label: "陇川县" },
          { value: "533125@@其它区", label: "其它区" },
        ],
      },
      {
        value: "533300@@怒江傈僳族自治州",
        label: "怒江傈僳族自治州",
        children: [
          { value: "533321@@泸水县", label: "泸水县" },
          { value: "533323@@福贡县", label: "福贡县" },
          {
            value: "533324@@贡山独龙族怒族自治县",
            label: "贡山独龙族怒族自治县",
          },
          {
            value: "533325@@兰坪白族普米族自治县",
            label: "兰坪白族普米族自治县",
          },
          { value: "533326@@其它区", label: "其它区" },
        ],
      },
      {
        value: "533400@@迪庆藏族自治州",
        label: "迪庆藏族自治州",
        children: [
          { value: "533421@@香格里拉县", label: "香格里拉县" },
          { value: "533422@@德钦县", label: "德钦县" },
          { value: "533423@@维西傈僳族自治县", label: "维西傈僳族自治县" },
          { value: "533424@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "540000@@西藏",
    label: "西藏",
    children: [
      {
        value: "540100@@拉萨市",
        label: "拉萨市",
        children: [
          { value: "540102@@城关区", label: "城关区" },
          { value: "540121@@林周县", label: "林周县" },
          { value: "540122@@当雄县", label: "当雄县" },
          { value: "540123@@尼木县", label: "尼木县" },
          { value: "540124@@曲水县", label: "曲水县" },
          { value: "540125@@堆龙德庆县", label: "堆龙德庆县" },
          { value: "540126@@达孜县", label: "达孜县" },
          { value: "540127@@墨竹工卡县", label: "墨竹工卡县" },
          { value: "540128@@其它区", label: "其它区" },
        ],
      },
      {
        value: "542100@@昌都地区",
        label: "昌都地区",
        children: [
          { value: "542121@@卡若", label: "卡若" },
          { value: "542122@@江达县", label: "江达县" },
          { value: "542123@@贡觉县", label: "贡觉县" },
          { value: "542124@@类乌齐县", label: "类乌齐县" },
          { value: "542125@@丁青县", label: "丁青县" },
          { value: "542126@@察雅县", label: "察雅县" },
          { value: "542127@@八宿县", label: "八宿县" },
          { value: "542128@@左贡县", label: "左贡县" },
          { value: "542129@@芒康县", label: "芒康县" },
          { value: "542132@@洛隆县", label: "洛隆县" },
          { value: "542133@@边坝县", label: "边坝县" },
          { value: "542134@@其它区", label: "其它区" },
        ],
      },
      {
        value: "542200@@山南地区",
        label: "山南地区",
        children: [
          { value: "542221@@乃东县", label: "乃东县" },
          { value: "542222@@扎囊县", label: "扎囊县" },
          { value: "542223@@贡嘎县", label: "贡嘎县" },
          { value: "542224@@桑日县", label: "桑日县" },
          { value: "542225@@琼结县", label: "琼结县" },
          { value: "542226@@曲松县", label: "曲松县" },
          { value: "542227@@措美县", label: "措美县" },
          { value: "542228@@洛扎县", label: "洛扎县" },
          { value: "542229@@加查县", label: "加查县" },
          { value: "542231@@隆子县", label: "隆子县" },
          { value: "542232@@错那县", label: "错那县" },
          { value: "542233@@浪卡子县", label: "浪卡子县" },
          { value: "542234@@其它区", label: "其它区" },
        ],
      },
      {
        value: "542300@@日喀则地区",
        label: "日喀则地区",
        children: [
          { value: "542301@@桑珠孜", label: "桑珠孜" },
          { value: "542322@@南木林县", label: "南木林县" },
          { value: "542323@@江孜县", label: "江孜县" },
          { value: "542324@@定日县", label: "定日县" },
          { value: "542325@@萨迦县", label: "萨迦县" },
          { value: "542326@@拉孜县", label: "拉孜县" },
          { value: "542327@@昂仁县", label: "昂仁县" },
          { value: "542328@@谢通门县", label: "谢通门县" },
          { value: "542329@@白朗县", label: "白朗县" },
          { value: "542330@@仁布县", label: "仁布县" },
          { value: "542331@@康马县", label: "康马县" },
          { value: "542332@@定结县", label: "定结县" },
          { value: "542333@@仲巴县", label: "仲巴县" },
          { value: "542334@@亚东县", label: "亚东县" },
          { value: "542335@@吉隆县", label: "吉隆县" },
          { value: "542336@@聂拉木县", label: "聂拉木县" },
          { value: "542337@@萨嘎县", label: "萨嘎县" },
          { value: "542338@@岗巴县", label: "岗巴县" },
          { value: "542339@@其它区", label: "其它区" },
        ],
      },
      {
        value: "542400@@那曲地区",
        label: "那曲地区",
        children: [
          { value: "542421@@那曲县", label: "那曲县" },
          { value: "542422@@嘉黎县", label: "嘉黎县" },
          { value: "542423@@比如县", label: "比如县" },
          { value: "542424@@聂荣县", label: "聂荣县" },
          { value: "542425@@安多县", label: "安多县" },
          { value: "542426@@申扎县", label: "申扎县" },
          { value: "542427@@索县", label: "索县" },
          { value: "542428@@班戈县", label: "班戈县" },
          { value: "542429@@巴青县", label: "巴青县" },
          { value: "542430@@尼玛县", label: "尼玛县" },
          { value: "542431@@其它区", label: "其它区" },
        ],
      },
      {
        value: "542500@@阿里地区",
        label: "阿里地区",
        children: [
          { value: "542521@@普兰县", label: "普兰县" },
          { value: "542522@@札达县", label: "札达县" },
          { value: "542523@@噶尔县", label: "噶尔县" },
          { value: "542524@@日土县", label: "日土县" },
          { value: "542525@@革吉县", label: "革吉县" },
          { value: "542526@@改则县", label: "改则县" },
          { value: "542527@@措勤县", label: "措勤县" },
          { value: "542528@@其它区", label: "其它区" },
        ],
      },
      {
        value: "542600@@林芝地区",
        label: "林芝地区",
        children: [
          { value: "542621@@巴宜", label: "巴宜" },
          { value: "542622@@工布江达县", label: "工布江达县" },
          { value: "542623@@米林县", label: "米林县" },
          { value: "542624@@墨脱县", label: "墨脱县" },
          { value: "542625@@波密县", label: "波密县" },
          { value: "542626@@察隅县", label: "察隅县" },
          { value: "542627@@朗县", label: "朗县" },
          { value: "542628@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "610000@@陕西",
    label: "陕西",
    children: [
      {
        value: "610100@@西安市",
        label: "西安市",
        children: [
          { value: "610102@@新城区", label: "新城区" },
          { value: "610103@@碑林区", label: "碑林区" },
          { value: "610104@@莲湖区", label: "莲湖区" },
          { value: "610111@@灞桥区", label: "灞桥区" },
          { value: "610112@@未央区", label: "未央区" },
          { value: "610113@@雁塔区", label: "雁塔区" },
          { value: "610114@@阎良区", label: "阎良区" },
          { value: "610115@@临潼区", label: "临潼区" },
          { value: "610116@@长安区", label: "长安区" },
          { value: "610122@@蓝田县", label: "蓝田县" },
          { value: "610124@@周至县", label: "周至县" },
          { value: "610125@@户县", label: "户县" },
          { value: "610126@@高陵县", label: "高陵县" },
          { value: "610127@@其它区", label: "其它区" },
        ],
      },
      {
        value: "610200@@铜川市",
        label: "铜川市",
        children: [
          { value: "610202@@王益区", label: "王益区" },
          { value: "610203@@印台区", label: "印台区" },
          { value: "610204@@耀州区", label: "耀州区" },
          { value: "610222@@宜君县", label: "宜君县" },
          { value: "610223@@其它区", label: "其它区" },
        ],
      },
      {
        value: "610300@@宝鸡市",
        label: "宝鸡市",
        children: [
          { value: "610302@@渭滨区", label: "渭滨区" },
          { value: "610303@@金台区", label: "金台区" },
          { value: "610304@@陈仓区", label: "陈仓区" },
          { value: "610322@@凤翔县", label: "凤翔县" },
          { value: "610323@@岐山县", label: "岐山县" },
          { value: "610324@@扶风县", label: "扶风县" },
          { value: "610326@@眉县", label: "眉县" },
          { value: "610327@@陇县", label: "陇县" },
          { value: "610328@@千阳县", label: "千阳县" },
          { value: "610329@@麟游县", label: "麟游县" },
          { value: "610330@@凤县", label: "凤县" },
          { value: "610331@@太白县", label: "太白县" },
          { value: "610332@@其它区", label: "其它区" },
        ],
      },
      {
        value: "610400@@咸阳市",
        label: "咸阳市",
        children: [
          { value: "610402@@秦都区", label: "秦都区" },
          { value: "610403@@杨陵", label: "杨陵" },
          { value: "610404@@渭城区", label: "渭城区" },
          { value: "610422@@三原县", label: "三原县" },
          { value: "610423@@泾阳县", label: "泾阳县" },
          { value: "610424@@乾县", label: "乾县" },
          { value: "610425@@礼泉县", label: "礼泉县" },
          { value: "610426@@永寿县", label: "永寿县" },
          { value: "610427@@彬县", label: "彬县" },
          { value: "610428@@长武县", label: "长武县" },
          { value: "610429@@旬邑县", label: "旬邑县" },
          { value: "610430@@淳化县", label: "淳化县" },
          { value: "610431@@武功县", label: "武功县" },
          { value: "610481@@兴平市", label: "兴平市" },
          { value: "610482@@其它区", label: "其它区" },
        ],
      },
      {
        value: "610500@@渭南市",
        label: "渭南市",
        children: [
          { value: "610502@@临渭区", label: "临渭区" },
          { value: "610521@@华州", label: "华州" },
          { value: "610522@@潼关县", label: "潼关县" },
          { value: "610523@@大荔县", label: "大荔县" },
          { value: "610524@@合阳县", label: "合阳县" },
          { value: "610525@@澄城县", label: "澄城县" },
          { value: "610526@@蒲城县", label: "蒲城县" },
          { value: "610527@@白水县", label: "白水县" },
          { value: "610528@@富平县", label: "富平县" },
          { value: "610581@@韩城市", label: "韩城市" },
          { value: "610582@@华阴市", label: "华阴市" },
          { value: "610583@@其它区", label: "其它区" },
        ],
      },
      {
        value: "610600@@延安市",
        label: "延安市",
        children: [
          { value: "610602@@宝塔区", label: "宝塔区" },
          { value: "610621@@延长县", label: "延长县" },
          { value: "610622@@延川县", label: "延川县" },
          { value: "610623@@子长县", label: "子长县" },
          { value: "610624@@安塞县", label: "安塞县" },
          { value: "610625@@志丹县", label: "志丹县" },
          { value: "610626@@吴起县", label: "吴起县" },
          { value: "610627@@甘泉县", label: "甘泉县" },
          { value: "610628@@富县", label: "富县" },
          { value: "610629@@洛川县", label: "洛川县" },
          { value: "610630@@宜川县", label: "宜川县" },
          { value: "610631@@黄龙县", label: "黄龙县" },
          { value: "610632@@黄陵县", label: "黄陵县" },
          { value: "610633@@其它区", label: "其它区" },
        ],
      },
      {
        value: "610700@@汉中市",
        label: "汉中市",
        children: [
          { value: "610702@@汉台区", label: "汉台区" },
          { value: "610721@@南郑县", label: "南郑县" },
          { value: "610722@@城固县", label: "城固县" },
          { value: "610723@@洋县", label: "洋县" },
          { value: "610724@@西乡县", label: "西乡县" },
          { value: "610725@@勉县", label: "勉县" },
          { value: "610726@@宁强县", label: "宁强县" },
          { value: "610727@@略阳县", label: "略阳县" },
          { value: "610728@@镇巴县", label: "镇巴县" },
          { value: "610729@@留坝县", label: "留坝县" },
          { value: "610730@@佛坪县", label: "佛坪县" },
          { value: "610731@@其它区", label: "其它区" },
        ],
      },
      {
        value: "610800@@榆林市",
        label: "榆林市",
        children: [
          { value: "610802@@榆阳区", label: "榆阳区" },
          { value: "610821@@神木县", label: "神木县" },
          { value: "610822@@府谷县", label: "府谷县" },
          { value: "610823@@横山县", label: "横山县" },
          { value: "610824@@靖边县", label: "靖边县" },
          { value: "610825@@定边县", label: "定边县" },
          { value: "610826@@绥德县", label: "绥德县" },
          { value: "610827@@米脂县", label: "米脂县" },
          { value: "610828@@佳县", label: "佳县" },
          { value: "610829@@吴堡县", label: "吴堡县" },
          { value: "610830@@清涧县", label: "清涧县" },
          { value: "610831@@子洲县", label: "子洲县" },
          { value: "610832@@其它区", label: "其它区" },
        ],
      },
      {
        value: "610900@@安康市",
        label: "安康市",
        children: [
          { value: "610902@@汉滨区", label: "汉滨区" },
          { value: "610921@@汉阴县", label: "汉阴县" },
          { value: "610922@@石泉县", label: "石泉县" },
          { value: "610923@@宁陕县", label: "宁陕县" },
          { value: "610924@@紫阳县", label: "紫阳县" },
          { value: "610925@@岚皋县", label: "岚皋县" },
          { value: "610926@@平利县", label: "平利县" },
          { value: "610927@@镇坪县", label: "镇坪县" },
          { value: "610928@@旬阳县", label: "旬阳县" },
          { value: "610929@@白河县", label: "白河县" },
          { value: "610930@@其它区", label: "其它区" },
        ],
      },
      {
        value: "611000@@商洛市",
        label: "商洛市",
        children: [
          { value: "611002@@商州区", label: "商州区" },
          { value: "611021@@洛南县", label: "洛南县" },
          { value: "611022@@丹凤县", label: "丹凤县" },
          { value: "611023@@商南县", label: "商南县" },
          { value: "611024@@山阳县", label: "山阳县" },
          { value: "611025@@镇安县", label: "镇安县" },
          { value: "611026@@柞水县", label: "柞水县" },
          { value: "611027@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "620000@@甘肃",
    label: "甘肃",
    children: [
      {
        value: "620100@@兰州市",
        label: "兰州市",
        children: [
          { value: "620102@@城关区", label: "城关区" },
          { value: "620103@@七里河区", label: "七里河区" },
          { value: "620104@@西固区", label: "西固区" },
          { value: "620105@@安宁区", label: "安宁区" },
          { value: "620111@@红古区", label: "红古区" },
          { value: "620121@@永登县", label: "永登县" },
          { value: "620122@@皋兰县", label: "皋兰县" },
          { value: "620123@@榆中县", label: "榆中县" },
          { value: "620124@@其它区", label: "其它区" },
        ],
      },
      {
        value: "620200@@嘉峪关市",
        label: "嘉峪关市",
        children: [
          { value: "620201100@@新城镇", label: "新城镇" },
          { value: "620201101@@峪泉镇", label: "峪泉镇" },
          { value: "620201102@@文殊镇", label: "文殊镇" },
          { value: "620201401@@雄关区", label: "雄关区" },
          { value: "620201402@@镜铁区", label: "镜铁区" },
          { value: "620201403@@长城区", label: "长城区" },
        ],
      },
      {
        value: "620300@@金昌市",
        label: "金昌市",
        children: [
          { value: "620302@@金川区", label: "金川区" },
          { value: "620321@@永昌县", label: "永昌县" },
          { value: "620322@@其它区", label: "其它区" },
        ],
      },
      {
        value: "620400@@白银市",
        label: "白银市",
        children: [
          { value: "620402@@白银区", label: "白银区" },
          { value: "620403@@平川区", label: "平川区" },
          { value: "620421@@靖远县", label: "靖远县" },
          { value: "620422@@会宁县", label: "会宁县" },
          { value: "620423@@景泰县", label: "景泰县" },
          { value: "620424@@其它区", label: "其它区" },
        ],
      },
      {
        value: "620500@@天水市",
        label: "天水市",
        children: [
          { value: "620502@@秦州区", label: "秦州区" },
          { value: "620503@@麦积区", label: "麦积区" },
          { value: "620521@@清水县", label: "清水县" },
          { value: "620522@@秦安县", label: "秦安县" },
          { value: "620523@@甘谷县", label: "甘谷县" },
          { value: "620524@@武山县", label: "武山县" },
          { value: "620525@@张家川回族自治县", label: "张家川回族自治县" },
          { value: "620526@@其它区", label: "其它区" },
        ],
      },
      {
        value: "620600@@武威市",
        label: "武威市",
        children: [
          { value: "620602@@凉州区", label: "凉州区" },
          { value: "620621@@民勤县", label: "民勤县" },
          { value: "620622@@古浪县", label: "古浪县" },
          { value: "620623@@天祝藏族自治县", label: "天祝藏族自治县" },
          { value: "620624@@其它区", label: "其它区" },
        ],
      },
      {
        value: "620700@@张掖市",
        label: "张掖市",
        children: [
          { value: "620702@@甘州区", label: "甘州区" },
          { value: "620721@@肃南裕固族自治县", label: "肃南裕固族自治县" },
          { value: "620722@@民乐县", label: "民乐县" },
          { value: "620723@@临泽县", label: "临泽县" },
          { value: "620724@@高台县", label: "高台县" },
          { value: "620725@@山丹县", label: "山丹县" },
          { value: "620726@@其它区", label: "其它区" },
        ],
      },
      {
        value: "620800@@平凉市",
        label: "平凉市",
        children: [
          { value: "620802@@崆峒区", label: "崆峒区" },
          { value: "620821@@泾川县", label: "泾川县" },
          { value: "620822@@灵台县", label: "灵台县" },
          { value: "620823@@崇信县", label: "崇信县" },
          { value: "620824@@华亭县", label: "华亭县" },
          { value: "620825@@庄浪县", label: "庄浪县" },
          { value: "620826@@静宁县", label: "静宁县" },
          { value: "620827@@其它区", label: "其它区" },
        ],
      },
      {
        value: "620900@@酒泉市",
        label: "酒泉市",
        children: [
          { value: "620902@@肃州区", label: "肃州区" },
          { value: "620921@@金塔县", label: "金塔县" },
          { value: "620922@@瓜州", label: "瓜州" },
          { value: "620923@@肃北蒙古族自治县", label: "肃北蒙古族自治县" },
          {
            value: "620924@@阿克塞哈萨克族自治县",
            label: "阿克塞哈萨克族自治县",
          },
          { value: "620981@@玉门市", label: "玉门市" },
          { value: "620982@@敦煌市", label: "敦煌市" },
          { value: "620983@@其它区", label: "其它区" },
        ],
      },
      {
        value: "621000@@庆阳市",
        label: "庆阳市",
        children: [
          { value: "621002@@西峰区", label: "西峰区" },
          { value: "621021@@庆城县", label: "庆城县" },
          { value: "621022@@环县", label: "环县" },
          { value: "621023@@华池县", label: "华池县" },
          { value: "621024@@合水县", label: "合水县" },
          { value: "621025@@正宁县", label: "正宁县" },
          { value: "621026@@宁县", label: "宁县" },
          { value: "621027@@镇原县", label: "镇原县" },
          { value: "621028@@其它区", label: "其它区" },
        ],
      },
      {
        value: "621100@@定西市",
        label: "定西市",
        children: [
          { value: "621102@@安定区", label: "安定区" },
          { value: "621121@@通渭县", label: "通渭县" },
          { value: "621122@@陇西县", label: "陇西县" },
          { value: "621123@@渭源县", label: "渭源县" },
          { value: "621124@@临洮县", label: "临洮县" },
          { value: "621125@@漳县", label: "漳县" },
          { value: "621126@@岷县", label: "岷县" },
          { value: "621127@@其它区", label: "其它区" },
        ],
      },
      {
        value: "621200@@陇南市",
        label: "陇南市",
        children: [
          { value: "621202@@武都区", label: "武都区" },
          { value: "621221@@成县", label: "成县" },
          { value: "621222@@文县", label: "文县" },
          { value: "621223@@宕昌县", label: "宕昌县" },
          { value: "621224@@康县", label: "康县" },
          { value: "621225@@西和县", label: "西和县" },
          { value: "621226@@礼县", label: "礼县" },
          { value: "621227@@徽县", label: "徽县" },
          { value: "621228@@两当县", label: "两当县" },
          { value: "621229@@其它区", label: "其它区" },
        ],
      },
      {
        value: "622900@@临夏回族自治州",
        label: "临夏回族自治州",
        children: [
          { value: "622901@@临夏市", label: "临夏市" },
          { value: "622921@@临夏县", label: "临夏县" },
          { value: "622922@@康乐县", label: "康乐县" },
          { value: "622923@@永靖县", label: "永靖县" },
          { value: "622924@@广河县", label: "广河县" },
          { value: "622925@@和政县", label: "和政县" },
          { value: "622926@@东乡族自治县", label: "东乡族自治县" },
          {
            value: "622927@@积石山保安族东乡族撒拉族自治县",
            label: "积石山保安族东乡族撒拉族自治县",
          },
          { value: "622928@@其它区", label: "其它区" },
        ],
      },
      {
        value: "623000@@甘南藏族自治州",
        label: "甘南藏族自治州",
        children: [
          { value: "623001@@合作市", label: "合作市" },
          { value: "623021@@临潭县", label: "临潭县" },
          { value: "623022@@卓尼县", label: "卓尼县" },
          { value: "623023@@舟曲县", label: "舟曲县" },
          { value: "623024@@迭部县", label: "迭部县" },
          { value: "623025@@玛曲县", label: "玛曲县" },
          { value: "623026@@碌曲县", label: "碌曲县" },
          { value: "623027@@夏河县", label: "夏河县" },
          { value: "623028@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "630000@@青海",
    label: "青海",
    children: [
      {
        value: "630100@@西宁市",
        label: "西宁市",
        children: [
          { value: "630102@@城东区", label: "城东区" },
          { value: "630103@@城中区", label: "城中区" },
          { value: "630104@@城西区", label: "城西区" },
          { value: "630105@@城北区", label: "城北区" },
          {
            value: "630121@@大通回族土族自治县",
            label: "大通回族土族自治县",
          },
          { value: "630122@@湟中县", label: "湟中县" },
          { value: "630123@@湟源县", label: "湟源县" },
          { value: "630124@@其它区", label: "其它区" },
        ],
      },
      {
        value: "632100@@海东地区",
        label: "海东地区",
        children: [
          { value: "632121@@平安县", label: "平安县" },
          {
            value: "632122@@民和回族土族自治县",
            label: "民和回族土族自治县",
          },
          { value: "632123@@乐都县", label: "乐都县" },
          { value: "632126@@互助土族自治县", label: "互助土族自治县" },
          { value: "632127@@化隆回族自治县", label: "化隆回族自治县" },
          { value: "632128@@循化撒拉族自治县", label: "循化撒拉族自治县" },
          { value: "632129@@其它区", label: "其它区" },
        ],
      },
      {
        value: "632200@@海北藏族自治州",
        label: "海北藏族自治州",
        children: [
          { value: "632221@@门源回族自治县", label: "门源回族自治县" },
          { value: "632222@@祁连县", label: "祁连县" },
          { value: "632223@@海晏县", label: "海晏县" },
          { value: "632224@@刚察县", label: "刚察县" },
          { value: "632225@@其它区", label: "其它区" },
        ],
      },
      {
        value: "632300@@黄南藏族自治州",
        label: "黄南藏族自治州",
        children: [
          { value: "632321@@同仁县", label: "同仁县" },
          { value: "632322@@尖扎县", label: "尖扎县" },
          { value: "632323@@泽库县", label: "泽库县" },
          { value: "632324@@河南蒙古族自治县", label: "河南蒙古族自治县" },
          { value: "632325@@其它区", label: "其它区" },
        ],
      },
      {
        value: "632500@@海南藏族自治州",
        label: "海南藏族自治州",
        children: [
          { value: "632521@@共和县", label: "共和县" },
          { value: "632522@@同德县", label: "同德县" },
          { value: "632523@@贵德县", label: "贵德县" },
          { value: "632524@@兴海县", label: "兴海县" },
          { value: "632525@@贵南县", label: "贵南县" },
          { value: "632526@@其它区", label: "其它区" },
        ],
      },
      {
        value: "632600@@果洛藏族自治州",
        label: "果洛藏族自治州",
        children: [
          { value: "632621@@玛沁县", label: "玛沁县" },
          { value: "632622@@班玛县", label: "班玛县" },
          { value: "632623@@甘德县", label: "甘德县" },
          { value: "632624@@达日县", label: "达日县" },
          { value: "632625@@久治县", label: "久治县" },
          { value: "632626@@玛多县", label: "玛多县" },
          { value: "632627@@其它区", label: "其它区" },
        ],
      },
      {
        value: "632700@@玉树藏族自治州",
        label: "玉树藏族自治州",
        children: [
          { value: "632721@@玉树县", label: "玉树县" },
          { value: "632722@@杂多县", label: "杂多县" },
          { value: "632723@@称多县", label: "称多县" },
          { value: "632724@@治多县", label: "治多县" },
          { value: "632725@@囊谦县", label: "囊谦县" },
          { value: "632726@@曲麻莱县", label: "曲麻莱县" },
          { value: "632727@@其它区", label: "其它区" },
        ],
      },
      {
        value: "632800@@海西蒙古族藏族自治州",
        label: "海西蒙古族藏族自治州",
        children: [
          { value: "632801@@格尔木市", label: "格尔木市" },
          { value: "632802@@德令哈市", label: "德令哈市" },
          { value: "632821@@乌兰县", label: "乌兰县" },
          { value: "632822@@都兰县", label: "都兰县" },
          { value: "632823@@天峻县", label: "天峻县" },
          { value: "632824@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "640000@@宁夏",
    label: "宁夏",
    children: [
      {
        value: "640100@@银川市",
        label: "银川市",
        children: [
          { value: "640104@@兴庆区", label: "兴庆区" },
          { value: "640105@@西夏区", label: "西夏区" },
          { value: "640106@@金凤区", label: "金凤区" },
          { value: "640121@@永宁县", label: "永宁县" },
          { value: "640122@@贺兰县", label: "贺兰县" },
          { value: "640181@@灵武市", label: "灵武市" },
          { value: "640182@@其它区", label: "其它区" },
        ],
      },
      {
        value: "640200@@石嘴山市",
        label: "石嘴山市",
        children: [
          { value: "640202@@大武口区", label: "大武口区" },
          { value: "640205@@惠农区", label: "惠农区" },
          { value: "640221@@平罗县", label: "平罗县" },
          { value: "640222@@其它区", label: "其它区" },
        ],
      },
      {
        value: "640300@@吴忠市",
        label: "吴忠市",
        children: [
          { value: "640302@@利通区", label: "利通区" },
          { value: "640303@@红寺堡区", label: "红寺堡区" },
          { value: "640323@@盐池县", label: "盐池县" },
          { value: "640324@@同心县", label: "同心县" },
          { value: "640381@@青铜峡市", label: "青铜峡市" },
          { value: "640382@@其它区", label: "其它区" },
        ],
      },
      {
        value: "640400@@固原市",
        label: "固原市",
        children: [
          { value: "640402@@原州区", label: "原州区" },
          { value: "640422@@西吉县", label: "西吉县" },
          { value: "640423@@隆德县", label: "隆德县" },
          { value: "640424@@泾源县", label: "泾源县" },
          { value: "640425@@彭阳县", label: "彭阳县" },
          { value: "640426@@其它区", label: "其它区" },
        ],
      },
      {
        value: "640500@@中卫市",
        label: "中卫市",
        children: [
          { value: "640502@@沙坡头区", label: "沙坡头区" },
          { value: "640521@@中宁县", label: "中宁县" },
          { value: "640522@@海原县", label: "海原县" },
          { value: "640523@@其它区", label: "其它区" },
        ],
      },
    ],
  },
  {
    value: "650000@@新疆",
    label: "新疆",
    children: [
      {
        value: "650100@@乌鲁木齐市",
        label: "乌鲁木齐市",
        children: [
          { value: "650102@@天山区", label: "天山区" },
          { value: "650103@@沙依巴克区", label: "沙依巴克区" },
          { value: "650104@@新市区", label: "新市区" },
          { value: "650105@@水磨沟区", label: "水磨沟区" },
          { value: "650106@@头屯河区", label: "头屯河区" },
          { value: "650107@@达坂城区", label: "达坂城区" },
          { value: "650108@@东山区", label: "东山区" },
          { value: "650109@@米东区", label: "米东区" },
          { value: "650121@@乌鲁木齐县", label: "乌鲁木齐县" },
          { value: "650122@@其它区", label: "其它区" },
        ],
      },
      {
        value: "650200@@克拉玛依市",
        label: "克拉玛依市",
        children: [
          { value: "650202@@独山子区", label: "独山子区" },
          { value: "650203@@克拉玛依区", label: "克拉玛依区" },
          { value: "650204@@白碱滩区", label: "白碱滩区" },
          { value: "650205@@乌尔禾区", label: "乌尔禾区" },
          { value: "650206@@其它区", label: "其它区" },
        ],
      },
      {
        value: "652100@@吐鲁番地区",
        label: "吐鲁番地区",
        children: [
          { value: "652101@@高昌", label: "高昌" },
          { value: "652122@@鄯善县", label: "鄯善县" },
          { value: "652123@@托克逊县", label: "托克逊县" },
          { value: "652124@@其它区", label: "其它区" },
        ],
      },
      {
        value: "652200@@哈密地区",
        label: "哈密地区",
        children: [
          { value: "652201@@伊州", label: "伊州" },
          {
            value: "652222@@巴里坤哈萨克自治县",
            label: "巴里坤哈萨克自治县",
          },
          { value: "652223@@伊吾县", label: "伊吾县" },
          { value: "652224@@其它区", label: "其它区" },
        ],
      },
      {
        value: "652300@@昌吉回族自治州",
        label: "昌吉回族自治州",
        children: [
          { value: "652301@@昌吉市", label: "昌吉市" },
          { value: "652302@@阜康市", label: "阜康市" },
          { value: "652303@@米泉市", label: "米泉市" },
          { value: "652323@@呼图壁县", label: "呼图壁县" },
          { value: "652324@@玛纳斯县", label: "玛纳斯县" },
          { value: "652325@@奇台县", label: "奇台县" },
          { value: "652327@@吉木萨尔县", label: "吉木萨尔县" },
          { value: "652328@@木垒哈萨克自治县", label: "木垒哈萨克自治县" },
          { value: "652329@@其它区", label: "其它区" },
        ],
      },
      {
        value: "652700@@博尔塔拉蒙古自治州",
        label: "博尔塔拉蒙古自治州",
        children: [
          { value: "652701@@博乐市", label: "博乐市" },
          { value: "652722@@精河县", label: "精河县" },
          { value: "652723@@温泉县", label: "温泉县" },
          { value: "652724@@其它区", label: "其它区" },
        ],
      },
      {
        value: "652800@@巴音郭楞蒙古自治州",
        label: "巴音郭楞蒙古自治州",
        children: [
          { value: "652801@@库尔勒市", label: "库尔勒市" },
          { value: "652822@@轮台县", label: "轮台县" },
          { value: "652823@@尉犁县", label: "尉犁县" },
          { value: "652824@@若羌县", label: "若羌县" },
          { value: "652825@@且末县", label: "且末县" },
          { value: "652826@@焉耆回族自治县", label: "焉耆回族自治县" },
          { value: "652827@@和静县", label: "和静县" },
          { value: "652828@@和硕县", label: "和硕县" },
          { value: "652829@@博湖县", label: "博湖县" },
          { value: "652830@@其它区", label: "其它区" },
        ],
      },
      {
        value: "652900@@阿克苏地区",
        label: "阿克苏地区",
        children: [
          { value: "652901@@阿克苏市", label: "阿克苏市" },
          { value: "652922@@温宿县", label: "温宿县" },
          { value: "652923@@库车县", label: "库车县" },
          { value: "652924@@沙雅县", label: "沙雅县" },
          { value: "652925@@新和县", label: "新和县" },
          { value: "652926@@拜城县", label: "拜城县" },
          { value: "652927@@乌什县", label: "乌什县" },
          { value: "652928@@阿瓦提县", label: "阿瓦提县" },
          { value: "652929@@柯坪县", label: "柯坪县" },
          { value: "652930@@其它区", label: "其它区" },
        ],
      },
      {
        value: "653000@@克孜勒苏柯尔克孜自治州",
        label: "克孜勒苏柯尔克孜自治州",
        children: [
          { value: "653001@@阿图什市", label: "阿图什市" },
          { value: "653022@@阿克陶县", label: "阿克陶县" },
          { value: "653023@@阿合奇县", label: "阿合奇县" },
          { value: "653024@@乌恰县", label: "乌恰县" },
          { value: "653025@@其它区", label: "其它区" },
        ],
      },
      {
        value: "653100@@喀什地区",
        label: "喀什地区",
        children: [
          { value: "653101@@喀什市", label: "喀什市" },
          { value: "653121@@疏附县", label: "疏附县" },
          { value: "653122@@疏勒县", label: "疏勒县" },
          { value: "653123@@英吉沙县", label: "英吉沙县" },
          { value: "653124@@泽普县", label: "泽普县" },
          { value: "653125@@莎车县", label: "莎车县" },
          { value: "653126@@叶城县", label: "叶城县" },
          { value: "653127@@麦盖提县", label: "麦盖提县" },
          { value: "653128@@岳普湖县", label: "岳普湖县" },
          { value: "653129@@伽师县", label: "伽师县" },
          { value: "653130@@巴楚县", label: "巴楚县" },
          {
            value: "653131@@塔什库尔干塔吉克自治县",
            label: "塔什库尔干塔吉克自治县",
          },
          { value: "653132@@其它区", label: "其它区" },
        ],
      },
      {
        value: "653200@@和田地区",
        label: "和田地区",
        children: [
          { value: "653201@@和田市", label: "和田市" },
          { value: "653221@@和田县", label: "和田县" },
          { value: "653222@@墨玉县", label: "墨玉县" },
          { value: "653223@@皮山县", label: "皮山县" },
          { value: "653224@@洛浦县", label: "洛浦县" },
          { value: "653225@@策勒县", label: "策勒县" },
          { value: "653226@@于田县", label: "于田县" },
          { value: "653227@@民丰县", label: "民丰县" },
          { value: "653228@@其它区", label: "其它区" },
        ],
      },
      {
        value: "654000@@伊犁哈萨克自治州",
        label: "伊犁哈萨克自治州",
        children: [
          { value: "654002@@伊宁市", label: "伊宁市" },
          { value: "654003@@奎屯市", label: "奎屯市" },
          { value: "654021@@伊宁县", label: "伊宁县" },
          {
            value: "654022@@察布查尔锡伯自治县",
            label: "察布查尔锡伯自治县",
          },
          { value: "654023@@霍城县", label: "霍城县" },
          { value: "654024@@巩留县", label: "巩留县" },
          { value: "654025@@新源县", label: "新源县" },
          { value: "654026@@昭苏县", label: "昭苏县" },
          { value: "654027@@特克斯县", label: "特克斯县" },
          { value: "654028@@尼勒克县", label: "尼勒克县" },
          { value: "654029@@其它区", label: "其它区" },
        ],
      },
      {
        value: "654200@@塔城地区",
        label: "塔城地区",
        children: [
          { value: "654201@@塔城市", label: "塔城市" },
          { value: "654202@@乌苏市", label: "乌苏市" },
          { value: "654221@@额敏县", label: "额敏县" },
          { value: "654223@@沙湾县", label: "沙湾县" },
          { value: "654224@@托里县", label: "托里县" },
          { value: "654225@@裕民县", label: "裕民县" },
          {
            value: "654226@@和布克赛尔蒙古自治县",
            label: "和布克赛尔蒙古自治县",
          },
          { value: "654227@@其它区", label: "其它区" },
        ],
      },
      {
        value: "654300@@阿勒泰地区",
        label: "阿勒泰地区",
        children: [
          { value: "654301@@阿勒泰市", label: "阿勒泰市" },
          { value: "654321@@布尔津县", label: "布尔津县" },
          { value: "654322@@富蕴县", label: "富蕴县" },
          { value: "654323@@福海县", label: "福海县" },
          { value: "654324@@哈巴河县", label: "哈巴河县" },
          { value: "654325@@青河县", label: "青河县" },
          { value: "654326@@吉木乃县", label: "吉木乃县" },
          { value: "654327@@其它区", label: "其它区" },
        ],
      },
      {
        value: "659001@@石河子市",
        label: "石河子市",
        children: [
          { value: "659001001@@新城街道", label: "新城街道" },
          { value: "659001002@@向阳街道", label: "向阳街道" },
          { value: "659001003@@红山街道", label: "红山街道" },
          { value: "659001004@@老街街道", label: "老街街道" },
          { value: "659001005@@东城街道", label: "东城街道" },
          { value: "659001100@@北泉镇", label: "北泉镇" },
          { value: "659001200@@石河子乡", label: "石河子乡" },
          { value: "659001500@@兵团一五二团", label: "兵团一五二团" },
        ],
      },
      {
        value: "659002@@阿拉尔市",
        label: "阿拉尔市",
        children: [
          { value: "659002001@@金银川路街道", label: "金银川路街道" },
          { value: "659002002@@幸福路街道", label: "幸福路街道" },
          { value: "659002003@@青松路街道", label: "青松路街道" },
          { value: "659002004@@南口街道", label: "南口街道" },
          { value: "659002200@@托喀依乡", label: "托喀依乡" },
          { value: "659002402@@工业园区", label: "工业园区" },
          { value: "659002500@@兵团七团", label: "兵团七团" },
          { value: "659002501@@兵团八团", label: "兵团八团" },
          { value: "659002503@@兵团十团", label: "兵团十团" },
          { value: "659002504@@兵团十一团", label: "兵团十一团" },
          { value: "659002505@@兵团十二团", label: "兵团十二团" },
          { value: "659002506@@兵团十三团", label: "兵团十三团" },
          { value: "659002507@@兵团十四团", label: "兵团十四团" },
          { value: "659002509@@兵团十六团", label: "兵团十六团" },
          {
            value: "659002511@@兵团第一师水利水电工程处",
            label: "兵团第一师水利水电工程处",
          },
          {
            value: "659002512@@兵团第一师塔里木灌区水利管理处",
            label: "兵团第一师塔里木灌区水利管理处",
          },
          { value: "659002513@@阿拉尔农场", label: "阿拉尔农场" },
          {
            value: "659002514@@兵团第一师幸福农场",
            label: "兵团第一师幸福农场",
          },
          { value: "659002515@@中心监狱", label: "中心监狱" },
        ],
      },
      {
        value: "659003@@图木舒克市",
        label: "图木舒克市",
        children: [
          { value: "659003001@@齐干却勒街道", label: "齐干却勒街道" },
          { value: "659003002@@前海街道", label: "前海街道" },
          { value: "659003003@@永安坝街道", label: "永安坝街道" },
          { value: "659003504@@兵团四十四团", label: "兵团四十四团" },
          { value: "659003509@@兵团四十九团", label: "兵团四十九团" },
          { value: "659003510@@兵团五十团", label: "兵团五十团" },
          { value: "659003511@@兵团五十一团", label: "兵团五十一团" },
          { value: "659003513@@兵团五十三团", label: "兵团五十三团" },
          {
            value: "659003514@@兵团图木舒克市喀拉拜勒镇",
            label: "兵团图木舒克市喀拉拜勒镇",
          },
          {
            value: "659003515@@兵团图木舒克市永安坝",
            label: "兵团图木舒克市永安坝",
          },
        ],
      },
      {
        value: "659004@@五家渠市",
        label: "五家渠市",
        children: [
          { value: "659004001@@军垦路街道", label: "军垦路街道" },
          { value: "659004002@@青湖路街道", label: "青湖路街道" },
          { value: "659004003@@人民路街道", label: "人民路街道" },
          { value: "659004500@@兵团一零一团", label: "兵团一零一团" },
          { value: "659004501@@兵团一零二团", label: "兵团一零二团" },
          { value: "659004502@@兵团一零三团", label: "兵团一零三团" },
        ],
      },
    ],
  },
];


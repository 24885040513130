<template>
  <!-- banner -->
  <div class="layer banner">
    <div class="header">
      <div class="content h_content">
        <!-- <div class="logo"></div> -->
        <a href="http://www.eltmall.com/" target="_blank">
          <img src="../../assets/images/logo.png" alt="云知光焕新服务" />
        </a>
      </div>
    </div>
    <div class="content b_content">
      <h1>云知光灯光焕新服务</h1>
      <h4>用灯光让空间焕然一新</h4>
      <a
        href="javascript:void(0);"
        class="btn btn-yuyue"
        @click="handlePopShow"
      >
        <span>立即预约</span>
      </a>
      <p>
        <span> <i class="iconfont icon-dingwei"></i>开通城市： </span>北京
      </p>
    </div>
    <!-- <img src="../../assets/images/banner1.png" alt="云知光焕新服务" /> -->
  </div>
</template>
<script>
export default {
  name: "Header",
  methods: {
    handlePopShow() {
      this.$emit("yuyueEvent");
    }
  }
};
</script>
<style lang="scss">
@import "../../assets/scss/mixins.scss";
// @import "../../assets/scss/iconfont.css";
.header {
  background: rgba(255, 255, 255, 0.5);
  .h_content {
    height: 34px;
    line-height: 34px;
    text-align: right;
  }
  img {
    display: inline-block;
    vertical-align: middle;
  }
}
.banner {
  height: 480px;
  width: 100%;
  color: #4a4a4a;
  background: url("../../assets/images/banner1.png") no-repeat center top;
  background-size: auto 100%;

  h1 {
    font-size: 48px;
    font-weight: 500;
    margin-bottom: 16px;
    padding-top: 80px;
    color: #fff;
  }
  h4 {
    font-size: 28px;
    margin-bottom: 80px;
    color: #fff;
  }
  a.btn {
    display: block;
    width: 176px;
    height: 56px;
    border-radius: 3px;
    background: #fff;
    color: #3C9096;
    font-size: 24px;
    line-height: 56px;
    text-align: center;
    text-decoration: none;
    &:hover{
      color: #76B1B5;
    }
  }
  p {
    height: 38px;
    line-height: 18px;
    font-size: 18px;
    padding-top: 20px;
    color: #fff;
    span {
      float: left;
      height: 18px;
      line-height: 18px;
      font-size: 12px;
    }
    i {
      padding-right: 2px;
      font-size: 12px;
      color: #999;
    }
  }
}
</style>

<template>
  <div class="layer detection">
    <div class="content d_content">
      <h2 class="layer_title">专业光环境检测</h2>
      <p class="layer_title_des">
        遵循云知光照明应用研究中心和欧美权威照明研究机构成果制定的光环境评价体系LQS（Lighting
        Quality Standard）对两大类24项评测指标进行评测，给出专业报告
      </p>
      <div class="de_block">
        <h4 class="de_title">性能和品质检测指标</h4>
        <ul class="de_params">
          <li>亮度分布</li>
          <li>工作面照度</li>
          <li>垂直面照度</li>
          <li>照度均匀度</li>
          <li>有无频闪</li>
          <li>眩光评价</li>
          <li>显色性</li>
          <li>色温分布协调性</li>
          <li>色温一致性</li>
          <li>灯具配光</li>
          <li>灯具外观</li>
          <li>灯具安全防护</li>
        </ul>
      </div>
      <div class="de_block">
        <h4 class="de_title">效果与经济检测指标</h4>
        <ul class="de_params">
          <li>基础照明</li>
          <li>工作照明</li>
          <li>重点照明</li>
          <li>灯光氛围</li>
          <li>灯光可控性</li>
          <li>灯光使用场景模式</li>
          <li>故障状况</li>
          <li>电气设计的安全与合理</li>
          <li>产品耐用性</li>
          <li>使用便利性</li>
          <li>维护便利性</li>
          <li>用户使用成本</li>
        </ul>
      </div>
      <div class="de_block">
        <h4 class="de_title">检测工具</h4>
        <ul class="de_tools">
          <li>
            <span>
              <img src="../../assets/images/too-1.png" alt="红外测距仪" />
            </span>
            <p>红外线测距仪</p>
          </li>
          <li>
            <span>
              <img src="../../assets/images/too-2.png" alt="照度仪" />
            </span>
            <p>照度计</p>
          </li>
          <li>
            <span>
              <img src="../../assets/images/too-3.png" alt="频闪仪（照度计）" />
            </span>
            <p>频闪仪</p>
          </li>
          <li>
            <span>
              <img src="../../assets/images/too-4.png" alt="工具箱" />
            </span>
            <p>工具箱</p>
          </li>
          <li>
            <span>
              <img src="../../assets/images/too-5.png" alt="照明护照(高配版)" />
            </span>
            <p>照明护照光谱仪</p>
          </li>
        </ul>
      </div>
      <div class="de_block">
        <h4 class="de_title">焕新现场</h4>
        <!-- swiper -->
        <div class="swiper-box">
          <swiper :options="swiperOption" class="swiper-no-swiping">
            <!-- -->
            <swiper-slide>
              <span>
                <img src="../../assets/images/sence-1.png" alt="需求调研" />
              </span>
              <p>需求调研</p>
            </swiper-slide>
            <swiper-slide>
              <span>
                <img src="../../assets/images/sence-2.png" alt="回路分析" />
              </span>
              <p>回路分析</p>
            </swiper-slide>
            <swiper-slide>
              <span>
                <img src="../../assets/images/sence-3.png" alt="照度分析" />
              </span>
              <p>照度分析</p>
            </swiper-slide>
            <swiper-slide>
              <span>
                <img src="../../assets/images/sence-4.png" alt="光谱测试" />
              </span>
              <p>光谱测试</p>
            </swiper-slide>
            <swiper-slide>
              <span>
                <img src="../../assets/images/sence-5.png" alt="频闪测试" />
              </span>
              <p>频闪测试</p>
            </swiper-slide>
            <swiper-slide>
              <span>
                <img src="../../assets/images/sence-6.png" alt="原灯勘察" />
              </span>
              <p>原灯勘察</p>
            </swiper-slide>
            <swiper-slide>
              <span>
                <img src="../../assets/images/sence-7.png" alt="方案设计" />
              </span>
              <p>方案设计</p>
            </swiper-slide>
            <swiper-slide>
              <span>
                <img src="../../assets/images/sence-8.png" alt="灯具安装" />
              </span>
              <p>灯具安装</p>
            </swiper-slide>
            <swiper-slide>
              <span>
                <img src="../../assets/images/sence-9.png" alt="灯光调试" />
              </span>
              <p>灯光调试</p>
            </swiper-slide>
          </swiper>
          <div class="swiper-button-prev" slot="button-prev">
            <i class="iconfont icon-next-copy-copy"></i>
          </div>
          <div class="swiper-button-next" slot="button-next">
            <i class="iconfont icon-next-copy"></i>
          </div>
        </div>
        <!-- <ul class="de_renew">
          <li>
            <span>
              <img src="../../assets/images/sence-1.png" alt="开关检测" />
            </span>
            <p>开关检测</p>
          </li>
          <li>
            <span>
              <img src="../../assets/images/sence-2.png" alt="照度测试" />
            </span>
            <p>照度测试</p>
          </li>
          <li>
            <span>
              <img src="../../assets/images/sence-3.png" alt="线路检测" />
            </span>
            <p>线路检测</p>
          </li>
          <li>
            <span>
              <img src="../../assets/images/sence-4.png" alt="其他分析" />
            </span>
            <p>其他分析</p>
          </li>
          <li>
            <span>
              <img src="../../assets/images/sence-1.png" alt="环境分析" />
            </span>
            <p>环境分析</p>
          </li>
          <li>
            <span>
              <img src="../../assets/images/sence-2.png" alt="频闪测试" />
            </span>
            <p>频闪测试</p>
          </li>
        </ul>-->
      </div>
    </div>
  </div>
</template>
<script>
// import VueAwesomeSwiper from "vue-awesome-swiper";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: "Detection",
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 6,
        spaceBetween: 16,
        slidesPerGroup: 6,
        loop: false,
        loopFillGroupWithBlank: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }
    };
  }
};
</script>
<style lang="scss">
@import "../../assets/scss/mixins.scss";

.swiper-box {
  position: relative;
}
.swiper-button-next,
.swiper-button-prev {
  background: none;
  font-size: 18px;
  text-align: center;
  line-height: 44px;
  margin-top: -33px;
  outline: none;
  i {
    color: #888;
    font-size: 20px;
  }
}
.swiper-button-next {
  right: -34px;
}
.swiper-button-prev {
  left: -34px;
}
.swiper-slide {
  span {
    display: block;
    height: 86px;
  }
  p {
    padding-top: 6px;
    text-align: center;
    font-size: 14px;
  }
}
.detection {
  background: #fff;
  height: 941px;
  margin-bottom: 40px;
  // overflow: hidden;
  position: relative;
  ul {
    @include clearfix;
  }
  .d_content {
    background: #fff;
    top: -550px;
    position: absolute;
    left: 50%;
    margin-left: -600px;
    z-index: 9;
    padding-bottom: 56px;
    box-shadow: 0px 0px 4px 0px #eee;
    border-radius: 6px;
  }
  .layer_title_des {
    line-height: 30px;
    color: #999;
    font-size: 16px;
    width: 832px;
    margin: 0 auto;
    padding: 20px 0 40px;
    text-align: center;
  }
  .de_block {
    background: #f5f5f5;
    padding: 40px 50px;
    margin: 0 50px;
  }
  .de_title {
    color: #4a4a4a;
    font-size: 24px;
    font-weight: 500;
    height: 40px;
    line-height: 40px;
    margin-bottom: 16px;
  }
  .de_block + .de_block {
    margin-top: 28px;
  }
  .de_params {
    margin-left: -10px;
    li {
      display: block;
      float: left;
      margin: 0 0 10px 10px;
      height: 50px;
      background: #fff;
      padding: 0 22px 0 40px;
      position: relative;
      line-height: 50px;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
      border-radius: 3px;
      &::after {
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #9dc7ca;
        position: absolute;
        left: 18px;
        top: 50%;
        margin-top: -3px;
      }
    }
  }
  .de_tools {
    li {
      width: 186px;
      float: left;
      text-align: center;
      span {
        display: table-cell;
        vertical-align: middle;
        width: 186px;
        height: 186px;
        background: #fff;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
        border-radius: 6px;
      }
      p {
        line-height: 24px;
        margin-top: 12px;
      }
    }
    li + li {
      margin-left: 17px;
    }
  }
  .de_renew {
    li {
      float: left;
      width: 153px;
      text-align: center;
      span {
        display: block;
        overflow: hidden;
        width: 153px;
        height: 85px;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
      p {
        padding-top: 13px;
        line-height: 16px;
      }
    }
    li + li {
      margin-left: 16px;
    }
  }
}
</style>

<template>
  <div class="layer service">
    <div class="content s_content">
      <h5 class="layer_subTitle">如果您遇到以上问题</h5>
      <h2 class="layer_title">我们可以提供专业服务</h2>
      <ul class="l_c_list">
        <li>
          <div class="icon-box-bg"></div>
          <div class="icon-box">
            <i class="iconfont icon-mianfeizixun"></i>
          </div>
          <div class="s_text">
            <p class="l_c_title">免费咨询</p>
            <p class="l_c_title_sub">
              解答您在灯光使用方面的顾虑
              <br />给出专业建议
            </p>
          </div>
        </li>
        <li>
          <div class="icon-box-bg"></div>
          <div class="icon-box">
            <i class="iconfont icon-shangmenjiance"></i>
          </div>
          <div class="s_text">
            <p class="l_c_title">上门检测</p>
            <p class="l_c_title_sub">上门服务，对各个空间灯光环境进行诊断，出具检测报告</p>
          </div>
        </li>
        <li>
          <div class="icon-box-bg"></div>
          <div class="icon-box">
            <i class="iconfont icon-tishi"></i>
          </div>
          <div class="s_text">
            <p class="l_c_title">灯光方案</p>
            <p class="l_c_title_sub">
              根据检测结果给出不同焕新方案
              <br />适应不同预算和需求
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "Service"
};
</script>
<style lang="scss">
@import "../../assets/scss/mixins.scss";

.service {
  background: #f8f8f8;

  .s_content {
    position: relative;
    padding: 72px 0 80px;
    &::after {
      display: block;
      content: "";
      position: absolute;
      background: url("../../assets/images/down.png") no-repeat center;
      width: 172px;
      height: 54px;
      top: -14px;
      left: 50%;
      margin-left: -86px;
    }
  }
  .layer_title {
    padding: 0 0 114px 0;
  }
  .l_c_list {
    @include clearfix;
    li {
      display: block;
      float: left;
      width: 392px;
      background: #fff;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
      position: relative;
      height: 190px;
    }
    li + li {
      margin-left: 12px;
    }
    .icon-box-bg,
    .icon-box {
      position: absolute;
      left: 50%;
      border-radius: 50%;
    }
    .icon-box-bg {
      width: 132px;
      height: 132px;
      background: #fff;
      top: -66px;
      margin-left: -66px;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
      z-index: 1;
    }
    .icon-box {
      width: 132px;
      height: 132px;
      border: 10px solid #fff;
      background: linear-gradient(
        315deg,
        rgba(110, 195, 199, 1) 0%,
        rgba(60, 144, 150, 1) 100%
      );
      color: #fff;
      margin-left: -66px;
      top: -66px;
      z-index: 20;
      text-align: center;
      line-height: 112px;
      i {
        font-size: 64px;
      }
    }
    .s_text {
      position: absolute;
      left: 0;
      top: 0;
      background: #fff;
      width: 100%;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
      z-index: 15;
      border-radius: 3px;
      text-align: center;
      padding: 78px 0 40px;
    }
    .l_c_title {
      font-size: 28px;
      color: #4a4a4a;
      line-height: 40px;
      font-weight: 500;
    }
    .l_c_title_sub {
      margin: 0 auto;
      width: 300px;
      line-height: 26px;
      color: #999;
      font-size: 16px;
    }
  }
}
</style>

<template>
  <div class="layer light">
    <div class="content l_content">
      <h2 class="layer_title">灯光可能存在的问题</h2>
      <div class="tab_nav">
        <ul>
          <li @click="handleActive(1)" :class="{ active: active == 1 }">家居</li>
          <li @click="handleActive(2)" :class="{ active: active == 2 }">办公</li>
          <li @click="handleActive(3)" :class="{ active: active == 3 }">商业</li>
        </ul>
      </div>
      <div class="tab_main">
        <!-- <transition-group name="fade"> -->
        <div class="tab_content" v-if="active == 1" key="1">
          <div class="tab_box_l">
            <!--  -->
            <div class="box_img box_img_big">
              <div class="box_img_label">
                <h4>起居室光线昏暗，不够亮</h4>
                <p>可能导致视力下降</p>
              </div>
              <img src="../../assets/images/house-1.png" alt />
            </div>
            <div class="box_img box_img_small">
              <div class="box_img_label">
                <h4>书房照度不足，灯光不防眩</h4>
                <p>会使工作学习效率降低</p>
              </div>
              <img src="../../assets/images/house-2.png" alt />
            </div>
            <div class="box_img box_img_small">
              <div class="box_img_label">
                <h4>衣服买回家总没有在店里看着顺眼</h4>
                <p>显色性能不到位，颜色会失真</p>
              </div>
              <img src="../../assets/images/house-3.png" alt />
            </div>
          </div>
          <div class="tab_box_r">
            <div class="box_img box_img_small">
              <div class="box_img_label">
                <h4>卧室光线过亮、眩光</h4>
                <p>严重影响睡眠质量</p>
              </div>
              <img src="../../assets/images/house-4.png" alt />
            </div>
            <div class="box_img box_img_medie">
              <div class="box_img_label">
                <h4>厨房再亮也照不到操作台</h4>
                <p>做饭切菜产生安全隐患</p>
              </div>
              <img src="../../assets/images/house-5.png" alt />
            </div>
          </div>
        </div>
        <div class="tab_content" v-if="active == 2" key="1">
          <div class="tab_box_l">
            <!--  -->
            <div class="box_img box_img_big">
              <div class="box_img_label">
                <h4>环境陈旧，灯光昏暗</h4>
                <p>企业实力被低估</p>
              </div>
              <img src="../../assets/images/office-1.png" alt />
            </div>
            <div class="box_img box_img_small">
              <div class="box_img_label">
                <h4>办公区光线分布不均匀，明暗不合理</h4>
                <p>员工工作状态易疲劳</p>
              </div>
              <img src="../../assets/images/office-2.png" alt />
            </div>
            <div class="box_img box_img_small">
              <div class="box_img_label">
                <h4>能耗与明亮度不成正比</h4>
                <p>运维成本持续攀高</p>
              </div>
              <img src="../../assets/images/office-3.png" alt />
            </div>
          </div>
          <div class="tab_box_r">
            <div class="box_img box_img_small">
              <div class="box_img_label">
                <h4>前台重点照明不足</h4>
                <p>品牌形象难被引起重视</p>
              </div>
              <img src="../../assets/images/office-4.png" alt />
            </div>
            <div class="box_img box_img_medie">
              <div class="box_img_label">
                <h4>灯光模式单一</h4>
                <p>难以适应不同工作场景需求</p>
              </div>
              <img src="../../assets/images/office-5.png" alt />
            </div>
          </div>
        </div>
        <!-- end of 2 -->
        <div class="tab_content" v-if="active == 3" key="1">
          <div class="tab_box_l">
            <!--  -->
            <div class="box_img box_img_big">
              <div class="box_img_label">
                <h4>灯光设计不够专业，光线混乱</h4>
                <p>浪费高昂装修成本</p>
              </div>
              <img src="../../assets/images/store-1.png" alt />
            </div>
            <div class="box_img box_img_small">
              <div class="box_img_label">
                <h4>橱窗灯光昏暗，招牌商品照不亮</h4>
                <p>难以吸引客流进店</p>
              </div>
              <img src="../../assets/images/store-2.png" alt />
            </div>
            <div class="box_img box_img_small">
              <div class="box_img_label">
                <h4>灯光控制布线混乱，模式无区分</h4>
                <p>独控困难，能源成本飙升</p>
              </div>
              <img src="../../assets/images/store-3.png" alt />
            </div>
          </div>
          <div class="tab_box_r">
            <div class="box_img box_img_small">
              <div class="box_img_label">
                <h4>灯具选择错误，光品质不达标</h4>
                <p>商品实物不如图片</p>
              </div>
              <img src="../../assets/images/store-4.png" alt />
            </div>
            <div class="box_img box_img_medie">
              <div class="box_img_label">
                <h4>灯具安装未进行调光</h4>
                <p>商业效果大打折扣</p>
              </div>
              <img src="../../assets/images/store-5.png" alt />
            </div>
          </div>
        </div>
        <!-- </transition-group> -->
      </div>
      <!-- </div> -->
      <!-- <div class="l_content_service"> -->

      <!-- </div> -->
      <!-- <div class="tab_nav"></div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "Light",
  data() {
    return {
      active: 1
    };
  },
  methods: {
    handleActive(index) {
      this.active = index;
    }
  }
};
</script>
<style lang="scss">
@import "../../assets/scss/mixins.scss";

.light {
  @include clearfix;
  background: #fff;
  padding-bottom: 66px;
  .tab_nav {
    padding: 24px 0;
    text-align: center;
    ul {
      display: inline-block;
      margin-left: -16px;
    }
    li {
      width: 88px;
      height: 36px;
      display: block;
      float: left;
      margin-left: 16px;
      cursor: pointer;
      text-align: center;
      line-height: 36px;
      font-size: 16px;
      box-sizing: border-box;
      border: 1px solid #c0c4cc;
      border-radius: 2px;
      color: #999;
      &.active {
        color: #fff;
        background: linear-gradient(
          315deg,
          rgba(110, 195, 199, 1) 0%,
          rgba(60, 144, 150, 1) 100%
        );
        border: 0;
      }
    }
  }
  .tab_main {
    overflow: hidden;
  }
  .tab_content {
    overflow: hidden;
    position: relative;
    padding-right: 756px;
    // animation: show 0.2s;
    // opacity: ;
  }

  @-webkit-keyframes show {
    0% {
      opacity: 0;
      background: rgba(7, 17, 27, 0);
    }
    100% {
      opacity: 1;
      background: rgba(7, 17, 27, 0.6);
    }
  }

  .tab_box_l {
    width: 795px;
    .box_img:last-child {
      margin-left: 11px;
    }
  }
  .tab_box_r {
    width: 392px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .box_img {
    position: relative;
    float: left;
    margin-bottom: 12px;
    overflow: hidden;
    border-radius: 3px;
    img {
      object-fit: cover;
    }
  }
  .box_img_small {
    width: 392px;
    height: 220px;
  }
  .box_img_big {
    width: 795px;
    height: 446px;
    .box_img_label {
      width: auto;
      box-sizing: border-box;
      height: 92px;
      top: 24px;
      left: 0;
      padding: 12px 20px 12px 20px;
      background: rgba($color: #000, $alpha: 0.4);
      border-radius: 0px 4px 4px 0px;
      h4 {
        font-size: 24px;
        line-height: 36px;
      }
      p {
        line-height: 30px;
        font-size: 16px;
      }
    }
  }
  .box_img_medie {
    width: 392px;
    height: 446px;
  }
  .box_img_label {
    position: absolute;
    padding: 10px 0 12px 20px;
    bottom: 0;
    width: 100%;
    // background: linear-gradient(
    //   180deg,
    //   rgba(0, 0, 0, 0) 0%,
    //   rgba(0, 0, 0, 0.5) 100%
    // );
    color: #fff;
    h4 {
      font-size: 18px;
      line-height: 24px;
    }
    p {
      line-height: 24px;
      font-size: 14px;
      color: #d2cfcd;
    }
  }
}
</style>

<template>
  <div id="app">
    <!-- 顶部条 -->
    <!-- <input type="text" @click="handleClolse" /> -->
    <!-- <City
      :province.sync="province"
      :city.sync="city"
      :district.sync="area"
      @close="handleClolse"
      :isVisialbe="isVisialbe"
    />-->
    <!-- 省：{{ province }} 市：{{ city }} 区： {{ area }} -->
    <!-- <input v-model="dol" />
    <vmodel :word.sync="dol" />-->
    <Header @yuyueEvent="handlePopShow" />
    <Light />
    <Service />
    <Case />
    <Plan />
    <Process />
    <Major />
    <Detection />
    <div class="footer-copy">
      Copyright 2019 云知光 hx.eltmall.com 版权所有 | 京ICP备17046455号 |
      增值电信业务经营性许可证京B2-20192000号 | 由云知光企业云服务提供技术支持
    </div>
    <transition name="fade">
      <div class="footer footerfixed" v-if="isFixedFooter">
        <div class="footer-bar">
          <div class="foot-content">
            <div class="f-l">
              <h4>云知光灯光焕新服务</h4>
              <p>用灯光让空间焕然一新</p>
            </div>
            <div class="f-r">
              <div class="input">
                <span> <i class="iconfont icon-weizhi"></i>北京市 </span>
                <input
                  type="text"
                  placeholder="请输入您的手机号"
                  v-model="localTel"
                />
              </div>
              <div class="btn-yuyue" @click="handlePopShow">免费预约</div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 预约窗口 -->
    <Pop
      :isShow="isPOPshow"
      @close="handlePopShow"
      @success="handleSuccess"
      @codefail="handleCodeFail"
      :defaultNum="localTel"
      @submitFail="handleSubmitFail"
      @netError="handleNetError"
    />
    <PopTip @closeTip="closeTipPop()" :isShow="tipShow" :text="tipText" />
  </div>
</template>

<script>
// import axios from "axios";
// import { deepCopy } from "./untils/untils";

// import HelloWorld from "./components/HelloWorld.vue";
import Header from "./components/header";
import Light from "./components/Light";
import Service from "./components/Service";
import Case from "./components/Case";
import Plan from "./components/Plan";
import Process from "./components/Process";
import Major from "./components/Major";
import Detection from "./components/Detection";
import Pop from "./components/Pop";
import PopTip from "./components/PopTip";
// import City from "./components/city";
// import vmodel from "./components/vmodel";
export default {
  name: "app",
  components: {
    // eslint-disable-next-line
    // HelloWorld,
    Header,
    Light,
    Service,
    Case,
    Plan,
    Process,
    Major,
    Detection,
    Pop,
    PopTip
    // City,
    // vmodel
  },
  data() {
    return {
      // 电话
      localTel: "",
      // footbar fixed
      isFixedFooter: false,
      isPOPshow: false,
      tipShow: false,
      tipText: "",
      city: "",
      province: "",
      area: "",
      isVisialbe: false,
      dol: ""
    };
  },
  watch: {},
  mounted() {
    window.addEventListener("scroll", this.handlefixed, false);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handlefixed, false);
  },
  methods: {
    handleClolse() {
      this.isVisialbe = !this.isVisialbe;
    },
    // 预约
    handlePopShow() {
      this.isPOPshow = !this.isPOPshow;
    },

    handlefixed() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 500) {
        this.isFixedFooter = true;
      } else {
        this.isFixedFooter = false;
      }
    },
    handleSuccess() {
      this.tipShow = true;
      this.tipText = "恭喜您，预约成功！";
      // 5S后自动关闭
      this.closeTipPop(2000);
    },
    closeTipPop(time) {
      let timer = time || 0;
      setTimeout(() => {
        this.tipShow = false;
      }, timer);
    },
    handleCodeFail() {
      this.tipShow = true;
      this.tipText = "验证码下发失败，请您稍后再试！";
      this.closeTipPop(2000);
    },
    handleSubmitFail() {
      this.tipShow = true;
      this.tipText = "预约失败，请您稍后再试！";
      this.closeTipPop(2000);
    },
    handleNetError() {
      this.tipShow = true;
      this.tipText = "网络错误，请稍后再试！";
      this.closeTipPop(2000);
    }
  }
};
</script>

<style lang="scss">
// @import "./assets/scss/iconfont.css";
@import "./assets/scss/_minireset.scss";
@import "./assets/scss/mixins.scss";
.content {
  width: 1200px;
  margin: 0 auto;
  font-size: 16px;
  color: #4a4a4a;
}
.layer {
  box-sizing: border-box;
}
.layer_subTitle {
  height: 48px;
  line-height: 48px;
  font-size: 24px;
  font-weight: 400;
  color: #999;
  text-align: center;
}
.layer_title {
  font-size: 36px;
  color: #4a4a4a;
  text-align: center;
  padding-top: 64px;
  font-weight: 500;
}
.footerfixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99;
}
.footer-copy {
  height: 56px;
  text-align: center;
  background: #f7f7f7;
  color: #c0c4cc;
  font-size: 12px;
  line-height: 56px;
  margin-bottom: 100px;
}
.footer {
  .footer-bar {
    height: 100px;
    background: linear-gradient(
      315deg,
      rgba(110, 195, 199, 0.9) 0%,
      rgba(60, 144, 150, 0.9) 100%
    );
  }
  .foot-content {
    width: 1200px;
    height: 100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .f-l {
    color: #fff;
    h4 {
      font-size: 28px;
      line-height: 40px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .f-r {
    color: #3c9096;
    display: flex;
    align-items: center;
    .input {
      background: #fff;
      font-size: 18px;
      position: relative;
      height: 60px;
      width: 450px;
      padding-left: 30px;
      // padding-left: 140px;
      border-radius: 5px;

      line-height: 60px;
      border-radius: 5px;
      .iconfont {
        font-size: 22px;
        vertical-align: baseline;
        padding-right: 5px;
      }
      span {
        position: relative;
        height: 60px;
        line-height: 60px;
        &::after {
          content: "";
          display: block;
          position: absolute;
          right: -20px;
          width: 2px;
          height: 25px;
          top: 0;
          background: #eeeeee;
        }
      }
      input {
        height: 50px;
        line-height: 60px;
        outline: none;
        width: 300px;
        margin-left: 30px;
        border: 0;
        font-size: 16px;
      }
    }
    .btn-yuyue {
      cursor: pointer;
      height: 60px;
      line-height: 60px;
      width: 150px;
      text-align: center;
      background: #fff;
      border-radius: 5px;
      margin-left: 15px;
      color: #3c9096;
      font-size: 20px;
    }
  }
}
</style>

<template>
  <div class="pop_layer" v-if="isShow">
    <div class="mask"></div>
    <div class="pop_tip">
      <div class="close" @click="handlePopTipShow">
        <i class="iconfont icon-guanbi"></i>
      </div>
      <p>{{text}}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "PopTip",
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    text: {
      type: String
    }
  },
  methods: {
    handlePopTipShow() {
      this.$emit("closeTip");
    }
  }
};
</script>

<template>
  <div class="layer major">
    <div class="content m_content">
      <h2 class="layer_title">专业信赖</h2>
      <ul id="major_items">
        <li :class="{ active: hoverIndex == 1 }" @mouseenter="handleHoverCollope(1)">
          <div class="active_inner">
            <i class="iconfont icon-zhuanye"></i>
            <h6>专业团队保障</h6>
            <p>
              技术实力 -
              由上百名灯光设计师、照明工程师、研发工程师、检测专员、销售工程师组成的强大技术团队
            </p>
            <p>
              覆盖领域 -
              五年来，积累了关于家居、商业、办公、领域一百多个空间类型的照明解决方案
            </p>
            <p>专业细分 - 照明、机电、平面、质检、销售专业等等</p>
          </div>
          <div class="normal_inner">
            <i class="iconfont icon-zhuanye"></i>
            <h6>专业团队保障</h6>
          </div>
          <img src="../../assets/images/major-1.png" alt="专业团队保障" />
        </li>
        <li @mouseenter="handleHoverCollope(2)" :class="{ active: hoverIndex == 2 }">
          <div class="active_inner">
            <i class="iconfont icon-fuwu"></i>
            <h6>产品质量保障</h6>
            <p>全球范围内严选高品质专业产品</p>
            <p>由众多设计师推荐背书</p>
            <p>经过产品工程师的严格筛选</p>
          </div>
          <div class="normal_inner">
            <i class="iconfont icon-fuwu"></i>
            <h6>产品质量保障</h6>
          </div>
          <img src="../../assets/images/major-2.png" alt="产品质量保障" />
        </li>
        <li @mouseenter="handleHoverCollope(3)" :class="{ active: hoverIndex == 3 }">
          <div class="active_inner">
            <i class="iconfont icon-pinzhi"></i>
            <h6>服务品质保障</h6>
            <p>两大类专业评估标准24项评测指标</p>
            <p>配备专业的光环境测量设备及光品质检测仪器</p>
            <p>执照电工和专业调光师上门进行安装和调试</p>
          </div>
          <div class="normal_inner">
            <i class="iconfont icon-pinzhi"></i>
            <h6>服务品质保障</h6>
          </div>
          <img src="../../assets/images/major-3.png" alt="服务品质保障" />
        </li>
        <li @mouseenter="handleHoverCollope(4)" :class="{ active: hoverIndex == 4 }">
          <div class="active_inner">
            <i class="iconfont icon-shouhou"></i>
            <h6>售后服务保障</h6>
            <p>24小时客服在线服务，专业技术人员上门服务</p>
            <p>为超过300家企业和6万名用户提供过优质的服务</p>
            <p>拥有近百名技术型核心成员，服务于照明应用各个专业领域</p>
          </div>
          <div class="normal_inner">
            <i class="iconfont icon-shouhou"></i>
            <h6>售后服务保障</h6>
          </div>
          <img src="../../assets/images/major-4.png" alt="售后服务保障" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "Major",
  data() {
    return {
      hoverIndex: 1
    };
  },
  methods: {
    // 手风琴效果
    handleHoverCollope(index) {
      this.hoverIndex = index;
    }
  }
};
</script>
<style lang="scss">
@import "../../assets/scss/mixins.scss";

.major {
  background: #f5f5f5;
  padding-bottom: 80px;
  .layer_title {
    padding-bottom: 48px;
  }
  .m_content {
    padding-bottom: 550px;
  }
  ul {
    @include clearfix;
    height: auto;
    height: 480px;
  }
  li {
    float: left;
    height: 480px;
    color: #fff;
    position: relative;
    width: 240px;
    .active_inner {
      display: none;
    }
    .normal_inner {
      display: block;
      padding-top: 168px;
      text-align: center;
      h6 {
        padding-top: 20px;
      }
    }
    & > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    i {
      font-size: 34px;
    }
    h6 {
      font-size: 24px;
      line-height: 34px;
      font-weight: 500;
      padding: 20px 0;
    }
  }
  li + li {
    margin-left: 2px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .active_inner {
    background-image: url("../../assets/images/rectangle.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 44px;
    text-align: center;
    padding: 44px 50px 0;
    p {
      position: relative;
      padding: 24px 0;
      &::after,
      &::before {
        position: absolute;
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        border: 1px solid #fff;
        border-radius: 50%;
        top: 5px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
      &::before {
        border: 0;
        transform: translateX(4px);
        background: rgba(255, 255, 255, 0.15);
      }
    }
  }
  .normal_inner {
    // position: absolute;
    background: rgba($color: #000000, $alpha: 0.4);
  }

  li.active {
    width: 474px;
    .active_inner {
      display: block;
    }
    .normal_inner {
      display: none;
    }
  }
}
</style>

<template>
  <div class="layer case">
    <div class="content c_content">
      <h2 class="layer_title">焕新案例</h2>
      <div class="case_item case_item_1">
        <div class="c_after">
          <span class="c_label">焕新后</span>
          <img src="../../assets/images/case-1-after.png" alt="焕新后" />
        </div>
        <div class="c_before">
          <span class="c_label">焕新前</span>
          <img src="../../assets/images/case-1-before.png" alt="焕新前" />
        </div>
        <div class="c_text">
          <h6>客厅舒适型优化</h6>
          <p>使光线更加柔和，不刺眼不晃眼，灯光环境温馨舒适。</p>
        </div>
      </div>
      <div class="case_item case_item_2">
        <div class="c_after">
          <span class="c_label">焕新后</span>
          <img src="../../assets/images/case-2-after.jpg" alt="焕新后" />
        </div>
        <div class="c_before">
          <span class="c_label">焕新前</span>
          <img src="../../assets/images/case-2-before.png" alt="焕新前" />
        </div>
        <div class="c_text">
          <h6>工位智能型优化</h6>
          <p>充分打造环境光，强调多场景灯光模式，办公更立体。</p>
        </div>
      </div>
      <div class="case_item case_item_3">
        <div class="c_after">
          <span class="c_label">焕新后</span>
          <img src="../../assets/images/case-3-after.png" alt="焕新后" />
        </div>
        <div class="c_before">
          <span class="c_label">焕新前</span>
          <img src="../../assets/images/case-3-before.png" alt="焕新前" />
        </div>
        <div class="c_text">
          <h6>餐馆设计型优化</h6>
          <p>光环境层次分明，错落有序，尽显餐厅品味与用餐格调。</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Case"
};
</script>
<style lang="scss">
@import "../../assets/scss/mixins.scss";
.case {
  background: #fff;
  padding-bottom: 80px;
  .case_item {
    position: relative;
    margin-bottom: 20px;
  }
  .layer_title {
    padding-bottom: 48px;
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .c_label {
    position: absolute;
    width: 80px;
    height: 32px;
    text-align: center;
    color: #fff;
    line-height: 32px;
  }
  .c_after .c_label {
    background: linear-gradient(
      315deg,
      rgba(110, 195, 199, 1) 0%,
      rgba(60, 144, 150, 0.4) 100%
    );
    border-radius: 0px 3px 3px 0px;
    top: 20px;
  }
  .c_before .c_label {
    background: rgba(153, 153, 153, 0.66);
    border-radius: 3px 0px 0px 3px;
    right: 0;
    bottom: 27px;
  }
  .c_before {
    position: absolute;
    height: 252px;
    width: 437px;
    border: 7px solid #fff;
    bottom: 20px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  }
  .c_text {
    position: absolute;
    h6 {
      font-size: 24px;
      font-weight: 600;
      // height: 40px;
      line-height: 40px;
      color: #3c9096;
      position: relative;
      // padding-bottom: 16px;
      margin-bottom: 16px;
      &::after {
        display: block;
        position: absolute;
        content: "";
        width: 40px;
        height: 4px;
        bottom: -16px;
        background: linear-gradient(
          315deg,
          rgba(110, 195, 199, 1) 0%,
          rgba(60, 144, 150, 1) 100%
        );
      }
    }
    p {
      padding-top: 25px;
      line-height: 24px;
      color: #4a4a4a;
    }
  }

  .case_item_1 {
    height: 550px;
    .c_after {
      width: 826px;
      height: 550px;
      span {
        position: absolute;
      }
    }
    .c_before {
      right: 0;
      bottom: 20px;
    }
    .c_text {
      right: 20px;
      top: 72px;
      width: 315px;
    }
  }
  .case_item_2 {
    height: 634px;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.18);
    .c_after {
      width: 1200px;
      height: 514px;
    }
    .c_before {
      left: 20px;
    }
    .c_text {
      text-align: right;
      right: 24px;
      bottom: 24px;
      h6 {
        margin-bottom: 0;
      }
      h6::after {
        width: 0;
        border: 0;
      }
      p {
        padding-top: 5px;
      }
    }
  }
  .case_item_3 {
    height: 550px;
    padding-left: 374px;
    margin-bottom: 0;
    .c_after {
      height: 550px;
      width: 826px;
    }
    .c_before {
      left: 0;
    }
    .c_text {
      top: 64px;
      left: 20px;
      width: 334px;
    }
  }
}
</style>
